import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { useWindowSize } from "../../../hooks/useWindowSize";

import { Order as OrderModel } from "../../../models/Order/Order";
import api from "../../../services/api";

import { currencyFormater } from "../../../services/currencyFormater";

import {
  Container,
  OtherValuesOrder,
  Col,
  Freight,
  ButtonCancel,
  ButtonChange,
  ButtonContainer,
  Footer,
  RowNumber,
  CheckBoxContainer,
  Status,
  IconInfo,
  StatusContainer,
  NameProduct,
} from "./styles";
import { Checkbox, Modal, Table, Tooltip, notification } from "antd";
import Centralizer from "../../../containers/Centralizer";
import Spinner from "../../../components/Spinner";

interface IProps {
  order: OrderModel | undefined;
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  loadingBling: boolean;
}

const ModalBlingSend: React.FC<IProps> = ({
  visible,
  setVisible,
  order,
  setShouldSearch,
  loadingBling,
}) => {
  const windowSize = useWindowSize();
  const [checkboxGroup, setCheckboxGroup] = useState<string | null>(null);
  const [selectedItems, setSelectedItems] = useState<React.Key[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) {
        event.preventDefault();

        notification.info({
          message: "Para sair, clique no botão cancelar.",
          duration: 1,
        });
      }
    };

    if (visible) {
      document.addEventListener("keydown", handleEsc);
    }

    return () => {
      document.removeEventListener("keydown", handleEsc);
    };
  }, [visible]);

  const blingHanlder = async (selectedItemIds, order_id?: number) => {
    try {
      setLoading(true);
      const payload = selectedItems;

      const {
        data: { order: orders },
      } = await api.patch(`/orders/send-to-bling/${order_id}`, {
        orderItems: payload,
      });
      notification.success({
        message: "Pedido importado para o bling com sucesso",
        duration: 5,
      });
      setSelectedItems([]);
      setShouldSearch(true);
    } catch (error) {
      let _description = "Erro ao enviar pedidos";
      //@ts-ignore
      if (error.response && error.response.data) {
        //@ts-ignore
        const errorData = error.response.data;

        if (typeof errorData === "string") {
          _description = errorData;
        }

        if (typeof errorData.error === "string") {
          _description = errorData.error;
        }

        if (errorData.error && typeof errorData.error.message === "string") {
          _description = errorData.error.message;
        }

        if (typeof errorData.message === "string") {
          _description = errorData.message;
        }

        if (Array.isArray(errorData.error)) {
          const formattedErrors = errorData.error.join("\n");
          notification.error({
            message: "Erro ao enviar pedidos",
            description: (
              <strong>
                <pre>{formattedErrors}</pre>
              </strong>
            ),
            duration: 5,
            style: { width: "700px" },
          });
          return;
        }

        notification.error({
          message: "Erro ao enviar pedidos",
          description: <strong>{_description}</strong>,
          duration: 5,
        });
      }
    } finally {
      setLoading(false);
      setShouldSearch(false);
    }
  };

  const columnTableItems = [
    {
      title: "Selecionar",
      dataIndex: "id",
      key: "select",
      render: (id: React.Key, record) => (
        <Checkbox
          checked={selectedItems?.includes(id)}
          onChange={() => toggleSelection(id)}
          disabled={record?.sale_order_bling_id !== null || loading}
        />
      ),
    },
    {
      title: "Nome",
      dataIndex: "products.name",
      key: "products.name",
      width: "25%",
      render: (_, record) => (
        <NameProduct>{record?.products?.name}</NameProduct>
      ),
    },
    {
      title: "Valor Unit.",
      dataIndex: "price_unit",
      key: "price_unit",
      render: (text) => <span>R$ {currencyFormater(+text * 0.75)}</span>,
    },
    {
      title: "Qtd",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Situação",
      dataIndex: "situacao",
      key: "situacao",
      render: (_, record) => (
        <StatusContainer>
          <Tooltip
            title={
              record?.products?.factory_info?.situacao === "A"
                ? "Ativo"
                : record?.products?.factory_info?.situacao === "I"
                ? "Inativo"
                : "Sem informações da fábrica"
            }
          >
            <Status active={record?.products?.factory_info?.situacao}>
              {record?.products?.factory_info?.situacao === "A" ? (
                "Ativo"
              ) : record?.products?.factory_info?.situacao === "I" ? (
                "Inativo"
              ) : (
                <IconInfo />
              )}
            </Status>
          </Tooltip>
          <Tooltip title={"O produto foi desativado pela fábrica!"}>
            {record?.products?.factory_info?.situacao === "I" ? (
              <IconInfo />
            ) : (
              <></>
            )}
          </Tooltip>
        </StatusContainer>
      ),
    },
    {
      title: "Importado",
      dataIndex: "sale_order_bling_id",
      key: "sale_order_bling_id",
      render: (text) => (
        <span style={{ color: text !== null ? "green" : "red" }}>
          {text !== null ? "Sim" : "Não"}
        </span>
      ),
    },
    {
      title: "Total",
      render: (_, record) => (
        <span>
          {" "}
          R${" "}
          {currencyFormater(
            +record.price_unit * 0.75 * (+record.quantity || 0)
          )}
        </span>
      ),
    },
    {
      title: "Carga",
      dataIndex: "type_cargo",
      key: "type_cargo",
      sorter: (a, b) =>
        a.products?.type_cargo?.length - b.products?.type_cargo?.length,
      render: (_, record) => (
        <div>
          <Freight>
            {record?.products?.type_cargo === 0 ? (
              "Gelada"
            ) : record?.products?.type_cargo === 1 ? (
              "Seca"
            ) : (
              <Tooltip title={"O produto não tem um tipo de carga cadastrado"}>
                <IconInfo />
              </Tooltip>
            )}
          </Freight>
        </div>
      ),
    },
  ];

  const handleCheckboxChange = (type) => {
    if (type === "todos") {
      if (checkboxGroup !== "todos") {
        const allItemKeys =
          order?.orderItems
            .filter((item) => item?.sale_order_bling_id === null)
            .map((item) => item.id) ?? [];
        setSelectedItems(allItemKeys);
        setCheckboxGroup("todos");
      } else {
        setSelectedItems([]);
        setCheckboxGroup(null);
      }
    } else if (type === "gelada") {
      if (checkboxGroup !== "gelada") {
        const geladaKeys =
          order?.orderItems
            .filter(
              (item) =>
                item.products?.type_cargo === 0 &&
                item?.sale_order_bling_id === null
            )
            .map((item) => item.id) ?? [];
        setSelectedItems(geladaKeys);
        setCheckboxGroup("gelada");
      } else {
        setSelectedItems([]);
        setCheckboxGroup(null);
      }
    } else if (type === "seca") {
      if (checkboxGroup !== "seca") {
        const secaKeys =
          order?.orderItems
            .filter(
              (item) =>
                item?.products?.type_cargo === 1 &&
                item?.sale_order_bling_id === null
            )
            .map((item) => item.id) ?? [];
        setSelectedItems(secaKeys);
        setCheckboxGroup("seca");
      } else {
        setSelectedItems([]);
        setCheckboxGroup(null);
      }
    }
  };

  const toggleSelection = (id: React.Key) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(id)
        ? prevSelected.filter((key) => key !== id)
        : [...prevSelected, id]
    );
  };

  const calculateTotal = () => {
    return order?.orderItems
      .filter((item) => selectedItems.includes(item.id))
      .reduce(
        (total, item) =>
          total + Number(item?.price_unit) * Number(item.quantity),
        0
      );
  };

  return (
    <Container
      title="Importar para o Bling"
      visible={visible}
      centered
      keyboard={false}
      maskClosable={false}
      closable={false}
      destroyOnClose={true}
      onCancel={() => {
        setVisible(false);
      }}
      width={1200}
      footer={[
        <>
          <Footer>
            <OtherValuesOrder>
              <Col>
                <span>Valor Total: </span>
                <label>
                  R$ {currencyFormater((calculateTotal() || 0) * 0.75) || 0}
                </label>
              </Col>
              <Col>
                <span>Total de Produtos: </span>
                <label>{selectedItems.length}</label>
              </Col>
            </OtherValuesOrder>
            <ButtonContainer>
              <ButtonCancel
                onClick={() => setVisible(false)}
                disabled={loading}
              >
                Cancelar
              </ButtonCancel>

              <ButtonChange
                disabled={selectedItems.length === 0 || loading}
                onClick={async () =>
                  await blingHanlder(selectedItems, order?.id)
                }
              >
                Importar para o bling
              </ButtonChange>
            </ButtonContainer>
            {loading && (
              <Centralizer>
                <Spinner />
              </Centralizer>
            )}
          </Footer>
        </>,
      ]}
    >
      {loadingBling ? (
        <Centralizer>
          <Spinner />
        </Centralizer>
      ) : (
        <>
          <Col>
            <RowNumber>
              Número do pedido:<span>{order?.vhsys}</span>
            </RowNumber>
          </Col>
          <CheckBoxContainer>
            <Checkbox
              onChange={() => handleCheckboxChange("gelada")}
              disabled={checkboxGroup === "todos" || loading}
              checked={checkboxGroup === "gelada"}
            >
              Selecionar Carga Gelada
            </Checkbox>
            <Checkbox
              onChange={() => handleCheckboxChange("seca")}
              disabled={checkboxGroup === "todos" || loading}
              checked={checkboxGroup === "seca"}
            >
              Selecionar Carga Seca
            </Checkbox>
            <Checkbox
              onChange={() => handleCheckboxChange("todos")}
              disabled={
                checkboxGroup === "gelada" ||
                checkboxGroup === "seca" ||
                loading
              }
              checked={checkboxGroup === "todos"}
            >
              Selecionar Todos
            </Checkbox>
          </CheckBoxContainer>
          <Table
            rowClassName={(record) =>
              record?.sale_order_bling_id !== null ? "row-disabled" : ""
            }
            columns={columnTableItems}
            dataSource={order?.orderItems}
            rowKey={(entity: any) => entity.id}
            className="itemsTable"
            scroll={{ y: windowSize.height > 800 ? 500 : 300 }}
            pagination={false}
          />
        </>
      )}
    </Container>
  );
};

export default ModalBlingSend;
