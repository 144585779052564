import { useState, useEffect } from "react";

import apiAuth from "../services/apiAuth";
import { Store } from "../models/Store/Store";
import { getTokenInfo } from "../services/auth";

import { message } from "antd";

interface StoreType {
  id: number;
  key: number;
  value: string;
  cnpj: string;
  company_status: boolean;
  defaulter_status: boolean;
  country: string;
}

interface StoreHookResult {
  isStoreActive: boolean | undefined;
  isBlockedStore: boolean | undefined;
  loadingStore: boolean;
  stores: StoreType[];
}

export const useStoreData = (storeId?: number | null | undefined) => {
  const [loadingStore, setLoadingStore] = useState(true);
  const [stores, setStores] = useState<StoreType[]>([]);
  const [isStoreActive, setIsStoreActive] = useState<boolean | undefined>(
    undefined
  );
  const [storeCountryPY, setStoreCountryPY] = useState<boolean | undefined>(
    undefined
  );
  const [isBlockedStore, setIsBlockedStore] = useState<boolean | undefined>(
    undefined
  );
  const [shouldSearchStore, setShouldSearchStore] = useState(true);

  useEffect(() => {
    const fetchStores = async () => {
      try {
        const user = await getTokenInfo();
        const {
          data: { content },
        } = await apiAuth.get(`/companyUser/${user?.id}/user`);
        const response = content.map((company) => ({
          key: company.company_id,
          value: company.company.company_name,
          cnpj: company.company.cnpj,
          company_status: company.company.company_status,
          defaulter_status: company.company.defaulter_status,
          country: company.company.country,
        }));
        setStores(response);
        setLoadingStore(false);
      } catch (error) {
        const _message =
          //@ts-ignore
          error?.response?.data?.message;
        message.error(_message || "Houve um erro ao realizar busca");
      } finally {
        setShouldSearchStore(false);
      }
    };

    if (shouldSearchStore) fetchStores();
  }, [shouldSearchStore]);

  useEffect(() => {
    const selectedStore = stores.find((item) => item.key === storeId);
    console.log("selectedStore:", selectedStore);

    if (storeId && selectedStore) {
      setIsStoreActive(!selectedStore.company_status);
      setIsBlockedStore(selectedStore.defaulter_status);
      setStoreCountryPY(selectedStore.country === "paraguai");
      //   const isCountryPY =
      //     selectedStore.country.trim().toLowerCase() === "paraguai";
      //   setStoreCountryPY(isCountryPY);
    } else {
      setIsStoreActive(false);
      setStoreCountryPY(false);
    }
  }, [storeId, stores]);

  return {
    isStoreActive,
    isBlockedStore,
    setIsBlockedStore,
    loadingStore,
    stores,
    setShouldSearchStore,
    storeCountryPY,
  };
};
