import React, { SetStateAction, Dispatch, useState } from "react";

import { withRouter, RouteComponentProps } from "react-router-dom";

import { verifyPermission } from "../../services/auth";
import api from "../../services/api";
import apiMercury from "../../services/apiMercury";
import { currencyFormater } from "../../services/currencyFormater";

import { Purchase } from "../../models/Purchase/Purchase";

import ModalImportStock from "../../components/ModalImportStock";

import { Modal, Dropdown, Menu, Tooltip } from "antd";

import moment from "moment";
import swal from "sweetalert";

import {
  Container,
  Action,
  RemoveIcon,
  StatusIcon,
  Checkbox,
  ItemsSelectedContainer,
  ItemsSelectedInfo,
  QtdItems,
  InfoQtdItems,
  ButtonsContainer,
  LiquidarButton,
  RemoverButton,
  CheckIcon,
  MoreIcon,
  Table,
} from "./styles";

interface IProps extends RouteComponentProps {
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  shouldSearch: boolean;
  setPurchaseId?: Dispatch<SetStateAction<number | undefined>>;
  loading: boolean;
  setLoading: Dispatch<SetStateAction<boolean>>;
  purchases: Purchase[];
  buttonInfo: (id: number) => void;
  setVisibleOrderBuy: Dispatch<SetStateAction<boolean>>;
  isStoreActive: boolean | undefined;
}

const { confirm } = Modal;

const ShoppingList: React.FC<IProps> = ({
  loading,
  purchases,
  isStoreActive,
  setPurchaseId,
  setShouldSearch,
  setLoading,
  buttonInfo,
  setVisibleOrderBuy,
}) => {
  const [purchasesAdd, setPurchasesAdd] = useState<number[]>([]);
  const [visibleStockImport, setVisibleStockImport] = useState(false);
  const [stockPurchaseID, setStockPurchaseID] = useState<number>();

  const handlerEdit = (purchase: Purchase) => {
    if (setPurchaseId) setPurchaseId(purchase.id);
    setVisibleOrderBuy(true);
  };

  const handlePaymentPurchases = async (id: number | undefined) => {
    confirm({
      title: "Confirmar Pagamento",
      content: "Gostaria de confirmar pagamento desta compra?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",

      async onOk() {
        setLoading(true);

        try {
          await apiMercury.patch(`/purchases`, {
            ids: [id],
            pay_date: moment(new Date()).toISOString(),
          });

          setShouldSearch(true);
          swal("Pagamento confirmado com sucesso!", "", "success");
        } catch {
          swal("Erro ao confirmar pagamento!", "", "error");
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const handleRemovePurchase = async (purchase: Purchase, action: string) => {
    confirm({
      title:
        action === "delete" ? "Remover Compra" : "Estornar Royalty/Marketing",
      content: `Gostaria de ${action === "delete" ? "excluir" : "estornar"
        } esta compra?`,
      okText: "Sim",
      okType: "danger",
      cancelText: "Não",

      async onOk() {
        try {
          await apiMercury.delete(`/purchases/${purchase?.id}`);
          swal(
            `Compra ${action === "delete" ? "excluida" : "estornada"
            } com sucesso!`,
            "",
            "success"
          );
          setShouldSearch(true);
        } catch {
          swal(
            `Erro ao ${action === "delete" ? "excluir" : "estornar"} compra`,
            "",
            "error"
          );
        }
      },
    });
  };

  const handleSelectPurchase = (
    purchase: {
      id: number;
    },
    isChecked
  ) => {
    if (isChecked) {
      setPurchasesAdd((oldValues) => [...oldValues, purchase.id]);
    } else {
      setPurchasesAdd((oldValues) =>
        oldValues.filter((purchaseChecked) => purchaseChecked !== purchase.id)
      );
    }
  };

  const handlePaymentMany = () => {
    confirm({
      title: "Confirmar Pagamento",
      content:
        "Deseja continuar e confirmar o pagamento da(s) compra(s) selecionada(s)?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      async onOk() {
        setLoading(true);

        try {
          await apiMercury.patch(`/purchases`, {
            ids: purchasesAdd,
            pay_date: moment(new Date()).toISOString(),
          });

          setPurchasesAdd([]);
          setShouldSearch(true);
          swal("Pagamento confirmado com sucesso!", "", "success");
        } catch {
          swal("Erro ao confirmar pagamento!", "", "error");
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const handleRemoveMany = () => {
    confirm({
      title: "Excluir compras",
      content: "Deseja continuar e excluir a(s) compra(s) selecionada(s)?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      async onOk() {
        setLoading(true);
        try {
          await Promise.all(
            purchasesAdd.map(async (id) => {
              await apiMercury.delete(`/purchases/${id}`);
            })
          );
          setPurchasesAdd([]);
          swal("Compra(s) removida(s) com sucesso!", "", "success");
          setShouldSearch(true);
        } catch {
          swal("Falha ao remover compra(s)!", "", "error");
        } finally {
          setLoading(false);
        }
      },
    });
  };

  const columns = [
    {
      title: "Data Vencimento",
      dataIndex: "due_date",
      key: "due_date",
      width: "20%",
      render: (text, record) => (
        <Tooltip title={moment(record?.due_date).format("DD/MM/YYYY")}>
          <span>{moment(record?.due_date).format("DD/MM/YYYY")}</span>
        </Tooltip>
      ),
      sorter: (dateA, dateB) =>
        moment(dateA?.due_date).diff(moment(dateB?.due_date, "month", "day")),
    },
    {
      title: "Loja",
      dataIndex: "store",
      key: "store",
      width: "20%",
      ellipsis: true,
      responsive: ["lg"] as any,
      render: (text, record) => (
        <Tooltip title={record?.store?.company_name}>
          <span>{record?.store?.company_name}</span>
        </Tooltip>
      ),
    },
    {
      title: "Categoria",
      dataIndex: "name",
      key: "name",
      width: "20%",
      ellipsis: true,
      render: (text) => (
        <Tooltip title={text}>
          <span>{text}</span>
        </Tooltip>
      ),
    },
    {
      title: "Fornecedor",
      dataIndex: "provider.company_name",
      key: "provider",
      width: "20%",
      responsive: ["xl"] as any,
      render: (text, record) => <span>{record?.provider?.fantasy_name}</span>,
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      width: "20%",
      render: (text, record) => (
        <Tooltip title={currencyFormater(+record?.total)}>
          <span>R$ {currencyFormater(+record?.total)}</span>
        </Tooltip>
      ),
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Observações",
      dataIndex: "observation",
      key: "observation",
      width: "15%",
      ellipsis: true,
      responsive: ["xl"] as any,
      render: (text, record) => <span>{record?.observation}</span>,
    },
    {
      title: "Status",
      dataIndex: "",
      key: "",
      responsive: ["md"] as any,
      width: "10%",
      textWrap: "word-break",
      render: (text, record) => (
        <>
          {!record.deleted_at && (
            <Tooltip
              title={
                record.pay_date
                  ? "Pagamento Confirmado"
                  : moment(record?.due_date).isBefore(new Date())
                    ? "Vencido"
                    : "Aguardando Pagamento"
              }
            >
              <StatusIcon
                pay_date={record?.pay_date}
                due_date={record?.due_date}
              />
            </Tooltip>
          )}
        </>
      ),
    },
    {
      title: "Seleção",
      dataIndex: "",
      key: "",
      responsive: ["md"] as any,
      width: "10%",
      textWrap: "word-break",
      render: (text, record) => (
        <Checkbox
          disabled={!!record?.deleted_at || isStoreActive}
          onChange={({ target: { checked } }) => {
            handleSelectPurchase({ id: record.id }, checked);
          }}
        />
      ),
    },
    {
      title: "Ações",
      dataIndex: "",
      key: "",
      width: "9%",
      textWrap: "word-break",
      render: (text, record) => (
        <Action>
          {!isStoreActive && (
            <Dropdown
              overlay={
                <Menu>
                  <Menu.Item
                    onClick={() => {
                      setVisibleStockImport(true);
                      setStockPurchaseID(record?.id);
                    }}
                  >
                    {record?.already_imported_as_stock
                      ? "Estornar do Estoque"
                      : "Importar para o Estoque"}
                  </Menu.Item>
                  <Menu.Item onClick={() => buttonInfo(record)}>
                    Informações da Compra
                  </Menu.Item>
                  {verifyPermission("shop.edit") && !record?.pay_date && (
                    <Menu.Item onClick={() => handlePaymentPurchases(record?.id)}>
                      <span style={{ color: "var(--green-400)" }}>
                        Confirmar Pagamento da Compra
                      </span>
                    </Menu.Item>
                  )}
                  {(record?.name === "royalties" ||
                    record?.name === "marketing" ||
                    record?.name === "sistema" ||
                    record?.name === "serviço") && (
                    <Menu.Item
                      onClick={() => handleRemovePurchase(record, "reverse")}
                    >
                      <span>Estornar Royalty/Marketing</span>
                    </Menu.Item>
                  )}
                  {verifyPermission("shop.edit") &&
                    !(
                      record?.name === "royalties" ||
                      record?.name === "marketing" ||
                      record?.name === "sistema" ||
                      record?.name === "serviço"
                    ) && (
                      <Menu.Item onClick={() => handlerEdit(record)}>
                        Editar Compra
                      </Menu.Item>
                    )}
                  <Menu.Item
                    onClick={() => handleRemovePurchase(record, "delete")}
                  >
                    <span style={{ color: "var(--orange-600)" }}>
                      Excluir Compra
                    </span>
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
              placement="bottomCenter"
              arrow
            >
              <MoreIcon />
            </Dropdown>
          )}
        </Action>
      ),
    },
  ];

  return (
    <>
      <Container>
        <Table
          loading={loading}
          columns={columns}
          dataSource={purchases.map((entity) => ({
            ...entity,
            key: entity.id,
          }))}
        />
      </Container>
      {purchasesAdd.length > 0 && (
        <ItemsSelectedContainer>
          <ItemsSelectedInfo>
            <QtdItems>{purchasesAdd.length}</QtdItems>
            <InfoQtdItems>
              {purchasesAdd.length === 1
                ? "item selecionado"
                : "itens selecionandos"}
            </InfoQtdItems>
            <ButtonsContainer>
              <LiquidarButton onClick={() => handlePaymentMany()}>
                {" "}
                <CheckIcon />
                Liquidar
              </LiquidarButton>
              {verifyPermission("shop.delete") && (
                <RemoverButton onClick={() => handleRemoveMany()}>
                  <RemoveIcon />
                  Remover
                </RemoverButton>
              )}
            </ButtonsContainer>
          </ItemsSelectedInfo>
        </ItemsSelectedContainer>
      )}

      <ModalImportStock
        visible={visibleStockImport}
        setVisible={setVisibleStockImport}
        setShouldSearch={setShouldSearch}
        stockPurchaseID={stockPurchaseID}
      ></ModalImportStock>
    </>
  );
};

export default withRouter(ShoppingList);
