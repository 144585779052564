import styled, { css } from "styled-components";
import moment from "moment";

import { Checkbox as CheckboxAnt, Table as TableAnt } from "antd";

import {
  EditAlt,
  Trash,
  ExclamationOutline,
  CheckDouble,
  MoreHoriz,
} from "../../styles/Icons";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
interface IDeleted {
  deleted_at;
}

export const ItemsSelectedContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
  height: 4.5rem;
  width: 100%;
`;

export const ItemsSelectedInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 35%;
  border: 1px solid var(--gray-75);

  @media only screen and (max-width: 578px) {
    width: 95%;
  }
`;

export const QtdItems = styled.label`
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--white-25);
  color: var(--orange-350);
  width: 2.5rem;
  height: 2.5rem;
  font-weight: 600;
  border-radius: 3rem;
  @media only screen and (max-width: 578px) {
    font-size: 11px;
  }
`;

export const InfoQtdItems = styled.label`
  margin-left: 5px;
  color: var(--gray-600);
  font-weight: 500;
  text-transform: capitalize;
  @media only screen and (max-width: 578px) {
    font-size: 11px;
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  width: 60%;
  height: 100%;
  border-radius: 50px;
  justify-content: space-evenly;
`;

export const LiquidarButton = styled.button`
  background: var(--orange-350);
  color: var(--white);
  font-weight: 600;
  border-radius: 0.5rem;
  padding: 10px 15px;
  font-size: 15px;
  cursor: pointer;
  transition: 0.5s;

  svg {
    fill: var(--white-10);
    margin-right: 3px;
  }

  :hover {
    background: var(--orange-350);
  }

  @media only screen and (max-width: 578px) {
    font-size: 11px;
    padding: 5px 5px;
  }
`;

export const RemoverButton = styled.button`
  background: var(--orange-600);
  color: var(--white);
  border-radius: 0.5rem;
  font-weight: 600;
  padding: 10px 15px;
  font-size: 15px;
  cursor: pointer;
  transition: 0.5s;

  svg {
    width: 20px;
    height: 20px;
    margin-bottom: 2px;
    margin-right: 3px;
  }

  :hover {
    background: var(--orange-600);
  }

  @media only screen and (max-width: 578px) {
    font-size: 11px;
    padding: 5px 5px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

interface IStatus {
  pay_date: string;
  due_date: string;
}

export const StatusIcon = styled(ExclamationOutline)<IStatus>`
  ${({ pay_date, due_date }) => {
    if (pay_date) {
      return css`
        ${iconCSS}
        color: var(--green-400);
      `;
    }
    const isBefore = moment(due_date).isBefore(new Date());

    if (isBefore && !pay_date) {
      return css`
        ${iconCSS}
        color: var(--orange-600);
      `;
    }
    return css`
      ${iconCSS}
      color: #a2a0a0;
    `;
  }}
`;

export const LabelName = styled.label`
  text-transform: capitalize;
  font-weight: bold;

  @media only screen and (max-width: 578px) {
    margin-left: 5px;
  }
`;

export const Checkbox = styled(CheckboxAnt)`
  display: flex;
  align-items: center;
  justify-content: center;
  zoom: 1.5;

  @media only screen and (max-width: 578px) {
    zoom: 1;
  }
`;

export const Action = styled.div`
  display: flex;
  align-items: center;
  color: var(--orange-350);
  margin-left: 1%;
  min-width: 2rem;
`;

const iconCSS = css`
  width: 23px;
  height: 23px;

  @media only screen and (max-width: 578px) {
    width: 18px;
    height: 18px;
  }
`;

export const EditIcon = styled(EditAlt)`
  ${iconCSS}
  :hover {
    fill: orange;
  }
`;

export const RemoveIcon = styled(Trash)`
  ${iconCSS}
`;

export const CheckIcon = styled(CheckDouble)`
  ${iconCSS}
`;

export const MoreIcon = styled(MoreHoriz)`
  ${iconCSS}
  cursor: pointer;

  :hover {
    background: #8e8b8b6e;
    padding: 2px;
    border-radius: 4px;
    transition: 0.3s background;
  }
`;

export const Table = styled(TableAnt)`
  height: 100%;

  .ant-table-container {
    border-radius: 15px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    border: 1px solid var(--gray-50);

    overflow-y: scroll;
    max-height: 45rem;

    ::-webkit-scrollbar {
      width: 0.5rem;
    }

    ::-webkit-scrollbar-track {
      background: #fffefe;
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: #acaeae;
      border-radius: 5px;
    }

    @media only screen and (max-width: 1250px) {
      max-height: 35rem;
    }

    @media only screen and (max-width: 800px) {
      max-height: 25rem;
    }
  }

  .ant-table-tbody > tr > td {
    text-transform: capitalize;
    color: var(--blue-999);
    font-weight: 400;
    font-size: 1rem;
    text-overflow: ellipsis;
    overflow: hidden;
    overflow-wrap: unset;
    white-space: nowrap;

    @media only screen and (max-width: 575px) {
      font-size: 0.9rem;
      padding: 0.2rem;
    }
  }

  .ant-table-thead > tr > th {
    background: #fdfdfd;
    color: var(--gray-350);

    @media only screen and (max-width: 575px) {
      font-size: 0.8rem;
      padding: 0.2rem;
    }
  }
  .ant-table-column-sorters {
    @media only screen and (max-width: 575px) {
      font-size: 0.8rem;
      padding: 0.2rem;
    }
  }
`;
