import styled from "styled-components";
import { ShoppingCartOutline, Filter, Graph } from "../../../../styles/Icons";

import {
  Col as ColAnt,
  Row as RowAnt,
  Checkbox as CheckboxAnt,
  Modal as ModalAnt,
  Table as TableAnt,
  Button,
  Select as SelectAnt,
} from "antd";

export const Checkbox = styled(CheckboxAnt)`
  font-size: 10px;
  zoom: 1.5;
  margin-top: 10px;
  font-size: 10px;
  font-weight: 600;
  margin-bottom: 5px;
`;

export const BodyList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  .ant-table-container {
    margin-top: 0.2rem;
    border-radius: 0;
  }
`;

export const AddIcon = styled(ShoppingCartOutline)`
  width: 25px;
  height: 25px;

  @media only screen and (max-width: 578px) {
    justify-content: space-around;
    height: 10%;
    width: 18px;
    height: 18px;
  }
`;

export const Row = styled(RowAnt)`
  display: flex;
  align-items: center;
  width: 100%;
`;

export const Col = styled(ColAnt)``;

export const RowHeaderList = styled(RowAnt)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  margin-top: 0.1rem;

  @media screen and (max-width: 578px) {
    /* height: 25%; */
    justify-content: space-between;
  }
`;

export const ColHeaderList = styled(ColAnt)`
  margin-right: 0.3rem;
  min-width: 3.25rem;
  @media only screen and (max-width: 1400px) {
    .allOrders {
      font-size: 0.8rem;
    }
  }
  @media only screen and (max-width: 700px) {
    .allOrders {
      font-size: 0.7rem;
    }
  }
  @media only screen and (max-width: 500px) {
    .allOrders {
      font-size: 0.5rem;
    }
    .orders {
      display: none;
    }
  }
`;

export const ButtonAdd = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 3.3rem;
  width: 3.3rem;
  border-radius: 5px;
  margin-right: 0.1rem;
  background: var(--orange-gradient);
  color: var(--white);

  :hover {
    color: var(--white);
    opacity: 80%;
  }
`;

export const ButtonFilter = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 3.3rem;
  width: 3.3rem;
  border-radius: 5px;

  background: var(--orange-350);
  color: var(--white);

  :hover {
    background: var(--orange-350);
    color: var(--white);
    opacity: 80%;
  }
`;

export const FilterIcon = styled(Filter)`
  width: 20px;
  height: 20px;
`;

export const GraphIcon = styled(Graph)`
  width: 20px;
  height: 20px;
`;

export const ModalInfo = styled(ModalAnt)`
  .ant-modal-header {
    padding: 3rem 3rem 2rem;
    .ant-modal-title {
      color: var(--gray-45);
      font-weight: 500;
      font-size: 2rem;
    }
  }
  .ant-modal-body {
    padding: 0 3rem 3rem;
  }
  .ant-row {
    display: flex;
    align-items: center;
    min-height: 3.25rem;
    color: var(--blue-999);
    font-weight: 400;
    text-transform: capitalize;
  }
`;

export const ModalFilterContainer = styled.div`
  padding: 0 1rem;
  border: 1px solid var(--gray-75);
  border-radius: 0.2rem;
`;
export const TableInfo = styled(TableAnt)`
  height: 100%;
`;
export const ModalFilter = styled(ModalAnt)`
  .ant-modal-body {
    border-top: 1px solid var(--gray-75);
    .ant-select-selection-item,
    .ant-select-selection-placeholder {
      align-self: center;
    }
    .info {
      color: var(--gray-45);
    }
    span {
      font-weight: 500;
    }

    .ant-input,
    .ant-input-password,
    .ant-select-selector,
    .ant-picker {
      height: 3.25rem;

      outline: none;

      box-shadow: 0 0 0 0;
      background: var(--white);

      :focus,
      :active,
      :hover {
        outline: none;
        box-shadow: 0 0 0 0;
      }
    }

    .ant-col {
      padding: 0.7rem;
    }
  }
`;

export const ButtonSearch = styled(Button)`
  border: none;
  background: var(--orange-350);
  color: var(--white);
  width: 100%;
  height: 3.25rem;
  font-weight: 600;
  border-radius: 4px;

  :hover,
  :focus,
  :active {
    background: var(--orange-350);
    color: var(--white);
    opacity: 80%;
  }
`;

export const ButtonClear = styled(Button)`
  border: 1px var(--gray-45) solid;
  color: var(--gray-45);
  background: var(--white);
  width: 100%;
  height: 3.25rem;
  font-weight: 600;
  border-radius: 4px;

  :hover,
  :focus,
  :active {
    border: 1px var(--gray-45) solid;
    color: var(--gray-45);
    background: var(--white);
    opacity: 80%;
  }
`;

export const HeaderTotal = styled.div`
  display: none;

  @media only screen and (max-width: 575px) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 0.8rem 0;

    &:first-of-type {
      margin: 0.8rem 0;
    }
  }
`;

export const ContainerTotal = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  top: 0.3rem;
  width: 100%;
  height: 1.875rem;

  @media only screen and (max-width: 575px) {
    display: none;
  }
`;

export const Total = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 1rem;
  margin-right: 0.3rem;
  border-radius: 0.2rem 0.2rem 0 0;
  color: var(--white);
  font-size: 1rem;
  font-weight: 500;
  span {
    margin: 0.1rem;
  }
  @media only screen and (max-width: 800px) {
    font-size: 0.875rem;
  }
  @media only screen and (max-width: 650px) {
    font-size: 0.675rem;
    padding: 0 0.5rem;
  }
  @media only screen and (max-width: 575px) {
    flex-direction: column;
    align-items: flex-start;
    width: 45%;
    font-size: 1rem;
    padding: 0 0.5rem;
    border-radius: 0.2rem;
    span {
      margin: 0;
    }
  }
`;

export const Select = styled(SelectAnt)`
  width: 100%;
  text-transform: capitalize;
`;
