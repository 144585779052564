import React, { useState, useEffect } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import moment from "moment";
import { Dropdown, Empty, Menu, message, Tooltip } from "antd";

import api from "../../services/api";
import { currencyFormater } from "../../services/currencyFormater";

import { CashHistories as CashHistoriesModel } from "../../models/CashHistories";
import { Sale as SaleModel } from "../../models/Sale";
import { Audit as AuditModel } from "../../models/Audit";

import typesMenu from "../../pages/CashManagement/enum/typesMenu.json";

import ModalItemsCashHistory from "../../components/ModalItemsCashHistory";
import ModalHandlers from "../../components/ModalHandlers";
import ModalItemsCashHistoryMoreInfo from "../../components/ModalItemsCashHistoryMoreInfo";
import ModalSaleDetail from "../../components/ModalSaleDetail";
import ModalCashManagementAuditoria from "../../components/ModalCashManagementAuditoria";

import {
  Container,
  LabelName,
  Actions,
  MoreInfo,
  Table,
  ArrowUpIcon,
  ArrowDownIcon,
  ButtonOpenModal,
  DropUpIcon,
  DropdownIcon,
  ErrorCash,
} from "./styles";

import { verifyPermission } from "../../services/auth";
import { SalesTypes } from "../../models/enums/SalesType";
import apiMsNf from "../../services/apiMsNf";
import ModalAuditTef from "../../components/ModalAuditTef";

interface IProps extends RouteComponentProps {
  cashHistories: CashHistoriesModel[];
  types: number[];
  loading: boolean;
  deleteSaleRow: (id: number) => void;
  setCashHistories: (cashHistories: CashHistoriesModel[]) => void;
  deletedSales: any;
  handleRestoreSale: (id: number) => void;
  setShouldSearch: (shouldSearch: boolean) => void;
  isStoreActive: boolean | undefined;
  countryPY?: boolean | undefined;
}

export enum AuditType {
  sale = 1,
  cash_history = 2,
  balance_history = 3,
}

const CashHistoryList: React.FC<IProps> = ({
  cashHistories,
  loading,
  types,
  deleteSaleRow,
  setCashHistories,
  deletedSales,
  handleRestoreSale,
  setShouldSearch,
  isStoreActive,
  countryPY,
}) => {
  const [visible, setVisible] = useState(false);
  const [visibleMoreInfo, setVisibleMoreInfo] = useState(false);
  const [visibleDetail, setVisibleDetail] = useState(false);
  const [visibleHandler, setVisibleHandler] = useState(false);
  const [visibleAuditoria, setVisibleAuditoria] = useState(false);
  const [visibleModalAuditTef, setVisibleModalAuditTef] = useState(false);
  const [selectedCashHistory, setSelectedCashHistory] = useState<number | null>(
    null
  );
  const [selectedHistory, setSelectedHistory] =
    useState<CashHistoriesModel | null>(null);
  const [selectedSale, setSelectedSale] = useState<SaleModel | null>(null);

  const [selectedAuditoria, setSelectedAuditoria] = useState<AuditModel[]>([]);
  const [selectedAuditType, setSelectedAuditType] = useState<AuditType>();
  const [selectedRefId, setSelectedRefId] = useState<number | undefined>();

  useEffect(() => {
    if (!selectedHistory) return;
    const data = cashHistories.filter(
      (cashHistory) => cashHistory.id !== selectedHistory.id
    );
    const newData = [...data, selectedHistory];
    setCashHistories(newData);
  }, [selectedHistory]);

  useEffect(() => {
    const fetchCashHistory = async () => {
      try {
        const {
          data: { data },
        } = await api.get(
          `/cash_history_audit/${selectedAuditType}/${selectedRefId}`
        );
        setSelectedAuditoria(data.filter((item) => !item.code_nsu));
      } catch (error) {
        const _message = "Houve um erro ao realizar busca";
        //@ts-ignore
        const _description = error?.response?.data?.error?.message;
        message.error({
          message: _message,
          description: _description,
        });
      }
    };

    if (visibleAuditoria && selectedAuditType && selectedRefId) {
      fetchCashHistory();
    }
  }, [visibleAuditoria, selectedAuditType, selectedRefId]);

  const identifyPeriod = (openedTime: Date) => {
    const openedHour = moment(openedTime, "DD-MM-YYYY HH:mm:ss").hour();
    if (openedHour >= 6 && openedHour < 12) {
      return "Manhã";
    } else if (openedHour >= 12 && openedHour < 18) {
      return "Tarde";
    } else {
      return "Noite";
    }
  };

  const getNfceDanfe = async (saleId: number) => {
    try {
      const {
        data: { content },
      } = await apiMsNf.get(`/nfce/${saleId}/danfe`);
      const html = atob(content);
      const url = window.URL.createObjectURL(
        new Blob([html], { type: "application/pdf" })
      );
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${saleId}.html`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      //@ts-ignore
      const errorMessage = error?.response?.data?.message;
      message.error(errorMessage || "Falha ao gerar arquivo.");
    }
  };

  const columnDeletedSales = [
    {
      title: "Identificação da venda deletada",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Data da venda",
      dataIndex: "created_at",
      key: "created_at",
      render: (_, record) => (
        <>
          {moment(record.created_at, "YYYY-MM-DD HH:mm:ss").format(
            "DD/MM/YYYY HH:mm:ss"
          )}
        </>
      ),
    },
    {
      title: "Valor da venda",
      dataIndex: "payments.amount",
      key: "payments.amount",
      render: (_, record) => {
        const totalAmount = record.payments.reduce(
          (accumulator, item) => accumulator + item.amount,
          0
        );
        return (
          <span>
            R$ {currencyFormater(totalAmount - (record?.change_amount || 0))}
          </span>
        );
      },
    },
    {
      title: "Data da deleção da venda",
      dataIndex: "deleted_at",
      key: "deleted_at",
      responsive: ["md"] as any,
      render: (_, record) => (
        <>
          {moment(record.deleted_at, "YYYY-MM-DD HH:mm:ss")
            .subtract(3, "hours")
            .format("DD/MM/YYYY HH:mm:ss")}
        </>
      ),
    },
    {
      title: "Ações",
      key: "action",
      width: "15%",
      render: (_, record) => (
        <Actions>
          <Dropdown
            overlay={
              <Menu>
                <Menu.Item onClick={() => handleRestoreSale(record.id)}>
                  Restaurar venda
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    setVisibleAuditoria(true);
                    setSelectedAuditType(AuditType.sale);
                    setSelectedRefId(record.id);
                  }}
                >
                  Histórico de venda
                </Menu.Item>
                <Menu.Item
                  onClick={() => {
                    setSelectedAuditType(AuditType.sale);
                    setVisibleModalAuditTef(true);
                    setSelectedRefId(record.id);
                  }}
                >
                  Histórico de pagamentos cancelados TEF
                </Menu.Item>
              </Menu>
            }
            trigger={["click"]}
            placement="bottomLeft"
          >
            <MoreInfo />
          </Dropdown>
        </Actions>
      ),
    },
  ];

  const columns = [
    {
      title: "Data/abertura",
      dataIndex: "opened_at",
      key: "opened_at",
      width: "30%",
      sorter: (cashId1, cashId2) => cashId1.id - cashId2.id,
      defaultSortOrder: "ascend" as any,
      render: (text, record) => (
        <Tooltip
          title={
            <>
              <span>
                {moment(text, "DD-MM-YYYY HH:mm:ss").format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </span>{" "}
              <span className="amountSM">
                {`R$ ${currencyFormater(+record.amount_on_open || 0)}`}
              </span>
            </>
          }
        >
          <LabelName>
            <div className="dateTime">
              <span>
                {moment(text, "DD-MM-YYYY HH:mm:ss").format(
                  "DD/MM/YYYY HH:mm:ss"
                )}
              </span>{" "}
            </div>
            <p className="hourTime">
              {`R$ ${currencyFormater(+record.amount_on_open || 0)}`}
            </p>
          </LabelName>
        </Tooltip>
      ),
    },
    {
      title: "Turno",
      dataIndex: "",
      key: "",
      responsive: ["md"] as any,
      width: "13%",
      render: (_, record) => <>{identifyPeriod(record.opened_at)}</>,
    },
    {
      title: "Data/fechamento",
      dataIndex: "closed_at",
      key: "closed_at",
      width: "35%",
      render: (_, record) => (
        <>
          {record.closed_at !== null && record.closed_at !== undefined ? (
            <Tooltip
              title={
                <>
                  {" "}
                  <span>
                    {moment(record.closed_at, "DD-MM-YYYY HH:mm:ss").format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </span>{" "}
                  <span className="amountSM">
                    {` R$ ${currencyFormater(+record.amount_on_close || 0)}`}
                  </span>
                </>
              }
            >
              <LabelName>
                <div className="dateTime">
                  <span>
                    {moment(record.closed_at, "DD-MM-YYYY HH:mm:ss").format(
                      "DD/MM/YYYY HH:mm:ss"
                    )}
                  </span>{" "}
                </div>
                <span className="hourTime">
                  {` R$ ${currencyFormater(+record.amount_on_close || 0)}`}
                </span>
              </LabelName>
            </Tooltip>
          ) : (
            <ErrorCash>Caixa aberto</ErrorCash>
          )}
        </>
      ),
    },
    {
      title: "Saídas",
      dataIndex: "out_result",
      key: "out_result",
      responsive: ["xl"] as any,
      textWrap: "word-break",
      width: "13%",
      render: (text) => (
        <LabelName>R$ {currencyFormater(+text || 0)}</LabelName>
      ),
    },
    {
      title: "Entrada",
      dataIndex: "in_result",
      key: "in_result",
      responsive: ["xl"] as any,
      textWrap: "word-break",
      width: "13%",
      render: (text) => (
        <LabelName>R$ {currencyFormater(+text || 0)}</LabelName>
      ),
    },
    {
      title: "Resultados",
      dataIndex: "result_cash",
      key: "result_cash",
      responsive: ["md"] as any,
      textWrap: "word-break",
      width: "20%",
      render: (text) => (
        <div>
          {+text >= 0 ? <ArrowUpIcon /> : <ArrowDownIcon />} R${" "}
          {currencyFormater(+text || 0)}
        </div>
      ),
    },
    {
      title: "Valor de faturamento",
      dataIndex: "result_cash",
      key: "result_cash_new",
      responsive: ["md"] as any,
      textWrap: "word-break",
      width: "20%",
      render: (text, record) => {
        const balanceHistory = record.balance_history;
        const totalStore = balanceHistory
          ? +(balanceHistory.total_store || "0")
          : 0;
        const totalDelivery = balanceHistory
          ? +(balanceHistory.total_delivery || "0")
          : 0;

        const total = totalStore + totalDelivery;
        return <LabelName>{`R$ ${currencyFormater(total)}`}</LabelName>;
      },
    },
    {
      title: "Ações",
      key: "action",
      width: "15%",
      render: (text, record) => (
        <Actions>
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown
              overlay={
                <Menu onClick={() => setSelectedHistory(record)}>
                  <Menu.Item
                    onClick={() => {
                      setVisible(true);
                    }}
                  >
                    Itens Excluídos
                  </Menu.Item>
                  <Menu.Item
                    onClick={() => {
                      openCashHandlers(record.id);
                    }}
                  >
                    Movimentações
                  </Menu.Item>
                  <Menu.SubMenu title="Faturamento">
                    <Menu.Item
                      onClick={() => {
                        setVisibleMoreInfo(true);
                      }}
                    >
                      Faturamento
                    </Menu.Item>
                    <Menu.Item
                      onClick={() => {
                        setVisibleAuditoria(true);
                        setSelectedAuditType(AuditType.balance_history);
                        setSelectedRefId(record.balance_history.id);
                      }}
                    >
                      Histórico de alterações
                    </Menu.Item>
                  </Menu.SubMenu>

                  <Menu.Item
                    onClick={() => {
                      setVisibleAuditoria(true);
                      setSelectedAuditType(AuditType.cash_history);
                      setSelectedRefId(record.id);
                    }}
                  >
                    Histórico de alterações
                  </Menu.Item>
                  {!countryPY && (
                    <Menu.Item
                      onClick={() => {
                        setVisibleModalAuditTef(true);
                        setSelectedAuditType(AuditType.cash_history);
                        setSelectedRefId(record.id);
                      }}
                    >
                      Histórico de pagamentos desfeitos TEF
                    </Menu.Item>
                  )}
                  {record.backup_url && (
                    <Menu.Item>
                      <a href={record.backup_url} download>
                        Backup database
                      </a>
                    </Menu.Item>
                  )}
                </Menu>
              }
              trigger={["click"]}
              placement="bottomLeft"
            >
              <MoreInfo onClick={(e) => e.stopPropagation()} />
            </Dropdown>
          </div>
        </Actions>
      ),
    },
  ];

  const childrenColumn = [
    {
      title: "Identificação",
      dataIndex: "id",
      key: "id",
      render: (text) => <>{text}</>,
    },
    {
      title: "Quantidade",
      dataIndex: "quantity",
      responsive: ["md"] as any,
      key: "quantity",
      render: (text) => <>{text}</>,
    },
    {
      title: "Data da venda",
      dataIndex: "sale_date",
      key: "sale_date",
      render: (_, record) => (
        <>{moment(record.sale_date, "YYYY-MM-DD").format("DD/MM/YYYY")}</>
      ),
    },
    {
      title: "Hora",
      dataIndex: "sale_date",
      responsive: ["md"] as any,
      key: "sale_date",
      render: (_, record) => (
        <>
          {moment(record.sale_date, "YYYY-MM-DD HH:mm:ss").format("HH:mm:ss")}
        </>
      ),
    },
    {
      title: "Total",
      dataIndex: "totalSold",
      key: "totalSold",
      render: (text) => <>{`R$ ${text}`}</>,
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
      responsive: ["md"] as any,
      render: (_, record) => <>{SalesTypes[record.type]}</>,
    },
    {
      title: "Detalhes da venda",
      key: "details",
      responsive: ["md"] as any,
      render: (_, record) => (
        <ButtonOpenModal
          onClick={() => {
            setVisibleDetail(true);
            setSelectedSale(record);
          }}
        >
          Visualizar
        </ButtonOpenModal>
      ),
    },
    ...(!countryPY
      ? [
          {
            title: "NFCe",
            dataIndex: "nfce_url",
            key: "nfce_url",
            render: (text, record) =>
              text && !text?.includes("https://api.focusnfe.com.brnull") ? (
                <a
                  className="nfce"
                  href={record?.nfce_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Emitida
                </a>
              ) : (
                <span className="not-nfce">Não emitida</span>
              ),
          },
        ]
      : []),
    {
      title: "Ações",
      key: "action",
      render: (_, record) => {
        return (
          <Actions>
            <Dropdown
              overlay={
                <Menu>
                  {!countryPY && (
                    <Menu.Item
                      key={record.id}
                      onClick={() => {
                        if (verifyPermission("sales.nfce")) {
                          getNfceDanfe(record.id);
                        } else {
                          message.warn(
                            "Usuário não autorizado ou URL inválida da NFCe"
                          );
                        }
                      }}
                      disabled={!record.nfce_url}
                    >
                      Download NFCe
                    </Menu.Item>
                  )}

                  <Menu.Item
                    onClick={() => {
                      setVisibleAuditoria(true);
                      setVisibleAuditoria(record);
                      setSelectedAuditType(AuditType.sale);
                      setSelectedRefId(record.id);
                    }}
                  >
                    Histórico de venda
                  </Menu.Item>
                  {!countryPY && (
                    <Menu.Item
                      onClick={() => {
                        setSelectedAuditType(AuditType.sale);
                        setVisibleModalAuditTef(true);
                        setSelectedRefId(record.id);
                      }}
                    >
                      Histórico de pagamentos cancelados TEF
                    </Menu.Item>
                  )}

                  <Menu.Item
                    onClick={() => deleteSaleRow(record.id)}
                    disabled={isStoreActive}
                    title={
                      cashHistories?.some((cash) => cash.closed_at !== null) &&
                      "Para excluir a venda, feche o caixa"
                    }
                  >
                    Excluir venda
                  </Menu.Item>
                </Menu>
              }
              trigger={["click"]}
              placement="bottomLeft"
            >
              <MoreInfo />
            </Dropdown>
          </Actions>
        );
      },
    },
  ];

  const totalSold = (sale: SaleModel): string => {
    return (
      sale.payments.reduce((total, payment) => total + +payment.amount, 0) -
      parseFloat(sale.change_amount)
    )
      .toFixed(2)
      .replace(".", ",");
  };

  const openCashHandlers = (cash_history_id: number) => {
    setSelectedCashHistory(cash_history_id);
    setVisibleHandler(true);
  };

  const filteredTypeSaleAndPayment = (sale, selectedFilters) => {
    const includeDeliveryFilter = selectedFilters.some((filter) =>
      typesMenu.some(
        (type) => type.description === "Delivery" && filter === type.id
      )
    );
    const includeStoreFilter = selectedFilters.some((filter) =>
      typesMenu.some(
        (type) => type.description === "Loja" && filter === type.id
      )
    );
    const includeTefFilter = selectedFilters.some((filter) =>
      typesMenu.some((type) => type.description === "Tef" && filter === type.id)
    );

    const includePaymentType = sale.payments.some((payment) =>
      selectedFilters.includes(payment.type)
    );
    const includePaymentTef = sale.payments.some((payment) => payment.code_nsu);

    const hasOnlyOnePaymentType = selectedFilters.length === 1;
    const isStoreType = sale.type === SalesTypes.Loja;
    const isDeliveryType =
      sale.type >= SalesTypes.Ifood && sale.type <= SalesTypes.Outros;

    if (includeDeliveryFilter && includeStoreFilter) {
      return isStoreType || isDeliveryType || includePaymentType;
    } else if (includeTefFilter) {
      return includePaymentTef;
    } else if (includeDeliveryFilter) {
      return isDeliveryType && (hasOnlyOnePaymentType || includePaymentType);
    } else if (includeStoreFilter) {
      return isStoreType && (hasOnlyOnePaymentType || includePaymentType);
    } else {
      return includePaymentType;
    }
  };

  return (
    <Container>
      {types.some((type) => type === 7) ? (
        <>
          {deletedSales.length > 0 ? (
            <Table
              loading={loading}
              columns={columnDeletedSales}
              dataSource={deletedSales}
              rowKey={(item: any) => item.id}
            />
          ) : (
            <Empty description="Não há vendas removidas" />
          )}
        </>
      ) : (
        <Table
          loading={loading}
          columns={columns}
          scroll={{ y: 700 }}
          dataSource={cashHistories}
          rowKey={(item: any) => item.id}
          expandable={{
            expandIconColumnIndex: columns.length,
            expandRowByClick: true,
            expandIcon: ({ expanded, record, onExpand }) => {
              const selectedRecord: any = record;
              return (
                <span
                  onClick={(e) => {
                    setSelectedHistory(selectedRecord);
                    onExpand(record, e);
                  }}
                >
                  {expanded ? <DropUpIcon /> : <DropdownIcon />}
                </span>
              );
            },
            expandedRowRender: (record) => (
              <Table
                className="children_table"
                columns={childrenColumn}
                rowKey={(item: any) => item.id}
                dataSource={(
                  record as { sales: CashHistoriesModel["sales"] }
                ).sales
                  .map((entitySales) => ({
                    ...entitySales,
                    key: entitySales.id,
                    id: entitySales.id,
                    quantity: entitySales.quantity,
                    sale_date: entitySales.created_at,
                    hour: entitySales.created_at,
                    totalSold: totalSold(entitySales),
                    type: entitySales.type,
                    items: entitySales.items,
                    payments: entitySales.payments,
                    change_amount: entitySales.change_amount,
                    discount: entitySales.discount,
                  }))
                  .filter((sale) => {
                    if (types.length === 0) {
                      return true;
                    }

                    return filteredTypeSaleAndPayment(sale, types);
                  })}
                pagination={false}
              />
            ),
          }}
          pagination={false}
        />
      )}

      <ModalItemsCashHistory
        visible={visible}
        setVisible={setVisible}
        cashHistory={selectedHistory}
      />
      <ModalSaleDetail
        setVisible={setVisibleDetail}
        visible={visibleDetail}
        cashHistorySale={selectedSale}
        types={types}
        loading={loading}
      />

      <ModalHandlers
        setVisibleHandler={setVisibleHandler}
        visibleHandler={visibleHandler}
        selectedCashHistory={selectedCashHistory}
      />
      <ModalItemsCashHistoryMoreInfo
        visible={visibleMoreInfo}
        setVisible={setVisibleMoreInfo}
        cashHistory={selectedHistory}
        cashClosed={selectedHistory?.closed_at !== null}
        setShouldSearch={setShouldSearch}
        isStoreActive={isStoreActive}
      />
      <ModalCashManagementAuditoria
        visible={visibleAuditoria}
        loading={loading}
        setVisible={setVisibleAuditoria}
        selectedAuditoria={selectedAuditoria}
        auditType={selectedAuditType}
      />
      <ModalAuditTef
        visible={visibleModalAuditTef}
        setVisible={setVisibleModalAuditTef}
        selectedRefId={selectedRefId}
        auditType={selectedAuditType}
      />
    </Container>
  );
};

export default withRouter(CashHistoryList);
