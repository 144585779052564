import React, { Dispatch, SetStateAction, useState } from 'react'

import moment from 'moment';
import api from '../../services/api';

import { Drawer, Modal, notification, Table, Tooltip } from 'antd';

import { CashHistories } from '../../models/CashHistories';
import { currencyFormater } from '../../services/currencyFormater';
import MonetaryInput2 from '../../components/MonetaryInput2';

import {
    ButtonClose,
    Container,
    TextCapitalize,
} from './styles';

interface IProps {
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    store_id: number;
    company_name?: string;
    cash_detail: CashHistories["cash"] | any;
    setShouldSearch: React.Dispatch<React.SetStateAction<boolean>>
}

const CashStoreModal: React.FC<IProps> = ({ setVisible, store_id, visible, company_name, cash_detail, setShouldSearch }) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [amountOnClose, setAmountOnClose] = useState(0);
    const [selectedCashCode, setSelectedCashCode] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    const changeCashStatus = async (cashCode: string, amount_on_close: number) => {
        Modal.confirm({
            title: "Fechar o caixa?",
            content: "Deseja continuar e fechar o caixa? Isso afetará diretamente o gestor de vendas.",
            okText: "Sim",
            okType: "primary",
            cancelText: "Não",
            async onOk() {
                try {
                    setLoading(true);
                    await api.put(`/store_cashes/${store_id}-${cashCode}/close`, { amount_on_close: amount_on_close });

                    setVisible(false);
                    setShouldSearch(true);
                    setOpenDrawer(false);
                    notification.success({
                        message: "Caixa atualizado com sucesso!",
                        duration: 5,
                    });
                } catch (err) {
                    notification.error({
                        message: "Erro ao fazer a atualização do status do caixa" + err,
                        duration: 5,
                    });
                } finally {
                    setLoading(false);
                }
            }
        })
    }

    const columns = [
        {
            title: "Cash ID",
            dataIndex: "id",
            key: "id",
            render: (text) => text
        },
        {
            title: "Nº do caixa",
            dataIndex: "code",
            key: "code",
            render: (text) => text
        },
        {
            title: "Última modificação",
            dataIndex: "opened_at",
            key: "opened_at",
            render: (text, record) => (
                <span>
                    {record.opened_at
                        ? `Aberto em ${record.opened_at} com valor de R$ ${currencyFormater(+record.amount_on_open)}`
                        : `Fechado em ${record.closed_at} com valor de R$ ${currencyFormater(+record.amount_on_close)}`
                    }
                </span>
            )
        },
        {
            title: "Responsável",
            dataIndex: "name",
            key: "name",
            render: (text) => {
                return (
                    <TextCapitalize>
                        {text ? text : 'Usuário desconhecido'}
                    </TextCapitalize>
                );
            }
        },
        {
            title: "Ação",
            key: "action",
            render: (_, record) => {
                const daysOpen = record.opened_at ? moment().diff(moment(record.opened_at, 'DD-MM-YYYY HH:mm:ss'), 'days') : 0;
                const isClosed = record.closed_at !== null;

                const tooltipText = isClosed
                    ? "Esse caixa já foi fechado"
                    : daysOpen < 1
                        ? `O caixa poderá ser fechado daqui ${1 - daysOpen} dia`
                        : "Fechar caixa";

                return (
                    <Tooltip title={tooltipText}>
                        <ButtonClose
                            onClick={() => {
                                setSelectedCashCode(record.code);
                                setOpenDrawer(true);
                            }}
                            disabled={isClosed || daysOpen < 1}
                        >
                            Finalizar caixa
                        </ButtonClose>
                    </Tooltip>
                );
            },
        }
    ]

    return (
        <Container
            centered
            closable={true}
            visible={visible}
            title={
                <>
                    Detalhes dos caixas da loja - <TextCapitalize>{company_name}</TextCapitalize>
                </>
            }
            okText="Finalizar"
            footer={false}
            width={window.innerWidth > 768 ? "70%" : "100%"}
            onCancel={() => setVisible(false)}
        >
            <Table
                columns={columns}
                pagination={false}
                dataSource={cash_detail}
                rowKey={(entity: any) => entity.id}
            />

            <Drawer
                title={"Valor de fechamento"}
                visible={openDrawer}
                onClose={() => setOpenDrawer(false)}
                maskClosable
                mask={false}
                closable
                width={window.outerWidth < 768 ? '100%' : '30%'}
            >
                <>
                    <MonetaryInput2
                        defaultValue={amountOnClose}
                        getValue={(value) => setAmountOnClose(+value)}
                    />

                    <ButtonClose
                        disabled={loading}
                        onClick={() => changeCashStatus(selectedCashCode as string, amountOnClose)}
                        style={{ marginTop: '1rem' }}
                    >
                        Confirmar fechamento
                    </ButtonClose>
                </>
            </Drawer>
        </Container >
    )
}

export default CashStoreModal