import { Empty, Spin } from "antd";
import React, { Dispatch, SetStateAction } from "react";

import { Sale } from "../../models/Sale";
import useEscapeKey from "../../utils/useEscapeKey";
import { currencyFormater } from "../../services/currencyFormater";

import {
  ButtonCancel,
  ButtonOk,
  Container,
  ContentFooter,
  Table,
  Modal,
  IdText,
  ContentAmountAndDiscount,
  ContentTopInformation,
} from "./styles";

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  cashHistorySale: Sale | null;
  types: number[];
  loading: boolean;
}

const ModalSaleDetail: React.FC<IProps> = ({
  visible,
  setVisible,
  cashHistorySale,
  types,
  loading,
}) => {
  const handleModalToggle = () => {
    setVisible(!visible);
  };

  useEscapeKey({ setVisible: () => setVisible(false) });

  const paymentMethodCodes = {
    money: 0,
    credit_card: 1,
    debit_card: 2,
    ticket: 3,
    online: 4,
    pix: 6,
  };

  const getPaymentMethod = (item, paymentMethodCodes) => {
    let paymentMethod = "";

    if (item === paymentMethodCodes.money) {
      paymentMethod = "Dinheiro";
    } else if (item === paymentMethodCodes.credit_card) {
      paymentMethod = "Cartão de crédito";
    } else if (item === paymentMethodCodes.debit_card) {
      paymentMethod = "Cartão de débito";
    } else if (item === paymentMethodCodes.ticket) {
      paymentMethod = "Ticket";
    } else if (item === paymentMethodCodes.online) {
      paymentMethod = "Pagamento online";
    } else {
      paymentMethod = "Pagamento PIX";
    }

    return <div>{paymentMethod}</div>;
  };

  const columns = [
    {
      title: "Itens",
      dataIndex: "name",
      key: "name",
      render: (item) => <div>{item}</div>,
    },
    {
      title: "Quantidade",
      dataIndex: "quantity",
      key: "quantity",
      responsive: ["xl"] as any,
      textWrap: "word-break",
      render: (text, record) => <span>{record.quantity}</span>,
    },
    {
      title: "Valor",
      dataIndex: "total",
      key: "total",
      render: (text, record) => {
        return <span>{`R$ ${currencyFormater(record.total)}`}</span>;
      },
    },
  ];

  const columnsPayments = [
    {
      title: "Tipo de pagamento",
      dataIndex: "type",
      key: "type",
      render: (_, record) => getPaymentMethod(record.type, paymentMethodCodes),
    },
    {
      title: "Código NSU",
      dataIndex: "code_nsu",
      key: "code_nsu",
      render: (item) => item,
    },
    {
      title: "Status TEF",
      dataIndex: "tef_status_payment",
      key: "tef_status_payment",
      render: (item, record) =>
        record.code_nsu ? (
          item === 0 ? (
            <span>Aprovada</span>
          ) : (
            <span>Cancelada</span>
          )
        ) : (
          "Venda realizada sem TEF"
        ),
    },
    {
      title: "Valor",
      dataIndex: "amount",
      key: "amount",
      responsive: ["xl"] as any,
      textWrap: "word-break",
      render: (payment) => <span>{`R$ ${currencyFormater(payment)}`}</span>,
    },
  ];

  return (
    <Modal
      title="Detalhes da venda"
      centered
      visible={visible}
      closable={false}
      width={"80%"}
      footer={
        <ContentFooter>
          <ButtonCancel type="link" onClick={handleModalToggle}>
            Cancelar
          </ButtonCancel>

          <ButtonOk onClick={() => setVisible(false)}>Concluir</ButtonOk>
        </ContentFooter>
      }
    >
      {loading ? (
        <Spin />
      ) : cashHistorySale?.items ? (
        <Container>
          <ContentTopInformation>
            <IdText>
              Identificação: <span>{cashHistorySale?.id}</span>
            </IdText>
            <ContentAmountAndDiscount>
              <>
                <span>{`Desconto: R$ ${currencyFormater(
                  +cashHistorySale?.discount
                )}`}</span>
                <span>{`Troco: R$ ${currencyFormater(
                  +cashHistorySale?.change_amount
                )}`}</span>
              </>
            </ContentAmountAndDiscount>
          </ContentTopInformation>

          <Table
            rowKey={(record: any) => record.id}
            loading={loading}
            columns={columns}
            pagination={false}
            dataSource={cashHistorySale?.items.map((entity) => ({
              ...entity,
              key: entity.id,
            }))}
          />
          <Table
            style={{ marginTop: "2rem" }}
            loading={loading}
            columns={columnsPayments}
            pagination={false}
            dataSource={cashHistorySale?.payments.map((entity) => ({
              ...entity,
              key: entity.id,
            }))}
          />
        </Container>
      ) : (
        <Empty />
      )}
    </Modal>
  );
};

export default ModalSaleDetail;
