import React, { SetStateAction, Dispatch, useState, useEffect } from "react";

import apiMsNf from "../../../services/apiMsNf";
import { onlyNumbers } from "../../../services/onlyNumber";

import moment from "moment";
import locale from "antd/es/date-picker/locale/pt_BR";

import InputCurrency from "../InputCurrency";

import { ItemsNfe } from "../../../models/NfeXML";

import uf from "../models/uf.json";
import goal from "../models/goal.json";
import freight from "../models/freight.json";
import taxRegime from "../models/taxRegime.json";
import paymentForm from "../models/paymentForm.json";
import presenceIndicator from "../models/presenceIndicator.json";

import NFEDrawerItem from "../NFEDrawerItem";

import InputMask from "../../../components/InputMask";
import MonetaryInput from "../../../components/MonetaryInput2";

import Centralizer from "../../../containers/Centralizer";

import { getIpi } from "../../../utils/getIpi";

import { DatePicker, TimePicker, Spin, notification, Tooltip } from "antd";

import {
  Container,
  ContentModal,
  Col,
  Form,
  Input,
  Row,
  Select,
  Checkbox,
  Switch,
  Divider,
  Button,
  ButtonCancel,
  IconInfo,
  IconTrash,
  IconEdit,
  IconContainer,
  TextAreaInput,
  ModalUploadNfe,
  ContainerUploadXML,
  UploadXMLIcon,
  ButtonModalUpload,
  ContainerItems,
} from "./styles";

const { Option } = Select;

const amadelliCNPJ = process.env.REACT_APP_CNPJ_AMADELLI;

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  store: any;
  productsStore: any[];
}

const NFEModalDevolution: React.FC<IProps> = ({
  visible,
  setVisible,
  setShouldSearch,
  store,
  productsStore,
}) => {
  const [form] = Form.useForm();
  const [cpf, setCpf] = useState<string>("");
  const [cnpj, setCnpj] = useState<string>("");
  const [selectItem, setSelectItem] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingCnpj, setLoadingCnpj] = useState<boolean>(false);
  const [loadingItems, setLoadingItems] = useState<boolean>(false);
  const [loadingTotal, setLoadingTotal] = useState<boolean>(false);
  const [visibleUploadNfe, setVisibleUploadNfe] = useState<boolean>(true);
  const [visibleDrawerItem, setVisibleDrawerItem] = useState<boolean>(false);
  const [typePersonSelect, setTypePersonSelect] = useState<number>(2);
  const [items, setItems] = useState<ItemsNfe[]>([]);
  const [file, setFile] = useState<any>(null);
  const [xmlImport, setXmlImport] = useState<any>();
  const [selectsXml, setSelectsXml] = useState<{
    regime_tributario_destinatario: string;
    indicador_inscricao_estadual_destinatario: string;
    finalidade_emissao: string;
    transporte: string;
    modalidade_frete: string;
    indicador_pagamento: string;
    forma_pagamento: string;
    presenca_comprador: string;
    tipo_documento: string;
    local_destino: string;
    emitente_regime_tributario: string;
    consumidor_final: boolean;
  }>({
    regime_tributario_destinatario: "1",
    indicador_inscricao_estadual_destinatario: "1",
    finalidade_emissao: "4",
    transporte: "0",
    modalidade_frete: "9",
    indicador_pagamento: "0",
    forma_pagamento: "01",
    presenca_comprador: "9",
    tipo_documento: "1",
    local_destino: "1",
    emitente_regime_tributario: "1",
    consumidor_final: false,
  });

  useEffect(() => {
    const resetModal = async () => {
      if (visible) {
        setVisibleUploadNfe(true);
      } else {
        resetFields();
      }
    };
    resetModal();
  }, [visible]);

  useEffect(() => {
    const calculaValorTotal = async () => {
      let totalNfe;
      totalNfe = {
        valor_produtos: 0,
        icms_base_calculo: 0,
        icms_valor: 0,
        icms_base_calculo_st: 0,
        icms_valor_total_st: 0,
        valor_total_tributos: 0,
        valor_frete: 0,
        valor_desconto: 0,
        valor_seguro: 0,
        valor_outras_despesas: 0,
        valor_total_ipi: 0,
      };

      items.reduce((accumulator, currentItem) => {
        accumulator.valor_produtos += +(currentItem?.valor_bruto || 0);
        accumulator.icms_base_calculo += +(currentItem?.icms_base_calculo || 0);
        accumulator.icms_valor += +(currentItem?.icms_valor || 0);
        accumulator.icms_base_calculo_st += +(
          currentItem?.icms_base_calculo_st || 0
        );
        accumulator.icms_valor_total_st += +(currentItem?.icms_valor_st || 0);
        accumulator.valor_frete += +(currentItem?.valor_frete || 0);
        accumulator.valor_desconto += +(currentItem?.valor_desconto || 0);
        accumulator.valor_seguro += +(currentItem?.valor_seguro || 0);
        accumulator.valor_outras_despesas += +(
          currentItem?.valor_outras_despesas || 0
        );
        accumulator.valor_total_tributos += +(
          currentItem?.valor_total_tributos || 0
        );
        accumulator.valor_total_ipi += +(currentItem?.ipi_valor || 0);
        return accumulator;
      }, totalNfe);

      for (let value in totalNfe) {
        totalNfe[value] = totalNfe[value]?.toFixed(2);
      }

      await form.setFieldsValue(totalNfe);
      changeValue();
    };

    calculaValorTotal();
  }, [items, loadingTotal, loadingItems]);

  const resetFields = async () => {
    setItems([
      {
        codigo_produto: "",
        descricao: "",
        quantidade_comercial: 0,
        unidade_comercial: "",
        valor_unitario_comercial: 0,
        valor_bruto: 0,
        codigo_ncm: "",
      },
    ]);
    setSelectsXml({
      regime_tributario_destinatario: "1",
      indicador_inscricao_estadual_destinatario: "1",
      finalidade_emissao: "4",
      transporte: "0",
      modalidade_frete: "9",
      indicador_pagamento: "0",
      forma_pagamento: "01",
      presenca_comprador: "9",
      tipo_documento: "1",
      local_destino: "1",
      emitente_regime_tributario:
        store?.company?.emitente_regime_tributario?.toString(),
      consumidor_final: false,
    });
    await form.resetFields();
    setFile(null);
    setCnpj("");
    setCpf("");
  };

  const changeValue = async () => {
    let calcTotal = await form.getFieldsValue([
      "valor_produtos",
      "valor_frete",
      "valor_seguro",
      "valor_outras_despesas",
      "valor_desconto",
      "icms_valor_total",
      "icms_valor_total_st",
      "valor_total",
      "valor_total_ipi",
    ]);

    calcTotal.valor_total = +(
      +(calcTotal.valor_produtos || 0) +
      +(calcTotal.valor_frete || 0) +
      +(calcTotal.valor_outras_despesas || 0) +
      +(
        +(calcTotal.icms_valor_total_st || 0) -
        +(calcTotal.icms_valor_total || 0)
      ) +
      +(calcTotal.valor_total_ipi || 0) +
      +(calcTotal.valor_seguro || 0) -
      +(calcTotal.valor_desconto || 0)
    )?.toFixed(2);

    if (calcTotal.valor_total < 0) {
      calcTotal.valor_total = 0?.toFixed(2);
    }
    await form.setFieldsValue(calcTotal);
  };

  const handleState = async (value, type) => {
    const valueNumber = value?.replace(/[^0-9]+/g, "");
    if (value) {
      if (type === "cnpj") {
        setCnpj(valueNumber);
        if (valueNumber?.toString()?.length === 14) {
          await getInfo(valueNumber);
        }
      } else {
        setCpf(valueNumber);
      }
    }
  };
  const getInfo = async (value) => {
    setLoadingCnpj(true);
    try {
      const {
        data: { content },
      } = await apiMsNf.get(`/client/cnpj/${value}`);

      form.setFieldsValue({
        nome_destinatario: content.cnpj.razao_social,
        cep_destinatario: content.cnpj.endereco.cep,
        logradouro_destinatario: content.cnpj.endereco.logradouro,
        numero_destinatario: content.cnpj.endereco.numero,
        municipio_destinatario: content.cnpj.endereco.nome_municipio,
        bairro_destinatario: content.cnpj.endereco.bairro,
        uf_destinatario: content.cnpj.endereco.uf,
      });
    } catch (e) {
      notification.error({
        message: "Erro ao pesquisar endereço",
        duration: 5,
      });
    } finally {
      setLoadingCnpj(false);
    }
  };

  const handleFileChangeXml = (event) => {
    const file = event.target.files?.[0];
    if (file?.type === "text/xml") {
      setItems([]);
      setFile(file);
      const reader = new FileReader();
      reader.readAsText(file);
      reader.onload = (event) => {
        const xml = event.target?.result?.toString() ?? "";
        if (xml) {
          const parser = new DOMParser();
          const xmlDoc = parser.parseFromString(xml, "text/xml");
          const getValueFromTag = (
            tagName: string,
            parentElement: Element
          ): string => {
            return (
              parentElement?.getElementsByTagName(tagName)[0]?.textContent ?? ""
            );
          };
          let _xmlProps;
          let _xmlSelects;

          const xmlNfeIDE = xmlDoc.getElementsByTagName("ide")[0] || null;
          if (xmlNfeIDE) {
            _xmlProps = {
              natureza_operacao: "Devolução de mercadoria",
              data_emi: getValueFromTag("dhEmi", xmlNfeIDE),
              data_saida:
                getValueFromTag("dhSaiEnt", xmlNfeIDE) ||
                moment().toISOString(),
              modelo: getValueFromTag("mod", xmlNfeIDE),
            };
            _xmlSelects = {
              consumidor_final:
                getValueFromTag("indFinal", xmlNfeIDE) === "1" ? true : false,
              local_destino: getValueFromTag("idDest", xmlNfeIDE) || "1",
              tipo_documento: getValueFromTag("tpNF", xmlNfeIDE) || "1",
              finalidade_emissao: "4",
              presenca_comprador: getValueFromTag("indPres", xmlNfeIDE),
            };
          }
          _xmlProps.data_emissao = moment();
          _xmlProps.hora_emissao = moment();
          _xmlProps.data_entrada_saida = moment();
          _xmlProps.hora_entrada_saida = moment();

          const xmlNfeDEST = xmlDoc.getElementsByTagName("dest")[0] || null;
          if (xmlNfeDEST) {
            _xmlProps = {
              ..._xmlProps,
              nome_fantasia_emitente: getValueFromTag("xFant", xmlNfeDEST),
              cnpj_emitente: getValueFromTag("CNPJ", xmlNfeDEST),
              inscricao_estadual_emitente: getValueFromTag("IE", xmlNfeDEST),
              cpf_destinatario: getValueFromTag("CPF", xmlNfeDEST),
              nome_emitente: getValueFromTag("xNome", xmlNfeDEST),
              logradouro_emitente: getValueFromTag("xLgr", xmlNfeDEST),
              numero_emitente: getValueFromTag("nro", xmlNfeDEST),
              complemento_emitente: getValueFromTag("xCpl", xmlNfeDEST),
              bairro_emitente: getValueFromTag("xBairro", xmlNfeDEST),
              municipio_emitente: getValueFromTag("xMun", xmlNfeDEST),
              uf_emitente: getValueFromTag("UF", xmlNfeDEST),
              cep_emitente: getValueFromTag("CEP", xmlNfeDEST),
              telefone_emitente: getValueFromTag("fone", xmlNfeDEST),
              email_emitente: getValueFromTag("email", xmlNfeDEST),
            };
          }
          const xmlNfeEMIT = xmlDoc.getElementsByTagName("emit")[0] || null;
          if (xmlNfeEMIT) {
            _xmlProps = {
              ..._xmlProps,
              nome_fantasia_destinatario: getValueFromTag("xFant", xmlNfeEMIT),
              cnpj_destinatario: getValueFromTag("CNPJ", xmlNfeEMIT),
              cpf_destinatario: getValueFromTag("CPF", xmlNfeEMIT),
              inscricao_estadual_destinatario: getValueFromTag(
                "IE",
                xmlNfeEMIT
              ),
              nome_destinatario: getValueFromTag("xNome", xmlNfeEMIT),
              logradouro_destinatario: getValueFromTag("xLgr", xmlNfeEMIT),
              numero_destinatario: getValueFromTag("nro", xmlNfeEMIT),
              complemento_destinatario: getValueFromTag("xCpl", xmlNfeEMIT),
              bairro_destinatario: getValueFromTag("xBairro", xmlNfeEMIT),
              municipio_destinatario: getValueFromTag("xMun", xmlNfeEMIT),
              uf_destinatario: getValueFromTag("UF", xmlNfeEMIT),
              cep_destinatario: getValueFromTag("CEP", xmlNfeEMIT),
              telefone_destinatario: getValueFromTag("fone", xmlNfeEMIT),
              email_destinatario: getValueFromTag("email", xmlNfeEMIT),
            };
            _xmlSelects = {
              ..._xmlSelects,
              regime_tributario_destinatario: getValueFromTag(
                "CRT",
                xmlNfeEMIT
              ),
              indicador_inscricao_estadual_destinatario:
                getValueFromTag("indIEDest", xmlNfeEMIT) || "1",
            };
          }
          setTypePersonSelect(_xmlProps?.cpf_destinatario?.length ? 1 : 2);
          handleState(_xmlProps?.cnpj_destinatario, "cnpj");
          handleState(_xmlProps?.cpf_destinatario, "cpf");
          const xmlNfeinfProt =
            xmlDoc.getElementsByTagName("infProt")[0] || null;
          if (xmlNfeinfProt) {
            _xmlProps = {
              ..._xmlProps,
              chave_nfe: getValueFromTag("chNFe", xmlNfeinfProt),
            };
          }

          const xmlNfeinfTotal =
            xmlDoc.getElementsByTagName("total")[0] || null;
          if (xmlNfeinfTotal) {
            _xmlProps = {
              ..._xmlProps,
              valor_produtos: +getValueFromTag("vProd", xmlNfeinfTotal) || 0,
              valor_frete: +getValueFromTag("vFrete", xmlNfeinfTotal) || 0,
              valor_seguro: +getValueFromTag("vSeg", xmlNfeinfTotal) || 0,
              valor_outras_despesas:
                +getValueFromTag("vOutro", xmlNfeinfTotal) || 0,
              valor_desconto: +getValueFromTag("vDesc", xmlNfeinfTotal) || 0,
              icms_base_calculo: getValueFromTag("vBC", xmlNfeinfTotal) || 0,
              icms_valor: getValueFromTag("vICMS", xmlNfeinfTotal) || 0,
              icms_base_calculo_st:
                getValueFromTag("vBCST", xmlNfeinfTotal) || 0,
              icms_valor_total_st: getValueFromTag("vST", xmlNfeinfTotal) || 0,
              valor_total: getValueFromTag("vNF", xmlNfeinfTotal) || 0,
              valor_total_servicos:
                getValueFromTag("vServ", xmlNfeinfTotal) || 0,
              issqn_valor_total: getValueFromTag("vISS", xmlNfeinfTotal) || 0,
              ipi_valor: getValueFromTag("vIPI", xmlNfeinfTotal) || 0,
              valor_total_tributos:
                getValueFromTag("vTotTrib", xmlNfeinfTotal) || 0,
            };
          }
          const xmlNfeinfTransporta =
            xmlDoc.getElementsByTagName("transporta")[0] || null;
          if (xmlNfeinfTransporta) {
            _xmlProps = {
              ..._xmlProps,
              cpf_transportador: getValueFromTag("CPF", xmlNfeinfTransporta),
              cnpj_transportador: getValueFromTag("CNPJ", xmlNfeinfTransporta),
              nome_transportador: getValueFromTag("xNome", xmlNfeinfTransporta),
              veiculo_rntc: getValueFromTag("RNTC", xmlNfeinfTransporta),
              inscricao_estadual_transportador: getValueFromTag(
                "IE",
                xmlNfeinfTransporta
              ),

              endereco_transportador: getValueFromTag(
                "xEnder",
                xmlNfeinfTransporta
              ),
              municipio_transportador: getValueFromTag(
                "xMun",
                xmlNfeinfTransporta
              ),
              uf_transportador: getValueFromTag("UF", xmlNfeinfTransporta),
            };
          }
          const xmlNfeinfVeicTransp =
            xmlDoc.getElementsByTagName("veicTransp")[0] || null;
          if (xmlNfeinfVeicTransp) {
            _xmlProps = {
              ..._xmlProps,
              veiculo_placa: getValueFromTag("placa", xmlNfeinfTransporta),
              uf_transportador: getValueFromTag("UF", xmlNfeinfTransporta),
            };
          }
          const xmlNfeinfVol = xmlDoc.getElementsByTagName("vol")[0] || null;
          if (xmlNfeinfVol) {
            _xmlProps = {
              ..._xmlProps,
              quantidade: getValueFromTag("qVol", xmlNfeinfVol),
              marca: getValueFromTag("marca", xmlNfeinfVol),
              numero: getValueFromTag("nVol", xmlNfeinfVol),
              peso_liquido: getValueFromTag("pesoL", xmlNfeinfVol),
              peso_bruto: getValueFromTag("pesoB", xmlNfeinfVol),
            };
          }

          const xmlNfeinfAdic =
            xmlDoc.getElementsByTagName("infAdic")[0] || null;
          if (xmlNfeinfAdic) {
            _xmlProps = {
              ..._xmlProps,
              informacoes_adicionais_contribuinte: getValueFromTag(
                "infCpl",
                xmlNfeinfAdic
              ),
              informacoes_adicionais_fisco: getValueFromTag(
                "infAdFisco",
                xmlNfeinfAdic
              ),
            };
          }

          const arrayItensXml = Array.from(
            xmlDoc.getElementsByTagName("det")
          ).map((_item) => ({
            prodElement: _item,
            xmlNfeImposto: _item.getElementsByTagName("imposto") || null,
            xmlNfeIcmsImp: _item.getElementsByTagName("ICMS") || null,
            xmlNfeIpiImp: _item.getElementsByTagName("IPI") || null,
            xmlNfePisImp: _item.getElementsByTagName("PIS") || null,
            xmlNfeCofinsImp: _item.getElementsByTagName("COFINS") || null,
          }));

          const _items = arrayItensXml.map((_itemXml, index) => {
            const prodElement = _itemXml.prodElement;
            const xmlNfeImposto = _itemXml.xmlNfeImposto[0] || null;
            const xmlNfeIcmsImp = _itemXml.xmlNfeIcmsImp[0] || null;
            const xmlNfeIpiImp = _itemXml.xmlNfeIpiImp[0] || null;
            const xmlNfePisImp = _itemXml.xmlNfePisImp[0] || null;
            const xmlNfeCofinsImp = _itemXml.xmlNfeCofinsImp[0] || null;
            const item = {
              natureza_operacao: _xmlProps.natureza_operacao,
              valor_total_tributos:
                +getValueFromTag("vTotTrib", xmlNfeImposto) || 0,
              codigo_produto: getValueFromTag("cProd", prodElement),
              descricao: getValueFromTag("xProd", prodElement),
              quantidade_max_comercial: +getValueFromTag("qCom", prodElement),
              quantidade_comercial: +getValueFromTag("qCom", prodElement),
              unidade_comercial: getValueFromTag("uCom", prodElement),
              valor_unitario_comercial: +(+getValueFromTag(
                "vUnCom",
                prodElement
              ))?.toFixed(2),
              valor_bruto: +(+getValueFromTag("vProd", prodElement))?.toFixed(
                2
              ),
              valor_frete: +getValueFromTag("vFrete", prodElement) || 0,
              valor_frete_quantity:
                +getValueFromTag("vFrete", prodElement) /
                +getValueFromTag("qCom", prodElement),
              valor_seguro: +getValueFromTag("vSeg", prodElement) || 0,
              valor_seguro_quantity:
                +getValueFromTag("vSeg", prodElement) /
                +getValueFromTag("qCom", prodElement),
              valor_outras_despesas:
                +getValueFromTag("vOutro", prodElement) || 0,
              valor_outras_despesas_quantity:
                +getValueFromTag("vOutro", prodElement) /
                +getValueFromTag("qCom", prodElement),
              valor_desconto: +getValueFromTag("vDesc", prodElement) || 0,
              valor_desconto_quantity:
                +getValueFromTag("vDesc", prodElement) /
                +getValueFromTag("qCom", prodElement),
              cfop: "",
              codigo_ncm: getValueFromTag("NCM", prodElement),
              cest: getValueFromTag("CEST", prodElement),
              codigo_beneficio_fiscal:
                getValueFromTag("cBenef", prodElement) || "SEM CBENEF",
              codigo_barras_comercial: getValueFromTag("cEAN", prodElement),
              codigo_barras_tributavel: getValueFromTag(
                "cEANTrib",
                prodElement
              ),
              codigo_barras_proprio_comercial: getValueFromTag(
                "cBarra",
                prodElement
              ),
              codigo_barras_proprio_tributavel: getValueFromTag(
                "cBarraTrib",
                prodElement
              ),
              icms_situacao_tributaria:
                getValueFromTag("CST", prodElement) ||
                getValueFromTag("CSOSN", prodElement),

              icms_aliquota:
                getValueFromTag("pICMS", xmlNfeIcmsImp) ||
                getValueFromTag("adRemICMS", xmlNfeIcmsImp),
              icms_aliquota_quantity:
                getValueFromTag("pICMS", xmlNfeIcmsImp) ||
                getValueFromTag("adRemICMS", xmlNfeIcmsImp),
              icms_aliquota_credito_simples: getValueFromTag(
                "pCredSN",
                xmlNfeIcmsImp
              ),
              icms_aliquota_final: getValueFromTag("pST", xmlNfeIcmsImp),
              icms_aliquota_retencao: getValueFromTag(
                "adRemICMSReten",
                xmlNfeIcmsImp
              ),
              icms_aliquota_retido: getValueFromTag(
                "adRemICMSRet",
                xmlNfeIcmsImp
              ),
              icms_aliquota_st: getValueFromTag("pICMSST", xmlNfeIcmsImp),
              icms_aliquota_st_quantity: getValueFromTag(
                "pICMSST",
                xmlNfeIcmsImp
              ),
              icms_base_calculo: +getValueFromTag("vBC", xmlNfeIcmsImp) || 0,
              icms_base_calculo_quantity:
                +getValueFromTag("vBC", xmlNfeIcmsImp) /
                +getValueFromTag("qCom", prodElement),
              icms_base_calculo_mono:
                +getValueFromTag("qBCMono", xmlNfeIcmsImp) || 0,
              icms_base_calculo_mono_retencao:
                +getValueFromTag("qBCMonoReten", xmlNfeIcmsImp) || 0,
              icms_base_calculo_mono_retido:
                +getValueFromTag("qBCMonoRet", xmlNfeIcmsImp) || 0,
              icms_base_calculo_retido_st:
                +getValueFromTag("vBCSTRet", xmlNfeIcmsImp) || 0,
              icms_base_calculo_st: +getValueFromTag("vBCST", xmlNfeIcmsImp),
              icms_base_calculo_st_quantity:
                +getValueFromTag("vBCST", xmlNfeIcmsImp) /
                +getValueFromTag("qCom", prodElement),
              icms_margem_valor_adicionado_st:
                getValueFromTag("pMVAST", xmlNfeIcmsImp) || 0,
              icms_modalidade_base_calculo: getValueFromTag(
                "modBC",
                xmlNfeIcmsImp
              ),
              icms_modalidade_base_calculo_st: getValueFromTag(
                "modBCST",
                xmlNfeIcmsImp
              ),
              icms_motivo_desoneracao: getValueFromTag(
                "motDesICMS",
                xmlNfeIcmsImp
              ),
              icms_motivo_reducao: getValueFromTag(
                "motRedAdRem",
                xmlNfeIcmsImp
              ),
              icms_origem: getValueFromTag("orig", xmlNfeIcmsImp),
              icms_percentual_diferimento: getValueFromTag(
                "pDif",
                xmlNfeIcmsImp
              ),
              icms_percentual_reducao: getValueFromTag(
                "pRedAdRem",
                xmlNfeIcmsImp
              ),
              icms_reducao_base_calculo: getValueFromTag(
                "pRedBC",
                xmlNfeIcmsImp
              ),
              icms_reducao_base_calculo_st: getValueFromTag(
                "pRedBCST",
                xmlNfeIcmsImp
              ),
              icms_valor: +getValueFromTag("vICMS", xmlNfeIcmsImp) || 0,
              icms_valor_credito_simples: getValueFromTag(
                "vCredICMSSN",
                xmlNfeIcmsImp
              ),
              icms_valor_desonerado: getValueFromTag(
                "vICMSDeson",
                xmlNfeIcmsImp
              ),
              icms_valor_diferido: getValueFromTag("vICMSDif", xmlNfeIcmsImp),
              icms_valor_mono: getValueFromTag("vICMSMono", xmlNfeIcmsImp),
              icms_valor_mono_diferido: getValueFromTag(
                "vICMSMonoDif",
                xmlNfeIcmsImp
              ),
              icms_valor_mono_operacao: getValueFromTag(
                "vICMSMonoOp",
                xmlNfeIcmsImp
              ),
              icms_valor_mono_retencao: getValueFromTag(
                "vICMSMonoReten",
                xmlNfeIcmsImp
              ),
              icms_valor_mono_retido: getValueFromTag(
                "vICMSMonoRet",
                xmlNfeIcmsImp
              ),
              icms_valor_operacao: getValueFromTag("vICMSOp", xmlNfeIcmsImp),
              icms_valor_retido_st: getValueFromTag(
                "vICMSSTRet",
                xmlNfeIcmsImp
              ),
              icms_valor_st: +getValueFromTag("vICMSST", xmlNfeIcmsImp) || 0,
              icms_valor_substituto: getValueFromTag(
                "vICMSSubstituto",
                xmlNfeIcmsImp
              ),

              fcp_percentual: getValueFromTag("pFCP", xmlNfeIcmsImp),
              fcp_valor: getValueFromTag("vFCP", xmlNfeIcmsImp),
              icms_fcp_percentual_diferimento: getValueFromTag(
                "pFCPDif",
                xmlNfeIcmsImp
              ),
              icms_fcp_valor_diferido: getValueFromTag(
                "vFCPDif",
                xmlNfeIcmsImp
              ),
              icms_fcp_valor_efetivo: getValueFromTag(
                "vFCPEfet",
                xmlNfeIcmsImp
              ),
              informacoes_adicionais_item: getValueFromTag(
                "infAdProd",
                xmlNfeIcmsImp
              ),

              pis_situacao_tributaria:
                getValueFromTag("CST", xmlNfePisImp) === "01"
                  ? "50"
                  : getValueFromTag("CST", xmlNfePisImp) === "06"
                  ? "70"
                  : getValueFromTag("CST", xmlNfePisImp),
              pis_valor: getValueFromTag("vPIS", xmlNfePisImp),
              pis_aliquota_porcentual: getValueFromTag("pPIS", xmlNfePisImp),
              pis_base_calculo: getValueFromTag("vBC", xmlNfePisImp),
              cofins_situacao_tributaria:
                getValueFromTag("CST", xmlNfeCofinsImp) === "01"
                  ? "50"
                  : getValueFromTag("CST", xmlNfeCofinsImp) === "06"
                  ? "70"
                  : getValueFromTag("CST", xmlNfeCofinsImp),

              cofins_valor: getValueFromTag("vCOFINS", xmlNfeCofinsImp),
              cofins_aliquota_porcentual: getValueFromTag(
                "pCOFINS",
                xmlNfeCofinsImp
              ),
              cofins_base_calculo: getValueFromTag("vBC", xmlNfeCofinsImp),

              ipi_situacao_tributaria: getIpi(
                getValueFromTag("CST", xmlNfeIpiImp)
              ),
              ipi_base_calculo: +(getValueFromTag("vBC", xmlNfeIpiImp) || 0),
              ipi_base_calculo_quantity: +(
                +getValueFromTag("vBC", xmlNfeIpiImp) /
                  +getValueFromTag("qCom", prodElement) || 0
              ),
              ipi_aliquota: getValueFromTag("pIPI", xmlNfeIpiImp),
              ipi_valor: getValueFromTag("vIPI", xmlNfeIpiImp),
              ipi_quantidade_total: getValueFromTag("qUnid", xmlNfeIpiImp),
              ipi_valor_por_unidade_tributavel: getValueFromTag(
                "vUnid",
                xmlNfeIpiImp
              ),
              ipi_cnpj_produtor: getValueFromTag("CNPJProd", xmlNfeIpiImp),
              ipi_codigo_selo_controle: getValueFromTag("cSelo", xmlNfeIpiImp),
              ipi_quantidade_selo_controle: getValueFromTag(
                "qSelo",
                xmlNfeIpiImp
              ),
              ipi_codigo_enquadramento_legal: getValueFromTag(
                "cEnq",
                xmlNfeIpiImp
              ),
            };

            for (const key in item) {
              if (item.hasOwnProperty(key) && !item[key]) {
                delete item[key];
              }
            }
            return item;
          });
          const xmlNfePag = xmlDoc.getElementsByTagName("pag")[0] || null;
          if (xmlNfePag) {
            _xmlProps = {
              ..._xmlProps,
              valor_pagamento: getValueFromTag("vPag", xmlNfePag),
            };
            _xmlSelects = {
              ..._xmlSelects,
              indicador_pagamento: getValueFromTag("indPag", xmlNfePag) || "0",
              forma_pagamento: getValueFromTag("tPag", xmlNfePag) || "01",
            };
          }

          const xmlNfeTransp = xmlDoc.getElementsByTagName("transp")[0] || null;
          if (xmlNfeTransp) {
            _xmlSelects = {
              ..._xmlSelects,
              transporte: getValueFromTag("tpViaTransp", xmlNfeTransp) || "0",
              modalidade_frete: getValueFromTag("modFrete", xmlNfeTransp),
            };
          }
          setItems(_items);
          setXmlImport(_xmlProps);
          setSelectsXml(_xmlSelects);
        }
      };
    } else {
      return notification.warning({
        message: "Formato inválido",
        description: "Apenas é possível o upload de arquivos no formato .xml",
        duration: 5,
      });
    }
  };

  const changeQuantity = async (event, codigo_produto) => {
    setLoadingTotal(true);
    let newListItens = items;

    const index = newListItens.findIndex(
      (item) => item.codigo_produto === codigo_produto
    );
    if (
      (newListItens[index]?.quantidade_max_comercial || 1) >= event.target.value
    ) {
      newListItens[index] = {
        ...newListItens[index],
        quantidade_comercial: +event.target.value,
        valor_frete: +(
          +event.target.value * (newListItens[index]?.valor_frete_quantity || 0)
        )?.toFixed(2),
        valor_seguro: +(
          +event.target.value *
          (newListItens[index]?.valor_seguro_quantity || 0)
        )?.toFixed(2),
        valor_outras_despesas: +(
          +event.target.value *
          (newListItens[index]?.valor_outras_despesas_quantity || 0)
        )?.toFixed(2),
        valor_desconto: +(
          +event.target.value *
          (newListItens[index]?.valor_desconto_quantity || 0)
        )?.toFixed(2),
        icms_base_calculo:
          newListItens[index].icms_situacao_tributaria === "00" ||
          newListItens[index].icms_situacao_tributaria === "10" ||
          newListItens[index].icms_situacao_tributaria === "10_partilha" ||
          newListItens[index].icms_situacao_tributaria === "20" ||
          newListItens[index].icms_situacao_tributaria === "51" ||
          newListItens[index].icms_situacao_tributaria === "70" ||
          newListItens[index].icms_situacao_tributaria === "90" ||
          newListItens[index].icms_situacao_tributaria === "90_partilha" ||
          newListItens[index].icms_situacao_tributaria === "900"
            ? +(
                +event.target.value *
                (newListItens[index]?.icms_base_calculo_quantity || 0)
              )?.toFixed(2)
            : 0,
        icms_valor:
          newListItens[index].icms_situacao_tributaria === "00" ||
          newListItens[index].icms_situacao_tributaria === "10" ||
          newListItens[index].icms_situacao_tributaria === "10_partilha" ||
          newListItens[index].icms_situacao_tributaria === "20" ||
          newListItens[index].icms_situacao_tributaria === "51" ||
          newListItens[index].icms_situacao_tributaria === "70" ||
          newListItens[index].icms_situacao_tributaria === "90" ||
          newListItens[index].icms_situacao_tributaria === "90_partilha" ||
          newListItens[index].icms_situacao_tributaria === "900"
            ? +(
                +event.target.value *
                (newListItens[index]?.icms_base_calculo_quantity || 0) *
                (+(newListItens[index]?.icms_aliquota || 0) / 100)
              )?.toFixed(2)
            : 0,
        icms_base_calculo_st:
          newListItens[index].icms_situacao_tributaria === "10" ||
          newListItens[index].icms_situacao_tributaria === "10_partilha" ||
          newListItens[index].icms_situacao_tributaria === "30" ||
          newListItens[index].icms_situacao_tributaria === "70" ||
          newListItens[index].icms_situacao_tributaria === "90" ||
          newListItens[index].icms_situacao_tributaria === "90_partilha" ||
          newListItens[index].icms_situacao_tributaria === "201" ||
          newListItens[index].icms_situacao_tributaria === "202" ||
          newListItens[index].icms_situacao_tributaria === "203" ||
          newListItens[index].icms_situacao_tributaria === "900"
            ? +(
                +event.target.value *
                (newListItens[index]?.icms_base_calculo_st_quantity || 0)
              )?.toFixed(2)
            : 0,
        icms_valor_st:
          newListItens[index].icms_situacao_tributaria === "10" ||
          newListItens[index].icms_situacao_tributaria === "10_partilha" ||
          newListItens[index].icms_situacao_tributaria === "30" ||
          newListItens[index].icms_situacao_tributaria === "70" ||
          newListItens[index].icms_situacao_tributaria === "90" ||
          newListItens[index].icms_situacao_tributaria === "90_partilha" ||
          newListItens[index].icms_situacao_tributaria === "201" ||
          newListItens[index].icms_situacao_tributaria === "202" ||
          newListItens[index].icms_situacao_tributaria === "203" ||
          newListItens[index].icms_situacao_tributaria === "900"
            ? +(
                +event.target.value *
                  (newListItens[index]?.icms_base_calculo_st_quantity || 0) *
                  (+(newListItens[index]?.icms_aliquota_st || 0) / 100) -
                +(
                  +event.target.value *
                  (newListItens[index]?.icms_base_calculo_quantity || 0) *
                  (+(newListItens[index]?.icms_aliquota || 0) / 100)
                )
              )?.toFixed(2)
            : 0,
        ipi_base_calculo: +(
          +event.target.value *
          (newListItens[index]?.ipi_base_calculo_quantity || 0)
        )?.toFixed(2),
        ipi_valor: +(
          +event.target.value *
          (newListItens[index]?.ipi_base_calculo_quantity || 0) *
          (+(newListItens[index]?.ipi_aliquota || 0) / 100)
        )?.toFixed(2),
        valor_bruto: +(
          +(newListItens[index].valor_unitario_comercial || 0) *
          +event.target.value
        )?.toFixed(2),
      };

      setItems(newListItens);
    } else {
      notification.warning({
        message: `A quantidade de itens inseridos ultrapassa o limite de ${
          newListItens[index]?.quantidade_max_comercial || 1
        } item`,
        description: `A quantidade máxima foi fixada em ${
          newListItens[index]?.quantidade_max_comercial || 3
        }`,
        duration: 3,
      });
    }
    setTimeout(() => {
      setLoadingTotal(false);
    }, 100);
  };

  const changeItem = async (event, descricao, name) => {
    setLoadingTotal(true);
    let newListItens = items;

    const index = newListItens.findIndex(
      (item) => item.descricao === descricao
    );

    newListItens[index] = {
      ...newListItens[index],
      [name]: event.target.value,
    };

    setItems(newListItens);
    setTimeout(() => {
      setLoadingTotal(false);
    }, 100);
  };

  const productSelect = async (selectedProduct, index) => {
    setLoadingItems(true);
    let newItem;

    const _productFind = productsStore.find(
      (product) => product.product_id === selectedProduct
    );

    const itemIndex = items.findIndex(
      (_item) => _item.codigo_produto === selectedProduct
    );

    newItem = {
      codigo_produto: _productFind.product.id,
      descricao: _productFind.product.name,
      quantidade_comercial: 1,
      unidade_comercial: _productFind.unity_taxable || "Un",
      valor_unitario_comercial: +_productFind.price_unit,
      valor_bruto: +_productFind.price_unit,
      codigo_ncm: _productFind.product.cod_ncm,
      natureza_operacao: form.getFieldValue("natureza_operacao"),
      valor_frete: _productFind?.freight,
      valor_desconto: _productFind?.discount,
      valor_seguro: _productFind?.insurance,
      valor_outras_despesas: _productFind?.expenses,
      cfop: "",
      cest: _productFind?.cest,
      peso_liquido: _productFind?.weight,
      icms_situacao_tributaria: _productFind?.icms_tax_situation?.toString(),
      icms_origem: _productFind?.icms_origin?.toString(),
      icms_modalidade_base_calculo: _productFind?.bc_icms,
      codigo_beneficio_fiscal: _productFind?.codigo_beneficio_fiscal,
      pis_situacao_tributaria: _productFind?.pis_tax_situation,
      cofins_situacao_tributaria: _productFind?.cofins_tax_situation,
      pis_aliquota_porcentual: _productFind?.pis_aliquot_percentage,
      cofins_aliquota_porcentual: _productFind?.cofins_aliquot_percentage,
    };

    let newItemList = items;

    if (itemIndex !== -1) {
      setItems(items);
    } else {
      newItemList[index] = newItem;
      setItems(newItemList);
    }
    setTimeout(() => {
      setLoadingItems(false);
    }, 100);
  };

  const addItem = async () => {
    const itensLength = items.length - 1;

    if (items[itensLength]?.descricao !== "" || itensLength === -1) {
      const newItem = {
        codigo_produto: "",
        descricao: "",
        quantidade_comercial: 0,
        unidade_comercial: "",
        valor_unitario_comercial: 0,
        valor_bruto: 0,
        codigo_ncm: "",
      };
      setItems((oldValues) => [...oldValues, newItem]);
    }
  };

  const removeItem = async (indexItem) => {
    setLoadingItems(true);
    const newList = items.filter((_item) => _item.codigo_produto !== indexItem);

    setItems(newList);

    if (newList.length === 0 && selectsXml.finalidade_emissao !== "4") {
      addItem();
    }

    setTimeout(() => {
      setLoadingItems(false);
    }, 100);
  };

  const naturezaItemUpdate = (event) => {
    const newNatureza = event.target.value;

    setTimeout(() => {
      const _listItensUpdate = items.map((item) => ({
        ...item,
        natureza_operacao: newNatureza,
      }));

      setItems(_listItensUpdate);
    }, 1000);
  };

  const verificaItens = (itens) => {
    for (const item of itens) {
      if (!item.cfop) {
        return item;
      }
      if (item?.icms_situacao_tributaria !== "00") {
        if (!item?.codigo_beneficio_fiscal) {
          return item;
        }
      }
      if (!item.codigo_ncm) {
        return item;
      }
      if (!item.icms_situacao_tributaria) {
        return item;
      }
      if (!item.pis_situacao_tributaria) {
        return item;
      }
      if (!item.cofins_situacao_tributaria) {
        return item;
      }
    }
    return null;
  };

  const verificaItensICMS = (itens) => {
    for (const item of itens) {
      if (store?.company?.emitente_regime_tributario === 1) {
        if (
          item.icms_situacao_tributaria === "00" ||
          item.icms_situacao_tributaria === "02" ||
          item.icms_situacao_tributaria === "10" ||
          item.icms_situacao_tributaria === "10_partilha" ||
          item.icms_situacao_tributaria === "15" ||
          item.icms_situacao_tributaria === "20" ||
          item.icms_situacao_tributaria === "30" ||
          item.icms_situacao_tributaria === "40" ||
          item.icms_situacao_tributaria === "41" ||
          item.icms_situacao_tributaria === "41_st" ||
          item.icms_situacao_tributaria === "50" ||
          item.icms_situacao_tributaria === "51" ||
          item.icms_situacao_tributaria === "53" ||
          item.icms_situacao_tributaria === "60" ||
          item.icms_situacao_tributaria === "60_st" ||
          item.icms_situacao_tributaria === "61" ||
          item.icms_situacao_tributaria === "70" ||
          item.icms_situacao_tributaria === "90" ||
          item.icms_situacao_tributaria === "90_partilha"
        )
          return item;
      }
    }
    return null;
  };

  const removerPropriedades = <T, K extends keyof T>(
    obj: T,
    props: K[]
  ): Omit<T, K> => {
    const newObj = { ...obj };
    props.forEach((prop) => delete newObj[prop]);
    return newObj;
  };

  const payloadConstruct = async () => {
    let _payload = await form.getFieldsValue();

    _payload = await Object.assign({}, _payload, selectsXml);

    const itemsSemPropsQuantity = items.map((item) =>
      removerPropriedades(item, [
        "valor_frete_quantity",
        "valor_desconto_quantity",
        "valor_seguro_quantity",
        "valor_outras_despesas_quantity",
        "quantidade_max_comercial",
        "icms_base_calculo_quantity",
        "icms_aliquota_quantity",
        "icms_base_calculo_st_quantity",
        "icms_aliquota_st_quantity",
        "ipi_base_calculo_quantity",
      ])
    );

    _payload = {
      ..._payload,
      nome_fantasia_emitente: xmlImport?.nome_fantasia_emitente,
      inscricao_estadual_emitente: xmlImport?.inscricao_estadual_emitente,
      cpf_destinatario: xmlImport?.cpf_destinatario,
      nome_emitente: xmlImport?.nome_emitente,
      logradouro_emitente: xmlImport?.logradouro_emitente,
      numero_emitente: xmlImport?.numero_emitente,
      complemento_emitente: xmlImport?.complemento_emitente,
      bairro_emitente: xmlImport?.bairro_emitente,
      municipio_emitente: xmlImport?.municipio_emitente,
      uf_emitente: xmlImport?.uf_emitente,
      cep_emitente: xmlImport?.cep_emitente,
      telefone_emitente: xmlImport?.telefone_emitente,
      email_emitente: xmlImport?.email_emitente,
      cnpj_destinatario: onlyNumbers(_payload.cnpj_destinatario)?.toString(),
      cnpj_emitente: onlyNumbers(_payload.cnpj_emitente)?.toString(),
      nome_fantasia_destinatario: xmlImport?.nome_fantasia_destinatario,
      itens: itemsSemPropsQuantity
        .filter((item) => item.descricao !== "")
        .map((item, index) => ({
          ...item,
          numero_item: index + 1,
          unidade_tributavel: item.unidade_comercial,
          quantidade_tributavel: item.quantidade_comercial,
          valor_unitario_tributavel: item.valor_unitario_comercial,
        })),
      store_id: store?.key,
      formas_pagamento:
        selectsXml?.finalidade_emissao !== "3" &&
        selectsXml?.finalidade_emissao !== "4"
          ? [
              {
                indicador_pagamento: _payload.indicador_pagamento,
                forma_pagamento: _payload.forma_pagamento,
                valor_pagamento: +_payload.valor_pagamento,
              },
            ]
          : [
              {
                forma_pagamento: "90",
                valor_pagamento: 0,
              },
            ],
      notas_referenciadas: [{ chave_nfe: _payload.chave_nfe }],
      consumidor_final: selectsXml?.consumidor_final ? "1" : "0",
    };

    _payload.data_emissao =
      moment(_payload.data_emissao).format("YYYY-MM-DD") +
      "T" +
      moment(_payload.hora_emissao).format("HH:mm:ss") +
      "Z";
    _payload.data_entrada_saida =
      moment(_payload.data_entrada_saida).format("YYYY-MM-DD") +
      "T" +
      moment(_payload.data_entrada_saida).format("HH:mm:ss") +
      "Z";

    delete _payload.hora_emissao;
    delete _payload.hora_entrada_saida;
    delete _payload.indicador_pagamento;
    delete _payload.forma_pagamento;
    delete _payload.valor_pagamento;
    delete _payload.chave_nfe;
    delete _payload.valor_total_ipi;

    for (const key in _payload) {
      if (_payload.hasOwnProperty(key) && !_payload[key]) {
        delete _payload[key];
      }
    }
    for (const key in _payload) {
      if (key.includes("qtd")) {
        delete _payload[key];
      }
    }

    if (selectsXml?.finalidade_emissao !== "4") {
      delete _payload.notas_referenciadas;
    }

    for (const key in _payload) {
      if (_payload.hasOwnProperty(key) && !_payload[key]) {
        delete _payload[key];
      }
    }
    return _payload;
  };

  const onFinish = async () => {
    await form.validateFields();

    if (!items.length) {
      return notification.warning({
        message: `Falha ao emitir a nota`,
        description: "A nota deve possuir pelomenos um item",
        duration: 5,
      });
    }

    let payload = await payloadConstruct();

    if (payload.cnpj_destinatario !== amadelliCNPJ) {
      return notification.warning({
        message: "O cnpj do destinatário inválido",
        description: "O cnpj informado não pertence a Amadelli",
        duration: 5,
      });
    }

    const verify = await verificaItens(items);
    const verifyICMS = await verificaItensICMS(items);

    if (verify) {
      return notification.warning({
        message: `O item ${verify?.descricao} possui dados faltantes`,
        description:
          "Por favor verifique os dados do item no botão Editar e preencha-os corretamente",
        duration: 5,
      });
    }

    if (verifyICMS) {
      return notification.warning({
        message: `O Regime Tributário de sua loja está cadastrado como 'Simples Nacional'`,
        description: `Por favor contate seu contador e verifique a 'Situação Tributária do ICMS' correta do item ${verifyICMS?.descricao}`,
        duration: 5,
      });
    }

    setLoading(true);
    try {
      await apiMsNf.post(`/nfe/authorize-nfe`, payload);
      notification.info({
        message: "Nota em processamento",
        description:
          "Esse processo pode demorar alguns minutos, caso a nota não apareça na lista por favor recarregue as informações da página",
        duration: 5,
      });
      setShouldSearch(true);
      setVisible(false);
    } catch (err) {
      //@ts-ignore
      const _description = err?.response?.data?.message;
      notification.error({
        message: "Erro ao emitir a nota",
        description: _description || "",
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  const updateFile = () => {
    setLoading(true);
    if (xmlImport.cnpj_emitente === store?.company?.cnpj) {
      form.setFieldsValue(xmlImport);
      setVisibleUploadNfe(false);
    } else {
      notification.warning({
        message: `O XML não pertence a ${store?.value} de cnpj ${store?.company?.cnpj}`,
        description: `Por favor insira um XML pertencente a loja selecionada.`,
        duration: 3,
      });
      resetFields();
    }
    setTimeout(() => {
      setLoading(false);
    }, 100);
  };

  const resetFormErrors = (value) => {
    form.setFields([
      {
        name: value,
        errors: [],
      },
    ]);
  };

  return (
    <Container
      centered
      closable={true}
      visible={visible}
      title={"Devolução"}
      footer={false}
      width={1200}
      onCancel={() => setVisible(false)}
    >
      <ContentModal>
        <Form
          layout="vertical"
          form={form}
          validateTrigger="onSubmit"
          onFinish={onFinish}
        >
          <Row className="header" gutter={[4, 4]}>
            <Col sm={12}>
              <h2>Nota Fiscal</h2>
            </Col>
            <Col sm={8}>
              <Row gutter={[4, 4]} className="rowEnd">
                <Col sm={10} xs={24}>
                  <ButtonCancel
                    onClick={() => {
                      setVisible(false);
                    }}
                  >
                    Cancelar
                  </ButtonCancel>
                </Col>
                <Col sm={10} xs={24}>
                  <Button type="submit">Salvar</Button>
                </Col>
              </Row>
            </Col>
          </Row>
          {loading ? (
            <Centralizer>
              <Spin />
            </Centralizer>
          ) : (
            <>
              <Row gutter={[4, 4]}>
                <Col sm={8} xs={24}>
                  <Form.Item
                    label="Natureza de operação"
                    name="natureza_operacao"
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <Input
                      name="natureza_operacao"
                      placeholder="Natureza de operação"
                      value={"Devolução de mercadoria"}
                      onChange={(value) => naturezaItemUpdate(value)}
                      onClick={() => resetFormErrors("natureza_operacao")}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col sm={4} xs={24}>
                  <Form.Item
                    label="Data de emissão"
                    name="data_emissao"
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <DatePicker
                      size="small"
                      format="DD/MM/YYYY"
                      locale={locale}
                      placeholder="Selecione a data"
                      onClick={() => resetFormErrors("data_emissao")}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col sm={4} xs={24}>
                  <Form.Item label="Hora emissão" name="hora_emissao">
                    <TimePicker disabled />
                  </Form.Item>
                </Col>
                <Col sm={4} xs={24}>
                  <Form.Item label="Data de saída" name="data_entrada_saida">
                    <DatePicker
                      size="small"
                      format="DD/MM/YYYY"
                      locale={locale}
                      placeholder="Selecione a data"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col sm={4} xs={24}>
                  <Form.Item label="Hora de saída" name="hora_entrada_saida">
                    <TimePicker disabled />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={[4, 4]}>
                <Col sm={8} xs={24}>
                  <Form.Item
                    label="Código do regime tributário"
                    name="regime_tributario_destinatario"
                  >
                    <Select
                      placeholder="Selecione Aqui"
                      defaultValue={
                        selectsXml?.emitente_regime_tributario || "1"
                      }
                      onChange={(value) =>
                        setSelectsXml((oldValues) => ({
                          ...oldValues,
                          emitente_regime_tributario: value.toString(),
                        }))
                      }
                      disabled
                    >
                      {taxRegime.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={8} xs={24}>
                  <Form.Item
                    label={
                      <>
                        Finalidade{" "}
                        <span style={{ color: "var(--orange-600)" }}>*</span>
                      </>
                    }
                    name="finalidade_emissao"
                    rules={[
                      {
                        validator: () => {
                          return selectsXml?.finalidade_emissao
                            ? Promise.resolve()
                            : Promise.reject("Campo obrigatório");
                        },
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecione Aqui"
                      defaultValue={
                        selectsXml?.finalidade_emissao?.toString() || "1"
                      }
                      onChange={(value) =>
                        setSelectsXml((oldValues) => ({
                          ...oldValues,
                          finalidade_emissao: value.toString(),
                        }))
                      }
                      onClick={() => resetFormErrors("finalidade_emissao")}
                      disabled
                    >
                      {goal.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={8} xs={24}>
                  <Form.Item
                    label={
                      <>
                        Indicador de presença{" "}
                        <span style={{ color: "var(--orange-600)" }}>*</span>
                      </>
                    }
                    name="presenca_comprador"
                    rules={[
                      {
                        validator: () => {
                          return selectsXml?.presenca_comprador
                            ? Promise.resolve()
                            : Promise.reject("Campo obrigatório");
                        },
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecione Aqui"
                      defaultValue={selectsXml?.presenca_comprador || "9"}
                      onChange={(value) =>
                        setSelectsXml((oldValues) => ({
                          ...oldValues,
                          presenca_comprador: value.toString(),
                        }))
                      }
                      onClick={() => resetFormErrors("presenca_comprador")}
                    >
                      {presenceIndicator.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col sm={8} xs={24}>
                  <Form.Item
                    label={
                      <>
                        Local de destino{" "}
                        <span style={{ color: "var(--orange-600)" }}>*</span>
                      </>
                    }
                    name="local_destino"
                    rules={[
                      {
                        validator: () => {
                          return selectsXml?.local_destino
                            ? Promise.resolve()
                            : Promise.reject("Campo obrigatório");
                        },
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecione Aqui"
                      defaultValue={selectsXml?.local_destino || "1"}
                      onChange={(value) =>
                        setSelectsXml((oldValues) => ({
                          ...oldValues,
                          local_destino: value.toString(),
                        }))
                      }
                      onClick={() => resetFormErrors("local_destino")}
                    >
                      <Select.Option key={1} value={"1"}>
                        1: Operação interna
                      </Select.Option>
                      <Select.Option key={2} value={"2"}>
                        2: Operação interestadual
                      </Select.Option>
                      <Select.Option key={3} value={"3"}>
                        3: Operação com exterior
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {selectsXml?.finalidade_emissao !== "1" && (
                <>
                  <Row>
                    <Divider>
                      <h3>Documento referenciado</h3>
                    </Divider>
                  </Row>
                  <Row gutter={[4, 4]}>
                    <Col sm={8} xs={24}>
                      <Form.Item label="Tipo" name="modelo">
                        <Input
                          defaultValue={"NF-e ou NFC-e (modelos 55 e 65)"}
                          disabled
                        />
                      </Form.Item>
                    </Col>
                    {selectsXml?.finalidade_emissao === "4" && (
                      <Col sm={8} xs={24}>
                        <Form.Item
                          label="Chave de acesso da nota fiscal"
                          name="chave_nfe"
                          rules={[
                            {
                              required:
                                selectsXml?.finalidade_emissao === "4"
                                  ? true
                                  : false,
                              message: "Campo obrigatório",
                            },
                          ]}
                        >
                          <Input
                            name="chave_nfe"
                            placeholder="Chave de acesso"
                            onClick={() => resetFormErrors("chave_nfe")}
                          />
                        </Form.Item>
                      </Col>
                    )}
                  </Row>
                </>
              )}
              <Row>
                <Divider>
                  <h3>Emitente</h3>
                </Divider>
              </Row>
              <Row gutter={4}>
                <Col sm={4} xs={24}>
                  <Form.Item label="Razao Social Emitente">
                    <Input
                      placeholder="Razao Social"
                      value={store?.company?.emitente_razao_social}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col sm={4} xs={24}>
                  <Form.Item label="Nome Fantasia Emitente">
                    <Input
                      placeholder="Nome Fantasia"
                      value={store?.company?.emitente_nome_fantasia}
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col sm={4} xs={24}>
                  <Form.Item label="CNPJ do Emitente" name="cnpj_emitente">
                    <InputMask
                      placeholder="CNPJ"
                      mask="99.999.999/9999-99"
                      name="cnpj_emitente"
                      disabled
                    />
                  </Form.Item>
                </Col>
                <Col sm={8} xs={24}>
                  <Form.Item
                    label="Inscrição Estadual do Emitente"
                    name="inscricao_estadual_emitente"
                  >
                    <Input
                      name="inscricao_estadual_emitente"
                      placeholder="Inscrição Estadual do Emitente"
                      disabled
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Divider>
                  <h3>Destinatário</h3>
                </Divider>
              </Row>
              <Row gutter={4}>
                <Col
                  sm={4}
                  xs={24}
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Form.Item
                    label="Nome do contato"
                    name="nome_destinatario"
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    {!loadingCnpj && (
                      <Input
                        name="nome_destinatario"
                        placeholder="Nome do contato"
                        onClick={() => resetFormErrors("nome_destinatario")}
                      />
                    )}
                  </Form.Item>
                  <Form.Item>
                    <Checkbox
                      checked={selectsXml?.consumidor_final}
                      onChange={() =>
                        setSelectsXml((oldValues) => ({
                          ...oldValues,
                          consumidor_final: !selectsXml?.consumidor_final,
                        }))
                      }
                    >
                      Consumidor Final
                    </Checkbox>
                  </Form.Item>
                </Col>
                <Col sm={4} xs={24}>
                  <Form.Item label="Tipo de pessoa">
                    <Select
                      placeholder="Selecione Aqui"
                      onChange={(value) => setTypePersonSelect(+value)}
                      defaultValue={typePersonSelect}
                      value={typePersonSelect}
                    >
                      <Select.Option key={1} value={1}>
                        Pessoa Física
                      </Select.Option>
                      <Select.Option key={2} value={2}>
                        Pessoa Jurídica
                      </Select.Option>
                    </Select>
                    <p className="space" />
                  </Form.Item>
                </Col>

                <Col
                  sm={typePersonSelect === 1 ? 4 : 0}
                  xs={typePersonSelect === 1 ? 24 : 0}
                >
                  <Form.Item
                    label="CPF"
                    name="cpf_destinatario"
                    rules={[
                      {
                        required: typePersonSelect === 1 ? true : false,
                        message: "Campo obrigatório",
                      },
                    ]}
                  >
                    <InputMask
                      placeholder="CPF"
                      mask="999.999.999-99"
                      name="cpf_destinatario"
                      value={cpf}
                      onChange={(event) => {
                        handleState(event.target.value, cpf);
                        resetFormErrors("cpf_destinatario");
                      }}
                    />
                    <p className="space" />
                  </Form.Item>
                </Col>

                <Col
                  sm={typePersonSelect === 2 ? 4 : 0}
                  xs={typePersonSelect === 2 ? 24 : 0}
                >
                  <Form.Item
                    label={
                      <>
                        CNPJ{" "}
                        <span style={{ color: "var(--orange-600)" }}>*</span>
                      </>
                    }
                    name="cnpj_destinatario"
                    rules={[
                      {
                        validator: () => {
                          return cnpj
                            ? Promise.resolve()
                            : Promise.reject("Campo Obrigatório");
                        },
                      },
                      {
                        validator: () => {
                          return cnpj?.replace(/[^0-9]+/g, "")?.length > 13
                            ? Promise.resolve()
                            : Promise.reject("CNPJ inválido");
                        },
                      },
                    ]}
                  >
                    <InputMask
                      placeholder="CNPJ"
                      mask="99.999.999/9999-99"
                      name="cnpj_destinatario"
                      value={cnpj}
                      onChange={(event) => {
                        resetFormErrors("cnpj_destinatario");
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col sm={5} xs={24}>
                  <Form.Item
                    label={
                      <>
                        Indicador Inscrição estadual{" "}
                        <span style={{ color: "var(--orange-600)" }}>*</span>
                      </>
                    }
                    name="indicador_inscricao_estadual_destinatario"
                  >
                    <Select
                      placeholder="Selecione Aqui"
                      defaultValue={
                        selectsXml?.indicador_inscricao_estadual_destinatario ||
                        "1"
                      }
                      onChange={(value) =>
                        setSelectsXml((oldValues) => ({
                          ...oldValues,
                          indicador_inscricao_estadual_destinatario:
                            value.toString(),
                        }))
                      }
                      onClick={() =>
                        resetFormErrors(
                          "indicador_inscricao_estadual_destinatario"
                        )
                      }
                    >
                      <Select.Option key={1} value={"1"}>
                        1: Contribuinte ICMS (informar a IE do destinatário)
                      </Select.Option>
                      <Select.Option key={1} value={"2"}>
                        2: Contribuinte isento de Inscrição no cadastro de
                        Contribuintes do ICMS
                      </Select.Option>
                      <Select.Option key={1} value={"9"}>
                        9: Não Contribuinte, que pode ou não possuir Inscrição
                        Estadual no Cadastro de Contribuintes do ICMS
                      </Select.Option>
                    </Select>
                  </Form.Item>
                  <p className="space" />
                </Col>
                {selectsXml?.indicador_inscricao_estadual_destinatario !==
                  "2" && (
                  <Col sm={6} xs={24}>
                    <Form.Item
                      label="Inscrição estadual"
                      name="inscricao_estadual_destinatario"
                      rules={[
                        {
                          required:
                            selectsXml?.indicador_inscricao_estadual_destinatario ===
                            "1"
                              ? true
                              : false,
                          message: "Campo obrigatório",
                        },
                      ]}
                    >
                      <Input
                        name="inscricao_estadual_destinatario"
                        placeholder="Inscrição estadual"
                        onClick={() =>
                          resetFormErrors("inscricao_estadual_destinatario")
                        }
                      />
                    </Form.Item>
                    <p className="space" />
                  </Col>
                )}
              </Row>
              {loadingCnpj ? (
                <Centralizer>
                  <Spin />
                </Centralizer>
              ) : (
                <>
                  <Row gutter={[4, 4]}>
                    <Col sm={4} xs={24}>
                      <Form.Item
                        label="CEP"
                        name="cep_destinatario"
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Input
                          name="cep_destinatario"
                          placeholder="CEP"
                          onClick={() => resetFormErrors("cep_destinatario")}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item
                        label="UF"
                        name="uf_destinatario"
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Select
                          placeholder="Selecione Aqui"
                          onClick={() => resetFormErrors("uf_destinatario")}
                        >
                          {uf.map((option) => (
                            <Select.Option key={option.id} value={option.value}>
                              {option.value}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col sm={6} xs={24}>
                      <Form.Item
                        label="Municipio"
                        name="municipio_destinatario"
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Input
                          name="municipio_destinatario"
                          placeholder="Municipio"
                          onClick={() =>
                            resetFormErrors("municipio_destinatario")
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={10} xs={24}>
                      <Form.Item
                        label="Bairro"
                        name="bairro_destinatario"
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Input
                          name="bairro_destinatario"
                          placeholder="Bairro"
                          onClick={() => resetFormErrors("bairro_destinatario")}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[4, 4]}>
                    <Col sm={8} xs={24}>
                      <Form.Item
                        label="Endereço"
                        name="logradouro_destinatario"
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Input
                          name="logradouro_destinatario"
                          placeholder="Endereço"
                          onClick={() =>
                            resetFormErrors("logradouro_destinatario")
                          }
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item
                        label="Número"
                        name="numero_destinatario"
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <Input
                          name="numero_destinatario"
                          placeholder="Número"
                          onClick={() => resetFormErrors("numero_destinatario")}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={12} xs={24}>
                      <Form.Item
                        label="Complemento"
                        name="complemento_destinatario"
                      >
                        <Input
                          name="complemento_destinatario"
                          placeholder="Complemento"
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row gutter={[4, 4]}>
                    <Col sm={4} xs={24}>
                      <Form.Item label="Fone/fax" name="telefone_destinatario">
                        <Input
                          name="telefone_destinatario"
                          placeholder="Fone/fax"
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={10} xs={24}>
                      <Form.Item label="Email" name="email_destinatario">
                        <Input name="email_destinatario" placeholder="Email" />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Divider>
                  <h3>Items da nota fiscal</h3>
                </Divider>
              </Row>
              {loadingItems ? (
                <Centralizer>
                  <Spin />
                </Centralizer>
              ) : (
                <ContainerItems>
                  {items?.map((item, index) => (
                    <Row key={index}>
                      <Col sm={1} xs={24} style={{ marginRight: "0.4rem" }}>
                        <Form.Item label=" ">
                          <Input disabled={true} value={index + 1} />
                        </Form.Item>
                      </Col>
                      <Col sm={6} xs={24}>
                        <Form.Item
                          label="Produto ou serviço"
                          rules={[
                            { required: true, message: "Campo obrigatório" },
                          ]}
                        >
                          <Select
                            placeholder="Selecione Aqui"
                            defaultValue={item.descricao}
                            onChange={(value) => productSelect(value, index)}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option?.children
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            disabled
                          >
                            {productsStore.map((option) => (
                              <Select.Option
                                key={option.product_id}
                                value={option.product_id}
                              >
                                {option.product.name}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col sm={3} xs={24}>
                        <Form.Item label="Código">
                          <Input
                            defaultValue={item.codigo_produto}
                            onChange={(value) =>
                              changeItem(
                                value,
                                item.descricao,
                                "codigo_produto"
                              )
                            }
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={1} xs={24}>
                        <Form.Item label="UN">
                          <Input
                            defaultValue={item.unidade_comercial}
                            onChange={(value) =>
                              changeItem(
                                value,
                                item.descricao,
                                "unidade_comercial"
                              )
                            }
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={2} xs={24}>
                        <Form.Item
                          label="Qtde"
                          name={`qtd${item?.codigo_produto + index.toString()}`}
                        >
                          <Input
                            defaultValue={
                              loadingTotal
                                ? item.quantidade_comercial
                                : item.quantidade_comercial
                            }
                            onChange={(value) =>
                              changeQuantity(value, item.codigo_produto)
                            }
                            type="number"
                            min={1}
                            step={
                              item?.descricao
                                ?.toLowerCase()
                                ?.includes("service")
                                ? 0.001
                                : 1
                            }
                            disabled={!item.descricao ? true : false}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={2} xs={24}>
                        <Form.Item
                          label="Preço un"
                          name={`priceUn${index}`}
                          rules={[
                            {
                              pattern: /^[1-9]\d*(\.\d+)?$/,
                              message: "Nº positivo",
                            },
                          ]}
                        >
                          <MonetaryInput
                            defaultValue={item.valor_unitario_comercial}
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={2} xs={24}>
                        <Form.Item label="Preço total">
                          <MonetaryInput
                            defaultValue={
                              loadingTotal
                                ? +(item?.valor_bruto || 0)
                                : +(item?.valor_bruto || 0)
                            }
                            readOnly
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Código NCM"
                          rules={[
                            { required: true, message: "Campo obrigatório" },
                            { min: 8, message: "Minimo de 8 caracteres" },
                            { max: 8, message: "Máximo de 8 caracteres" },
                          ]}
                        >
                          <InputMask
                            name={"codigo_ncm"}
                            mask={"99999999"}
                            placeholder="99999999"
                            value={item.codigo_ncm}
                            onChange={(value) =>
                              changeItem(value, item.descricao, "codigo_ncm")
                            }
                            disabled={!item.descricao ? true : false}
                          />
                        </Form.Item>
                      </Col>
                      <Col sm={1} xs={24}>
                        <Form.Item label=" ">
                          <IconContainer>
                            <IconEdit
                              onClick={() => {
                                item.descricao && setSelectItem(item);
                                item.descricao && setVisibleDrawerItem(true);
                              }}
                            />
                          </IconContainer>
                        </Form.Item>
                      </Col>
                      <Col sm={1} xs={24}>
                        <Form.Item label=" ">
                          <IconContainer>
                            <IconTrash
                              onClick={() => removeItem(item.codigo_produto)}
                            />
                          </IconContainer>
                        </Form.Item>
                      </Col>
                    </Row>
                  ))}
                </ContainerItems>
              )}
              <Row className="rowEnd">
                {/* <a className="addButton" onClick={() => addItem()}>
                  + Adicionar outro item
                </a> */}
              </Row>
              <Row>
                <Divider className="header">
                  <h3>Cálculo de imposto</h3>
                </Divider>
              </Row>
              {loadingItems ? (
                <Centralizer>
                  <Spin />
                </Centralizer>
              ) : (
                <>
                  <Row gutter={[4, 4]}>
                    <Col sm={4} xs={24}>
                      <Form.Item
                        label="Total dos Produtos (R$)"
                        name="valor_produtos"
                      >
                        <InputCurrency
                          type="currency"
                          name="valor_produtos"
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item label="Valor de frete (R$)" name="valor_frete">
                        <InputCurrency
                          type="currency"
                          name="valor_frete"
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item
                        label="Valor do seguro (R$)"
                        name="valor_seguro"
                      >
                        <InputCurrency
                          type="currency"
                          name="valor_seguro"
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item
                        label="Outras despesas (R$)"
                        name="valor_outras_despesas"
                      >
                        <InputCurrency
                          type="currency"
                          name="valor_outras_despesas"
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item label="Desconto (R$)" name="valor_desconto">
                        <InputCurrency
                          type="currency"
                          name="valor_desconto"
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item label="Total da nota (R$)" name="valor_total">
                        <InputCurrency
                          type="currency"
                          name="valor_total"
                          readOnly
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={6} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            {" "}
                            Base ICMS{" "}
                            <Tooltip
                              title={
                                "Base ICMS = (Valor produto + Frete + Seguro + Outras Despesas Acessórias - Descontos)"
                              }
                            >
                              <IconInfo />
                            </Tooltip>
                          </span>
                        }
                        name="icms_base_calculo"
                      >
                        <InputCurrency
                          type="currency"
                          name="icms_base_calculo"
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={6} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            {" "}
                            Valor ICMS{" "}
                            <Tooltip
                              title={
                                "Valor ICMS = (Base ICMS * (% Alíquota do ICMS))"
                              }
                            >
                              <IconInfo />
                            </Tooltip>
                          </span>
                        }
                        name="icms_valor"
                      >
                        <InputCurrency
                          type="currency"
                          name="icms_valor"
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={6} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            {" "}
                            Base ICMS ST{" "}
                            <Tooltip
                              title={
                                "Base ICMS ST = (Valor produto + Valor IPI + Frete + Seguro + Outras Despesas Acessórias - Descontos) * (1+(% MVA))"
                              }
                            >
                              <IconInfo />
                            </Tooltip>
                          </span>
                        }
                        name="icms_base_calculo_st"
                      >
                        <InputCurrency
                          type="currency"
                          name="icms_base_calculo_st"
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={6} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            {" "}
                            Valor ICMS ST{" "}
                            <Tooltip
                              title={
                                "Valor ICMS ST = (Base ICMS ST * (% Alíquota do ICMS ST)) - Valor ICMS"
                              }
                            >
                              <IconInfo />
                            </Tooltip>
                          </span>
                        }
                        name="icms_valor_total_st"
                      >
                        <InputCurrency
                          type="currency"
                          name="icms_valor_total_st"
                          readOnly
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={6} xs={24}>
                      <Form.Item label="Total Faturado (R$)" name="valor_total">
                        <InputCurrency
                          type="currency"
                          name="valor_total"
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={6} xs={24}>
                      <Form.Item
                        label={
                          <span>
                            {" "}
                            Total A. tributos (R$){" "}
                            <Tooltip
                              title={"Soma total dos tributos dos itens"}
                            >
                              <IconInfo />
                            </Tooltip>
                          </span>
                        }
                        name="valor_total_tributos"
                      >
                        <InputCurrency
                          type="currency"
                          name="valor_total_tributos"
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={6} xs={24}>
                      <Form.Item label="Valor Total IPI" name="valor_total_ipi">
                        <InputCurrency
                          type="currency"
                          name="valor_total_ipi"
                          readOnly
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Divider>
                  <h3>Transportador/Volumes</h3>
                </Divider>
              </Row>
              <Row gutter={[4, 4]}>
                <Col sm={6} xs={24}>
                  <Form.Item
                    label={
                      <>
                        Transporte{" "}
                        <span style={{ color: "var(--orange-600)" }}>*</span>
                      </>
                    }
                    name="transporte"
                    rules={[
                      {
                        validator: () => {
                          return selectsXml?.transporte
                            ? Promise.resolve()
                            : Promise.reject("Campo obrigatório");
                        },
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecione Aqui"
                      defaultValue={selectsXml?.transporte || "0"}
                      onChange={(value) =>
                        setSelectsXml((oldValues) => ({
                          ...oldValues,
                          transporte: value.toString(),
                        }))
                      }
                      onClick={() => resetFormErrors("transporte")}
                      disabled
                    >
                      <Select.Option key={0} value={"0"}>
                        Não haverá transporte
                      </Select.Option>
                      <Select.Option key={1} value={"1"}>
                        Inserir transportadora manualmente
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col sm={6} xs={24}>
                  <Form.Item
                    label={
                      <>
                        Frete por conta{" "}
                        <span style={{ color: "var(--orange-600)" }}>*</span>
                      </>
                    }
                    name="modalidade_frete"
                    rules={[
                      {
                        validator: () => {
                          return selectsXml?.modalidade_frete
                            ? Promise.resolve()
                            : Promise.reject("Campo obrigatório");
                        },
                      },
                    ]}
                  >
                    <Select
                      placeholder="Selecione Aqui"
                      defaultValue={selectsXml?.modalidade_frete || "9"}
                      onChange={(value) =>
                        setSelectsXml((oldValues) => ({
                          ...oldValues,
                          modalidade_frete: value.toString(),
                        }))
                      }
                      onClick={() => resetFormErrors("modalidade_frete")}
                      disabled
                    >
                      {freight.map((option) => (
                        <Select.Option key={option.value} value={option.value}>
                          {option.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              {selectsXml?.transporte === "1" && (
                <>
                  <Row className="header">
                    <Divider>
                      <h3>Informações Adicionais</h3>
                    </Divider>
                  </Row>
                  <Row gutter={[4, 4]}>
                    <Col sm={8} xs={24}>
                      <Form.Item label="Nome" name="nome_transportador">
                        <Input name="nome_transportador" placeholder="Nome" />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item label="Placa do veículo" name="veiculo_placa">
                        <Input
                          name="veiculo_placa"
                          placeholder="Placa do veículo"
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item label="UF" name="uf_veiculo">
                        <Select placeholder="Selecione Aqui">
                          {uf.map((option) => (
                            <Select.Option key={option.id} value={option.value}>
                              {option.value}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item label="RNTC" name="veiculo_rntc">
                        <Input name="veiculo_rntc" placeholder="RNTC" />
                      </Form.Item>
                    </Col>

                    <Col sm={8} xs={24}>
                      <Form.Item
                        label="CNPJ"
                        name="cnpj_transportador"
                        rules={[
                          {
                            validator: (_, value) => {
                              const cleanedValue = value.replace(
                                /[^0-9]+/g,
                                ""
                              );
                              return cleanedValue?.length === 14
                                ? Promise.resolve()
                                : Promise.reject("CNPJ inválido");
                            },
                          },
                        ]}
                      >
                        <InputMask
                          placeholder="CNPJ"
                          mask="99.999.999/9999-99"
                          name="cnpj_transportador"
                          onChange={() => resetFormErrors("cnpj_transportador")}
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item
                        label="Inscrição Estadual"
                        name="inscricao_estadual_transportador"
                      >
                        <Input
                          name="inscricao_estadual_transportador"
                          placeholder="Inscrição Estadual"
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item label="UF" name="uf_transportador">
                        <Select placeholder="Selecione Aqui">
                          {uf.map((option) => (
                            <Select.Option key={option.id} value={option.value}>
                              {option.value}
                            </Select.Option>
                          ))}
                        </Select>
                      </Form.Item>
                    </Col>
                    <Col sm={8} xs={24}>
                      <Form.Item
                        label="Municipio"
                        name="municipio_transportador"
                      >
                        <Input
                          name="municipio_transportador"
                          placeholder="Municipio"
                        />
                      </Form.Item>
                    </Col>

                    <Col sm={8} xs={24}>
                      <Form.Item
                        label="Endereço da transportadora"
                        name="endereco_transportador"
                      >
                        <Input
                          name="endereco_transportador"
                          placeholder="Endereço da transportadora"
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row className="header">
                    <Divider>
                      <h4>Dados do volume</h4>
                    </Divider>
                  </Row>
                  <Row gutter={[4, 4]}>
                    <Col sm={4} xs={24}>
                      <Form.Item label="Quantidade" name="quantidade">
                        <Input name="quantidade" placeholder="Quantidade" />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item label="Peso bruto" name="peso_bruto">
                        <Input name="peso_bruto" placeholder="Peso bruto" />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item label="Peso liquido" name="peso_liquido">
                        <Input name="peso_liquido" placeholder="Peso liquido" />
                      </Form.Item>
                    </Col>
                    <Col sm={8} xs={24}>
                      <Form.Item label="Numeração do volume" name="numero">
                        <Input
                          name="numero"
                          placeholder="Numeração do volume"
                        />
                      </Form.Item>
                    </Col>
                    <Col sm={4} xs={24}>
                      <Form.Item label="Série">
                        <Input name="serie" placeholder="Série" />
                      </Form.Item>
                    </Col>

                    <Col sm={4} xs={24}>
                      <Form.Item label="Marca" name="marca">
                        <Input name="marca" placeholder="Marca" />
                      </Form.Item>
                    </Col>
                  </Row>
                </>
              )}
              {selectsXml?.finalidade_emissao !== "3" &&
                selectsXml?.finalidade_emissao !== "4" && (
                  <>
                    <Row className="header">
                      <Divider>
                        <h3>Pagamentos</h3>
                      </Divider>
                    </Row>
                    <Row gutter={[4, 4]}>
                      <Col sm={8} xs={24}>
                        <Form.Item
                          label={
                            <>
                              Pagamento{" "}
                              <span style={{ color: "var(--orange-600)" }}>
                                *
                              </span>
                            </>
                          }
                          name="indicador_pagamento"
                          rules={[
                            {
                              validator: () => {
                                return selectsXml?.indicador_pagamento
                                  ? Promise.resolve()
                                  : Promise.reject("Campo obrigatório");
                              },
                            },
                          ]}
                        >
                          <Select
                            placeholder="Selecione Aqui"
                            defaultValue={
                              selectsXml?.indicador_pagamento || "0"
                            }
                            onChange={(value) =>
                              setSelectsXml((oldValues) => ({
                                ...oldValues,
                                indicador_pagamento: value.toString(),
                              }))
                            }
                            onClick={() =>
                              resetFormErrors("indicador_pagamento")
                            }
                          >
                            <Select.Option key={1} value={"0"}>
                              0: Pagamento à Vista
                            </Select.Option>
                            <Select.Option key={2} value={"1"}>
                              1: Pagamento a Prazo
                            </Select.Option>
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col sm={10} xs={24}>
                        <Form.Item
                          label={
                            <>
                              Forma de pagamento{" "}
                              <span style={{ color: "var(--orange-600)" }}>
                                *
                              </span>
                            </>
                          }
                          name="forma_pagamento"
                          rules={[
                            {
                              validator: () => {
                                return selectsXml?.forma_pagamento
                                  ? Promise.resolve()
                                  : Promise.reject("Campo obrigatório");
                              },
                            },
                          ]}
                        >
                          <Select
                            placeholder="Selecione Aqui"
                            defaultValue={selectsXml?.forma_pagamento || "01"}
                            onChange={(value) =>
                              setSelectsXml((oldValues) => ({
                                ...oldValues,
                                forma_pagamento: value.toString(),
                              }))
                            }
                            onClick={() => resetFormErrors("forma_pagamento")}
                          >
                            {paymentForm.map((option) => (
                              <Select.Option
                                key={option.value}
                                value={option.value}
                              >
                                {option.label}
                              </Select.Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col sm={4} xs={24}>
                        <Form.Item
                          label="Valor do pagamento"
                          name="valor_pagamento"
                          rules={[
                            { required: true, message: "Campo obrigatório" },
                          ]}
                        >
                          <InputCurrency
                            type="currency"
                            name="valor_pagamento"
                            onClick={() => resetFormErrors("valor_pagamento")}
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </>
                )}
              <Row className="header">
                <Divider>
                  <h3>Informações Adicionais</h3>
                </Divider>
              </Row>
              <div className="textAreaDiv">
                <Row>
                  <Col sm={24} xs={24}>
                    <Form.Item
                      label="Informações complementares"
                      name="informacoes_adicionais_contribuinte"
                      rules={[
                        {
                          required:
                            selectsXml.finalidade_emissao === "4"
                              ? false
                              : true,
                          message: "Campo Obrigatório",
                        },
                      ]}
                    >
                      <TextAreaInput
                        rows={4}
                        maxLength={500}
                        name="informacoes_adicionais_contribuinte"
                        onClick={() =>
                          resetFormErrors("informacoes_adicionais_contribuinte")
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col sm={24} xs={24}>
                    <Form.Item
                      label={
                        <span>
                          {" "}
                          Informações adicionais de interesse do fisco
                          (natureza) <IconInfo />
                        </span>
                      }
                      name="informacoes_adicionais_fisco"
                    >
                      <TextAreaInput
                        rows={4}
                        maxLength={100}
                        name="informacoes_adicionais_fisco"
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </div>
            </>
          )}
        </Form>
      </ContentModal>

      <ModalUploadNfe
        title="Nova nota fiscal"
        centered
        onCancel={() => {
          setVisibleUploadNfe(false);
          setVisible(false);
        }}
        visible={visibleUploadNfe}
        width={450}
        footer={false}
        maskClosable={false}
        closable={true}
        destroyOnClose={true}
      >
        <ContainerUploadXML activedXML={file ? true : false}>
          <UploadXMLIcon activedXML={file ? true : false} />
          <Input
            type="file"
            onChange={(value) => {
              handleFileChangeXml(value);
            }}
            accept=".xml"
            disabled={file ? true : false}
          />
          <span>
            {file
              ? "XML carregado com sucesso"
              : "Clique ou arraste o arquivo XML nesta área para carregar"}
          </span>
        </ContainerUploadXML>

        <ButtonModalUpload
          onClick={() => {
            updateFile();
          }}
          disabled={file ? false : true}
        >
          Importar XML
        </ButtonModalUpload>
      </ModalUploadNfe>

      <NFEDrawerItem
        visible={visibleDrawerItem}
        setVisible={setVisibleDrawerItem}
        selectItem={selectItem}
        setSelectItem={setSelectItem}
        items={items}
        setItems={setItems}
        setLoadingItems={setLoadingItems}
      />
    </Container>
  );
};

export default NFEModalDevolution;
