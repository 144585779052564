import React, { useState, useEffect } from "react";

import apiMercury from "../../services/apiMercury";

import { Provider as ProviderModel } from "../../models/Provider";

import ModalProvider from "../ModalProvider";

import {
  Select,
  Option,
  PencilIcon,
  OptionInfoContainer,
  OptionLabel,
} from "./styles";

interface IProps {
  handleChange: (provider: number) => void;
  defaultValue?: string;
}

const SearchProvider: React.FC<IProps> = ({ handleChange, defaultValue }) => {
  const [loading, setLoading] = useState(false);
  const [shoulOpen, setShoulOpen] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState<number | undefined>(
    undefined
  );
  const [providers, setProviders] = useState<ProviderModel[]>([]);

  const [visible, setVisible] = useState(false);

  useEffect(() => {
    const fetchProviders = async () => {
      setShoulOpen(false);
      setLoading(true);
      const {
        data: { content },
      } = await apiMercury.get(`/providers`);
      setProviders(content.sort((a, b) => a.fantasy_name.localeCompare(b.fantasy_name))); 
      setLoading(false);
    };

    fetchProviders();
  }, []);

  useEffect(() => {
    if (visible) {
      setShoulOpen(false);
    }
  }, [visible, shoulOpen]);

  const onChange = (id: number) => {
    handleChange(id);
    setSelectedProvider(id);
  };

  const handleEdit = (id: number) => {
    setSelectedProvider(id);
    setVisible(true);
    setShoulOpen(false);
  };

  return (
    <>
      <Select
        showSearch
        optionFilterProp="children"
        filterOption={(input, option) =>
          option?.children.props.children[0].props.children
            .toLowerCase()
            .indexOf(input.toLowerCase()) >= 0
        }
        placeholder="Escolha a opção"
        loading={loading}
        defaultValue={defaultValue}
        onClick={() => setShoulOpen(!shoulOpen)}
        onChange={(id) => onChange(+id)}
        onBlur={() => setShoulOpen(false)}
        open={shoulOpen}
        allowClear
      >
        {providers.map((provider) => (
          <Option
            key={provider.id}
            value={provider.id}
            style={{ textTransform: "uppercase" }}
          >
            <OptionInfoContainer>
              <OptionLabel>{provider.fantasy_name}</OptionLabel>
              <PencilIcon
                className="pencilIcon"
                onClick={() => handleEdit(provider.id)}
              />
            </OptionInfoContainer>
          </Option>
        ))}
      </Select>
      <ModalProvider
        visible={visible}
        setVisible={setVisible}
        providerId={selectedProvider}
      />
    </>
  );
};

export default SearchProvider;
