import styled, { css } from "styled-components";
import { Bell } from "../../styles/Icons";

interface IBellIcon {
  actived?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  @media only screen and (max-width: 700px) {
    padding: 3%;
  }
  @media only screen and (max-width: 500px) {
    overflow: scroll;
    height: 100%;
  }
`;

export const HomologContent = styled.div`
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 0, 0, 0.5);
  padding: 10px;
  color: white;
  z-index: 1;
  pointer-events: none;

  @media screen and (max-width: 768px){
    top: 25%;
    height: 2rem;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Header = styled.header`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 5%;
  width: 100%;
  padding: 1% 1.5% 0;

  h3 {
    margin-bottom: 0;
    font-size: 2.2rem;
  }

  h3 {
    color: var(--gray-700);
  }

  @media only screen and (max-width: 1250px) {
    h3 {
      font-size: 1.5rem;
    }
  }

  @media only screen and (max-width: 700px) {
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: column-reverse;
    height: 20%;
    margin-bottom: 1%;
    padding: 0;

    h3 {
      font-size: 1.2rem;
      font-weight: 500;
    }

    .generalContent {
      display: flex;
      align-items: center;
      width: 100%;
      height: 100%;
    }

    .content {
      width: 58%;
    }
  }

  @media only screen and (max-width: 600px) {
    height: 10%;
    .content {
      width: 80%;
    }
  }
  @media only screen and (max-width: 500px) {

    .content {
      width: 100%;
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 5%;
  height: 100%;

  img {
    display: none;
  }

  .actions-icons {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }

  @media only screen and (max-width: 700px) {
    width: 60%;
    height: 40%;
    justify-content: flex-end;

    img {
      display: none;
    }

    .actions-icons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 35%;
      height: 100%;
    }
  }
`;

export const Body = styled.div`
  display: flex;
  height: 95%;
  padding: 1.5%;

  @media only screen and (max-width: 700px) {
    padding: 0;
    height: 100%;
  }
  
  @media only screen and (max-width: 600px) {
    height: 90%;
  }
`;

export const ContentHeader = styled.div`
  display: flex;

  @media screen and (max-width: 1024px) {
    display: none;
  }
`;

export const PatchNotesIcon = styled(Bell) <IBellIcon>`
  height: 1.5rem;
  margin-right: 2rem;
  cursor: pointer;
  color: var(--black-opaco);
  transition: 200ms ease-in-out;

  :hover {
    color: var(--orange-350);
  }

  ${({ actived }) =>
    actived === true &&
    css`
      color: var(--orange-350);
    `}
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  span {
    color: red;
  }
`;
