import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import api from "../../services/api";
import { exportCSVFile } from "../../services/exportCSVFile";
import { currencyFormater } from "../../services/currencyFormater";

import { CashHandlerTypes } from "../../models/enums/CashHandlerTypes";
import { Handler } from "../../models/Handler";

import { Empty, Spin } from "antd";

import useEscapeKey from "../../utils/useEscapeKey";

import moment from "moment";

import {
  Modal,
  Checkbox,
  Table,
  Button,
  DownloadButton,
  DownloadIcon,
} from "./styles";

const { confirm } = Modal;
interface IProps {
  visibleHandler: boolean;
  setVisibleHandler: Dispatch<SetStateAction<boolean>>;
  selectedCashHistory: number | null;
}

const ModalHandlers: React.FC<IProps> = ({
  visibleHandler,
  setVisibleHandler,

  selectedCashHistory,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [handlers, setHandlers] = useState<Handler[]>();

  const handleModalToggle = () => {
    setVisibleHandler(!visibleHandler);
  };

  useEscapeKey({ setVisible: () => setVisibleHandler(false) });

  const downloadCSV = () => {
    const CSVHeaders = {
      tipo: "Tipo",
      valor: "Valor",
      razao: "Motivo",
      verificada: "Verificada",
      criadoEm: "Data",
      criadoAs: "Hora",
    };
    const getFormatedData = () => moment(new Date()).format("DD-MM-YYYY");

    const getFormatedHandlers = () => {
      const payload = handlers?.map((handler) => {
        const [date, time] = handler.created_at.split(" ");
        return {
          tipo: CashHandlerTypes[handler.type],
          valor: handler.amount,
          razao: handler.reason.replace(/[^A-Za-z0-9]+/g, " "),
          verificada: handler.verified ? "Sim" : "Nao",
          criadoEm: date,
          criadoAs: time,
        };
      });
      return payload;
    };
    exportCSVFile(
      CSVHeaders,
      getFormatedHandlers(),
      `Movimentacoes_${getFormatedData()}`
    );
  };

  const handleCheckBox = async (id: number, value: boolean) => {
    confirm({
      title: "Atualização movimentação",
      content: "Deseja marcar está movimentação como verificada?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      async onOk() {
        setLoading(true);
        await api.put(`/cash_handler/${id}`, { verified: value });
        const {
          data: { data },
        } = await api.get(`/cash_handler?history_id=${selectedCashHistory}`);
        setHandlers(data);
        setLoading(false);
      },
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const {
        data: { data },
      } = await api.get(`/cash_handler?history_id=${selectedCashHistory}`);
      setHandlers(data);
      setLoading(false);
    };
    if (selectedCashHistory && visibleHandler) {
      fetchData();
    }
  }, [selectedCashHistory, visibleHandler]);

  const columns = [
    {
      title: "Horário",
      dataIndex: "created_at",
      responsive: ["xl"] as any,
      key: "created_at",
      render: (text) => (
     
          <span>
            {`${moment(text, "DD-MM-YYYY HH:mm:ss").format("HH:mm:ss")}`}{" "}
          </span>
       
      ),
    },
    {
      title: "Tipo",
      dataIndex: "type",
      key: "type",
      render: (text) => (
       
          <span>{`${text === 1 ? "Saída" : "Entrada"}`} </span>
        
      ),
    },
    {
      title: "Valor",
      dataIndex: "amount",
      key: "amount",
      textWrap: "word-break",
      render: (text) => (
       
          <span>{`R$ ${currencyFormater(+text || 0)}`}</span>
     
      ),
    },
    {
      title: "Verificada",
      dataIndex: "verified",
      key: "verified",
      render: (text, record) => (
        <Checkbox
          checked={text}
          onClick={() => handleCheckBox(+record?.id, !text)}
        />
      ),
    },
    {
      title: "Razão",
      dataIndex: "reason",
      key: "reason",
      responsive: ["xl"] as any,
      textWrap: "word-break",
      render: (text) => (
       
          <span>{`${text}`}</span>
      
      ),
    },
  ];

  return (
    <Modal
      title="Movimentações"
      centered
      visible={visibleHandler}
      onCancel={handleModalToggle}
      cancelButtonProps={{ hidden: true }}
      closable={false}
      width={"80%"}
      footer={[
        <>
          <Button onClick={() => setVisibleHandler(false)}>
            <span style={{ color: "var(--blue-900)" }}>Fechar</span>
          </Button>
        </>,
        <DownloadButton
          key="submit"
          type="primary"
          disabled={handlers?.length ? false : true}
          onClick={() => downloadCSV()}
        >
          <span className="button-title">Download CSV</span> <DownloadIcon />
        </DownloadButton>,
      ]}
    >
      {loading ? (
        <Spin />
      ) : handlers?.length ? (
        <Table
          loading={loading}
          columns={columns}
          pagination={false}
          dataSource={handlers.map((entity) => ({
            ...entity,
            key: entity.id,
          }))}
        />
      ) : (
        <Empty description="Não há movimentações" />
      )}
    </Modal>
  );
};

export default ModalHandlers;
