import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import api from '../../../services/api';
import { Col, Form, Modal, notification, Row } from 'antd';
import { Royalty } from '../../../models/Royalty';
import { currencyFormater } from '../../../services/currencyFormater';
import MonetaryInput from '../../../components/MonetaryInput2';
import OrderPaymentType from "../../../models/enums/OrderPaymentType.json";
import { ButtonCancel, ButtonSave, ContentPayment, Select, TotalPayment } from './styles';

interface IProps {
    royalty: Royalty | undefined;
    visible: boolean;
    setVisible: Dispatch<SetStateAction<boolean>>;
    setShouldSearch: Dispatch<SetStateAction<boolean>>;
}

const ModalParcialPayment: React.FC<IProps> = ({
    royalty,
    visible,
    setShouldSearch,
    setVisible
}) => {
    const [payment, setPayment] = useState<number>(0);
    const [paymentType, setPaymentType] = useState<number | null>(5);
    const [loading, setLoading] = useState<boolean>(false);
    const [valuePayment, setValuePayment] = useState<number>(0);

    const [form] = Form.useForm();

    const calculateTotalPayment = (royalty: Royalty) => {
        return royalty.royalty_payments
            .filter(payment => payment.deleted_at === null)
            .reduce((total, payment) => total + parseFloat(payment.payment_value), 0);
    };

    const totalPayment = royalty ? calculateTotalPayment(royalty) : 0;

    useEffect(() => {
        if (royalty) {
            setValuePayment((parseFloat(royalty.value_bankslip) || 0) - totalPayment);
        }
    }, [royalty, totalPayment]);

    const onFinish = async () => {
        form.validateFields();

        if (payment === 0) {
            return notification.warning({
                message: "Preencha os campos corretamente",
                description: "O campo Valor unitário deve ser preenchido",
                duration: 5,
            });
        }
        if (paymentType === null) {
            return notification.warning({
                message: "Preencha os campos corretamente",
                description: "Modo de pagamento deve ser selecionado",
                duration: 5,
            });
        }

        if (payment > valuePayment) {
            return notification.warning({
                message: "Valor inválido",
                description: "O valor inserido ultrapassa o valor restante.",
                duration: 5,
            });
        }

        try {
            setLoading(true);
            await api.post("/royalty_payments", {
                royalty_id: royalty?.id,
                payment_value: +payment,
                payment_type: paymentType,
                type: royalty?.type
            });

            notification.success({
                message: "Pagamento cadastrado",
                description: `O pagamento no valor de R$ ${currencyFormater(
                    +payment
                )} foi cadastrado com sucesso.`,
                duration: 5,
            });
            setShouldSearch(true);
            resetFields();
        } catch (error) {
            //@ts-ignore
            const _description = error.message;
            notification.error({
                message: "Oops! Não foi possível realizar o pagamento",
                description: error ? _description : "Falha ao realizar o pagamento.",
                duration: 5,
            });
        } finally {
            setLoading(false);
        }
    };

    const resetFields = async () => {
        form.resetFields();
        setPayment(0);
        setPaymentType(1);
        setVisible(false);
    };

    return (
        <Modal
            title={`Confirmar pagamento do boleto - ${royalty?.cod_bankslip}?`}
            visible={visible}
            centered
            destroyOnClose={true}
            width={'50%'}
            closable={false}
            footer={[
                <ButtonCancel onClick={() => resetFields()}>Cancelar</ButtonCancel>,
                <ButtonSave
                    loading={loading}
                    disabled={payment > valuePayment}
                    onClick={() => onFinish()}
                >
                    Enviar
                </ButtonSave>,
            ]}
        >
            <Form layout="vertical" form={form}>
                <Row gutter={4}>
                    <Col sm={10} xs={24}>
                        <Form.Item
                            label="Método de pagamento"
                            name="paymentType"
                            initialValue={5}
                            rules={[
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ]}
                        >
                            <Select
                                placeholder="Selecione um modo"
                                onChange={(value) => setPaymentType(+value)}
                            >
                                {OrderPaymentType.map((orderPaymentType) => (
                                    <Select.Option
                                        label={orderPaymentType.label}
                                        value={orderPaymentType.value}
                                        key={orderPaymentType.value}
                                    >
                                        {orderPaymentType.label}
                                    </Select.Option>
                                ))}
                            </Select>
                        </Form.Item>
                    </Col>

                    <Col sm={10}>
                        <Form.Item
                            label="Valor unitário"
                            name="payment"
                            rules={[
                                {
                                    required: true,
                                    message: "Campo obrigatório",
                                },
                            ]}
                        >
                            <MonetaryInput
                                getValue={(value) => {
                                    setPayment(value);
                                    form.setFieldsValue({ payment: value });
                                }}
                                defaultValue={+payment}
                            />
                        </Form.Item>
                    </Col>
                    <Col sm={1} style={{ minWidth: "3.3rem" }}>
                        <Form.Item label=" ">
                            <ButtonSave
                                disabled={payment > valuePayment}
                                onClick={() => setPayment(valuePayment)}
                            >
                                +
                            </ButtonSave>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
            <ContentPayment>
                <TotalPayment>
                    <div>
                        Valor pago até agora: R${" "}
                        <span style={{ color: "var(--orange-350)" }}>
                            {totalPayment ? currencyFormater(totalPayment) : 0} {"  "}
                        </span>
                    </div>

                    <div>
                        Valor restante a pagar: R${" "}
                        <span style={{ color: "var(--orange-350)" }}>
                            {+valuePayment > 0 ? currencyFormater(valuePayment) : 0}
                        </span>
                    </div>

                    <div>
                        Total do boleto: R${" "}
                        <span style={{ color: "var(--orange-350)" }}>
                            {currencyFormater(+valuePayment + totalPayment)}
                        </span>
                    </div>
                </TotalPayment>
            </ContentPayment>
        </Modal>
    );
}

export default ModalParcialPayment;
