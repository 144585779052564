import React, { useState, useEffect } from "react";

import { withRouter, RouteComponentProps } from "react-router-dom";

import api from "../../../../services/api";
import apiMercury from "../../../../services/apiMercury";
import { verifyPermission } from "../../../../services/auth";
import { currencyFormater } from "../../../../services/currencyFormater";
import { useStoreData } from "../../../../hooks/useStoreData";

import { Purchase } from "../../../../models/Purchase/Purchase";
import { ProductCategory as ProductCategoryResponse } from "../../../../models/ProductCategory";

import { removeAccentsAndLowerCase } from "../../../../utils/removeAccentsAndCaseSensitive";

import PageContainer from "../../../../containers/PageContainer";
import ShoppingList from "../../../../containers/ShoppingList";
import OrderBuyModal from "../../../../containers/OrderBuyModal";
import Centralizer from "../../../../containers/Centralizer";

import GetStore from "../../../../components/GetStore";
import MonetaryInput2 from "../../../../components/MonetaryInput2";
import RangeDataField from "../../../../components/RangeDataField";
import StoreSelectionPage from "../../../../components/StoreSelectionPage";
import DisabledFlag from "../../../../components/DisabledFlag";

import { Spin, Tooltip, message } from "antd";

import moment, { Moment } from "moment";
import swal from "sweetalert";

import PurchasesImg from "../../../../assets/svg/purchasespage.svg";
import NoData from "../../../../assets/svg/nodatapurchase.svg";

import {
  Row,
  Col,
  ButtonAdd,
  Checkbox,
  AddIcon,
  ButtonFilter,
  ButtonSearch,
  BodyList,
  FilterIcon,
  RowHeaderList,
  ColHeaderList,
  ModalFilter,
  ModalInfo,
  ModalFilterContainer,
  ButtonClear,
  GraphIcon,
  ContainerTotal,
  Total,
  HeaderTotal,
  Select,
} from "./styles";


const { Option } = Select;

interface ComponentProps extends RouteComponentProps<{}, {}, any> { }

type PurchasesItems = {
  id?: number;
  product_name?: string;
  quantity?: number | null;
  unitary_value?: number | null;
};

type PurchasesOrder = {
  user_id?: number;
  user_name?: string;
  total?: number;
  created_at?: string;
};

const Shop: React.FC<ComponentProps> = ({ location, history }) => {
  const { state } = location;
  const today = moment();

  //@ts-ignore
  const store_param = state?.store_param;

  const [productsCategory, setProductsCategory] = useState<
    ProductCategoryResponse[]
  >([]);

  const [purchasesOrder, setPurchasesOrder] = useState<PurchasesOrder | null>(
    null
  );
  const [purchasesItems, setPurchasesItems] = useState<PurchasesItems[]>([]);
  const [purchases, setPurchases] = useState<Purchase[]>([]);

  const [purchaseId, setPurchaseId] = useState<number>();
  const [store, setStore] = useState<number | null | undefined>(
    +store_param || null
  );

  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [allOrders, setAllOrders] = useState(false);
  const [check, setCheck] = useState(false);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [visibleInfo, setVisibleInfo] = useState(false);
  const [shouldSearch, setShouldSearch] = useState(false);
  const [visibleOrderBuy, setVisibleOrderBuy] = useState(false);
  const [purchaseFilters, setPurchaseFilters] = useState(false);

  const { isStoreActive } = useStoreData(store);

  const [total, setTotal] = useState<{
    total: number;
    totalPay: number;
    totalExpirated: number;
    totalOpen: number;
  }>({ total: 0, totalPay: 0, totalExpirated: 0, totalOpen: 0 });

  const [filter, setFilter] = useState<{
    data_inicial: Moment;
    data_final: Moment;
    value_min?: number;
    value_max?: number;
    category?: number;
    product?: number;
    status?: number;
  }>({
    data_inicial: moment(),
    data_final: moment().add(1, "days"),
    value_min: undefined,
    value_max: undefined,
    category: undefined,
    product: undefined,
    status: undefined,
  });

  const [date, setDate] = useState({
    data_inicial: moment().subtract(90, "days"),
    data_final: moment().add(1, "days"),
  });

  useEffect(() => {
    if (state?.storeId) {
      setStore(state.storeId);
      setFilter({
        data_inicial: moment(),
        data_final: moment().add(1, "days"),
        value_min: undefined,
        value_max: undefined,
        category: undefined,
        product: undefined,
        status: undefined,
      });
      setCheck(false);
    }
  }, [state]);

  useEffect(() => {
    const fetchShop = async () => {
      try {
        setLoading(true);
        const { data_inicial, data_final } = {
          data_inicial: moment(date.data_inicial).format("DD/MM/YYYY"),
          data_final: moment(date.data_final).format("DD/MM/YYYY"),
        };

        const UrlBuilder = () => {
          let URL = allOrders
            ? `purchases?purchaseFilters=${purchaseFilters}&data_inicial=${data_inicial}&data_final=${data_final}`
            : `purchases/store/${store}?purchaseFilters=${purchaseFilters}&data_inicial=${data_inicial}&data_final=${data_final}`;

          if (purchaseFilters) {
            if (filter.value_min || filter.value_max) {
              URL +=
                `&min_value=${filter.value_min ? filter.value_min : 0}` +
                `&max_value=${filter.value_max ? filter.value_max : 999999}`;
            }

            if (filter.category) {
              URL += `&category_id=${filter.category}`;
            }
            if (filter.status) {
              URL += `&status=${filter.status}`;
            }
          }
          return URL;
        };

        const {
          data: { content },
        } = await apiMercury.get(UrlBuilder());

        if (content.length === 0 && purchaseFilters) {
          swal(
            "Nenhum resultado encontrado!",
            "Os valores de filtro não retornaram resultados",
            "warning"
          );
          setPurchaseFilters(false);
        } else {
          if (filter.product) {
            const productFilterList = content.filter((_purchase) =>
              _purchase.purchasesItems.some(
                (_product) => _product.product_id === filter.product
              )
            );
            if (productFilterList.length === 0) {
              swal(
                "Nenhum resultado encontrado!",
                "Os valores de filtro não retornaram resultados",
                "warning"
              );
              setPurchaseFilters(false);
            } else {
              calcTotal(productFilterList);
              setPurchases(productFilterList);
            }
          } else {
            calcTotal(content);
            setPurchases(content);
          }
        }
        setLoading(false);
      } catch (e) {
        const _message =
          //@ts-ignore
          e?.response?.data?.message || "Houve um erro ao realizar busca";
        message.error(_message);
      }
    };
    if (((store || allOrders) && shouldSearch) || store_param) {
      fetchShop();
      setShouldSearch(false);
    }
  }, [
    store,
    shouldSearch,
    date.data_inicial,
    date.data_final,
    purchaseFilters,
    filter,
    store_param,
  ]);

  useEffect(() => {
    const fetchProductsByCategory = async () => {
      try {
        const {
          data: { content },
        } = await api.get("/product_categories/products/purchases");
        setProductsCategory(content);
      } catch (e) {
        const _message =
          //@ts-ignore
          e?.response?.data?.message || "Houve um erro ao realizar busca";
        message.error(_message);
      }
    };
    fetchProductsByCategory();
  }, []);

  const calcTotal = (purchases: Purchase[]) => {
    const _total =
      purchases?.reduce(
        (accumulator, prox) => accumulator + +(prox.total || 0),
        0
      ) || 0;
    const _totalPay =
      purchases
        ?.filter((purchase) => purchase?.pay_date)
        .reduce((accumulator, prox) => accumulator + +(prox.total || 0), 0) ||
      0;
    const _totalExpirated =
      purchases
        ?.filter(
          (purchase) =>
            !purchase?.pay_date && moment(purchase?.due_date).isBefore(today)
        )
        .reduce((accumulator, prox) => accumulator + +(prox.total || 0), 0) ||
      0;
    const _totalOpen =
      purchases
        ?.filter(
          (purchase) =>
            !purchase?.pay_date && moment(purchase?.due_date).isAfter(today)
        )
        .reduce((accumulator, prox) => accumulator + +(prox.total || 0), 0) ||
      0;

    setTotal({
      total: _total,
      totalPay: _totalPay,
      totalExpirated: _totalExpirated,
      totalOpen: _totalOpen,
    });
  };

  const searchFilter = () => {
    setAllOrders(check);
    setPurchaseFilters(true);
    setVisible(false);
    setShouldSearch(true);
  };

  const resetFilter = () => {
    setFilter({
      data_inicial: moment().subtract(90, "days"),
      data_final: moment().add(1, "days"),
      value_min: undefined,
      value_max: undefined,
      category: undefined,
      product: undefined,
      status: undefined,
    });
    setDate({
      data_inicial: moment().subtract(90, "days"),
      data_final: moment().add(1, "days"),
    });
    setAllOrders(false);
    setPurchaseFilters(false);
    setVisible(false);
    setShouldSearch(true);
  };

  const newPurchase = () => {
    setPurchaseId(undefined);
    setVisibleOrderBuy(true);
  };

  const infoModal = async (purchase) => {
    setVisibleInfo(true);
    setLoadingInfo(true);
    try {
      const {
        data: { content },
      } = await apiMercury.get(`/purchases/${purchase.id}`);
      setPurchasesOrder({
        user_id: content.user_id,
        user_name: content.user.name,
        total: content.total,
        created_at: content.created_at,
      });
      setPurchasesItems(
        content.purchasesItems.map((item) => ({
          id: +item.id,
          product_name: item.product.name,
          quantity: +item.quantity,
          unitary_value: item.unitary_value,
        }))
      );
    } catch (e) {
      const _message =
        //@ts-ignore
        e?.response?.data?.message || "Houve um erro ao realizar busca";
      message.error(_message);
    }
    setLoadingInfo(false);
  };

  const disabledDate = (current) => {
    const dates = [date.data_final, date.data_inicial];
    if (!dates || dates.length === 0) {
      return false;
    }
    const tooLate = dates[0] && current.diff(dates[0], "days") > 90;
    const tooEarly = dates[1] && dates[1].diff(current, "days") > 90;
    return tooEarly || tooLate;
  };

  return (
    <PageContainer route="Compras">
      <>
        {(!store && allOrders) || (store && purchases.length !== 0) ? (
          <>
            <BodyList>
              <DisabledFlag isStoreActive={isStoreActive} />

              <RowHeaderList>
                <ColHeaderList sm={allOrders ? 6 : 7} xs={!store ? 19 : 15}>
                  <GetStore
                    defaultValue={store}
                    handleChange={(id) => {
                      setStore(id);
                      setFilter({
                        data_inicial: moment(),
                        data_final: moment().add(1, "days"),
                        value_min: undefined,
                        value_max: undefined,
                        category: undefined,
                        product: undefined,
                        status: undefined,
                      });
                      setShouldSearch(true);
                    }}
                  />
                </ColHeaderList>

                <ColHeaderList
                  sm={0}
                  md={!allOrders ? 1 : 0}
                  xs={0}
                  style={{ flex: "0" }}
                >
                  {verifyPermission("shop.purchases_report") && !allOrders && (
                    <Tooltip title={"Relatório de Compras"}>
                      <ButtonFilter
                        onClick={() => history.push(`/shop_report/${store}`)}
                      >
                        <GraphIcon />
                      </ButtonFilter>
                    </Tooltip>
                  )}
                </ColHeaderList>

                <ColHeaderList sm={1} xs={1} style={{ flex: "0" }}>
                  {(allOrders || store) && (
                    <Tooltip title={"Filtro"}>
                      <ButtonFilter
                        onClick={() => {
                          setVisible(true);
                          setCheck(allOrders);
                        }}
                      >
                        <FilterIcon />
                      </ButtonFilter>
                    </Tooltip>
                  )}
                </ColHeaderList>
                {!isStoreActive && (
                  <ColHeaderList
                    sm={store ? 1 : 0}
                    xs={store ? 1 : 0}
                    style={{ flex: "0", marginRight: "0" }}
                  >
                    {verifyPermission("shop.add") && store && (
                      <Tooltip title={"Nova Compra"}>
                        <ButtonAdd onClick={() => newPurchase()}>
                          <AddIcon />
                        </ButtonAdd>
                      </Tooltip>
                    )}
                  </ColHeaderList>
                )}
                <HeaderTotal>
                  <Total style={{ background: "var(--orange-350)" }}>
                    <span> Total:</span>
                    <span>R$ {currencyFormater(total.total)}</span>
                  </Total>
                  <Total style={{ background: "var(--green-400)" }}>
                    <span>Pago:</span>
                    <span>R$ {currencyFormater(total.totalPay)}</span>
                  </Total>
                </HeaderTotal>
                <HeaderTotal>
                  <Total style={{ background: "var(--blue-960)" }}>
                    <span>Em aberto:</span>
                    <span>R$ {currencyFormater(total.totalOpen)}</span>
                  </Total>
                  <Total style={{ background: "var(--orange-600)" }}>
                    <span>Expirado:</span>
                    <span>R$ {currencyFormater(total.totalExpirated)}</span>
                  </Total>
                </HeaderTotal>
              </RowHeaderList>
              <RowHeaderList style={{ marginTop: "0.1rem" }}>
                {loading ? (
                  <BodyList style={{ marginTop: "15%" }}>
                    <Centralizer>
                      <Spin />
                    </Centralizer>
                  </BodyList>
                ) : (
                  <>
                    <ContainerTotal>
                      <Total style={{ background: "var(--orange-350)" }}>
                        <span> Total:</span>
                        <span>R$ {currencyFormater(total.total)}</span>
                      </Total>
                      <Total style={{ background: "var(--green-400)" }}>
                        <span>Pago:</span>
                        <span>R$ {currencyFormater(total.totalPay)}</span>
                      </Total>
                      <Total style={{ background: "var(--blue-960)" }}>
                        <span>Em aberto:</span>
                        <span>R$ {currencyFormater(total.totalOpen)}</span>
                      </Total>
                      <Total style={{ background: "var(--orange-600)" }}>
                        <span>Expirado:</span>
                        <span>R$ {currencyFormater(total.totalExpirated)}</span>
                      </Total>
                    </ContainerTotal>
                    <ShoppingList
                      setLoading={setLoading}
                      shouldSearch={shouldSearch}
                      loading={loading}
                      setPurchaseId={setPurchaseId}
                      purchases={purchases}
                      setShouldSearch={setShouldSearch}
                      buttonInfo={infoModal}
                      setVisibleOrderBuy={setVisibleOrderBuy}
                      isStoreActive={isStoreActive}
                    />
                  </>
                )}
              </RowHeaderList>
            </BodyList>
          </>
        ) : (
          <>
            {!store && !allOrders ? (
              <>
                <StoreSelectionPage
                  title="Para buscar ou cadastrar uma compra"
                  Img={PurchasesImg}
                  store={store}
                  setStore={setStore}
                  setShouldSearch={setShouldSearch}
                  checkBoxVisible={true}
                  checked={allOrders}
                  setAll={setAllOrders}
                  permissionCheck="shop.all_purchases"
                  textCheckBox="Todas as compras"
                ></StoreSelectionPage>
              </>
            ) : (
              <>
                {loading ? (
                  <BodyList>
                    <Centralizer>
                      <Spin />
                    </Centralizer>
                  </BodyList>
                ) : (
                  <>
                    <StoreSelectionPage
                      title="Oops! Nenhuma compra cadastrada nesta loja."
                      textTitle="Faça o cadastro de uma nova compra ou selecione outra
                  loja"
                      Img={NoData}
                      store={store}
                      setStore={setStore}
                      setShouldSearch={setShouldSearch}
                      checkBoxVisible={true}
                      checked={allOrders}
                      setAll={setAllOrders}
                      permissionCheck="shop.all_purchases"
                      textCheckBox="Todas as compras"
                      buttonVisible={true}
                      permissionButton="shop.add"
                      buttonClick={newPurchase}
                      buttonText="Nova Compra"
                    ></StoreSelectionPage>
                  </>
                )}
              </>
            )}
          </>
        )}
      </>

      <ModalFilter
        title="Filtros"
        centered
        onCancel={() => setVisible(false)}
        visible={visible}
        width={450}
        footer={null}
        destroyOnClose={true}
      >
        <Row>
          <Col sm={24}>
            <span>Data de Vencimento</span>
            <RangeDataField
              data={date}
              setData={setDate}
              disabledDate={disabledDate}
            />
          </Col>

          <Col sm={12} xs={24}>
            <span>Valor Mínimo</span>
            <MonetaryInput2
              defaultValue={filter.value_min}
              getValue={(value) =>
                setFilter((oldValues) => ({ ...oldValues, value_min: +value }))
              }
            />
          </Col>
          <Col sm={12} xs={24}>
            <span>Valor Máximo</span>
            <MonetaryInput2
              defaultValue={filter.value_max}
              getValue={(value) =>
                setFilter((oldValues) => ({ ...oldValues, value_max: +value }))
              }
            />
          </Col>

          <Col sm={24} xs={24}>
            <span>Categoria</span>
            <Select
              placeholder="Escolha a opção"
              defaultValue={filter.category}
              onChange={(value) =>
                setFilter((oldValues) => ({
                  ...oldValues,
                  category: +value,
                  product: undefined,
                }))
              }
              showSearch
              filterOption={(input, option) =>
                removeAccentsAndLowerCase(option?.children).indexOf(
                  removeAccentsAndLowerCase(input)
                ) >= 0
              }
              optionFilterProp="children"
            >
              {productsCategory.map((category) => (
                <Option value={category.id} key={category.id}>
                  {category.name}
                </Option>
              ))}
            </Select>
          </Col>
          {filter.category !== undefined && (
            <Col sm={24} xs={24}>
              <span>Produtos</span>
              <Select
                placeholder="Escolha a opção"
                value={filter.product}
                onChange={(value) =>
                  setFilter((oldValues) => ({ ...oldValues, product: +value }))
                }
                showSearch
                filterOption={(input, option) =>
                  removeAccentsAndLowerCase(option?.children).indexOf(
                    removeAccentsAndLowerCase(input)
                  ) >= 0
                }
                optionFilterProp="children"
              >
                {productsCategory?.map(
                  (productCategory) =>
                    productCategory.id === filter.category &&
                    productCategory.products?.map((product) => (
                      <Option value={product.id || 0} key={product.id}>
                        {product.name}
                      </Option>
                    ))
                )}
              </Select>
            </Col>
          )}

          <Col sm={24} xs={24}>
            <span>Situação</span>
            <Select
              placeholder="Escolha a opção"
              style={{ width: "100%" }}
              defaultValue={filter.status}
              onChange={(value) =>
                setFilter((oldValues) => ({ ...oldValues, status: +value }))
              }
            >
              <Option value={1} key={1}>
                Paga
              </Option>
              <Option value={2} key={2}>
                Em Aberto
              </Option>
              <Option value={3} key={3}>
                Atrasada
              </Option>
            </Select>
          </Col>
          {verifyPermission("shop.all_purchases") && (
            <Col sm={24}>
              <Checkbox
                checked={check}
                onChange={() => {
                  setCheck(!check);
                }}
              >
                <span className="allOrders">
                  Todas <span className="orders">as compras</span>
                </span>
              </Checkbox>
            </Col>
          )}
          <Col sm={16} xs={24}>
            <ButtonSearch onClick={() => searchFilter()}>
              Buscar itens
            </ButtonSearch>
          </Col>
          <Col sm={8} xs={24}>
            <ButtonClear onClick={() => resetFilter()}>
              <span>Limpar Filtro</span>
            </ButtonClear>
          </Col>
        </Row>
      </ModalFilter>

      <ModalInfo
        title="Informações de Compra"
        centered
        onCancel={() => {
          setVisibleInfo(false);
          setPurchasesOrder(null);
          setPurchasesItems([]);
        }}
        visible={visibleInfo}
        width={"90%"}
        footer={null}
        destroyOnClose={true}
      >
        <ModalFilterContainer>
          {loadingInfo ? (
            <BodyList>
              <Centralizer>
                <Spin />
              </Centralizer>
            </BodyList>
          ) : (
            <>
              <Row
                style={{
                  borderBottom: "1px solid var(--gray-75)",
                  color: "var(--blue-900)",
                }}
              >
                <Col xs={0} sm={6} md={5}>
                  Data de Lançamento
                </Col>
                <Col xs={0} sm={0} md={3}>
                  Hora
                </Col>
                <Col xs={0} sm={0} md={6}>
                  Responsável
                </Col>
                <Col xs={16} sm={12} md={7}>
                  Itens
                </Col>
                <Col xs={8} sm={6} md={3}>
                  Valor
                </Col>
              </Row>
              <Row style={{ marginTop: "0.2rem" }}>
                <Col xs={0} sm={6} md={5}>
                  <span className="info">
                    {moment(purchasesOrder?.created_at).format("DD/MM/YYYY")}
                  </span>
                </Col>
                <Col xs={0} sm={0} md={3}>
                  <span className="info">
                    {moment(purchasesOrder?.created_at).format("HH:MM:SS")}
                  </span>
                </Col>
                <Col xs={0} sm={0} md={6}>
                  <span className="info">{purchasesOrder?.user_name}</span>
                </Col>
                <Col xs={16} sm={12} md={7}>
                  <span className="info">
                    {purchasesItems?.length > 0 ? (
                      purchasesItems.map((item) => (
                        <span key={item?.id}>
                          {item?.quantity}X -{item?.product_name}
                        </span>
                      ))
                    ) : (
                      <span>Produto não encontrado</span>
                    )}
                  </span>
                </Col>
                <Col xs={8} sm={6} md={3}>
                  <span className="info">
                    {purchasesItems?.length > 0 ? (
                      purchasesItems.map((item) => (
                        <span key={item?.id}>
                          {`R$ ${item?.unitary_value}` || 0}
                        </span>
                      ))
                    ) : (
                      <span> Valor Indefinido</span>
                    )}
                  </span>
                </Col>
              </Row>
            </>
          )}
        </ModalFilterContainer>
      </ModalInfo>

      <OrderBuyModal
        visibleOrderBuy={visibleOrderBuy}
        setVisibleOrderBuy={setVisibleOrderBuy}
        storeId={store}
        purchaseId={purchaseId}
        setPurchaseId={setPurchaseId}
        setshouldSearch={setShouldSearch}
        productsCategory={productsCategory}
      />
    </PageContainer>
  );
};

export default withRouter(Shop);
