import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import api from "../../services/api";
import apiSales from "../../services/apiSalesHandler";
import { verifyPermission } from "../../services/auth";

import MonetaryInput2 from "../MonetaryInput2";

import { currencyFormater } from "../../services/currencyFormater";
import { CashHistories as CashHistoriesModel } from "../../models/CashHistories";

import useEscapeKey from "../../utils/useEscapeKey";

import { notification, Spin, Tooltip } from "antd";

import {
  Col,
  Container,
  Modal,
  Row,
  Title,
  ContainerDescription,
  Item,
  ButtonReload,
  ContentRow,
  ReloadIcon,
  PencilIcon,
} from "./styles";

interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  cashHistory: CashHistoriesModel | null;
  cashClosed: boolean;
  setShouldSearch: (shouldSearch: boolean) => void;
  isStoreActive: boolean | undefined;
}

const ModalItemsCashHistoryMoreInfo: React.FC<IProps> = ({
  visible,
  setVisible,
  cashHistory,
  cashClosed,
  setShouldSearch,
  isStoreActive
}) => {
  const [loading, setLoading] = useState(false);
  const [recalculate, setRecalculate] =
    useState<CashHistoriesModel["balance_history"]>();

  const [editable, setEditable] = useState({
    money: false,
    credit_card: false,
    debit_card: false,
    online: false,
    pix: false,
    ticket: false,
  });

  useEffect(() => {
    setRecalculate(undefined);
  }, [visible]);

  const handleModalToggle = () => {
    setVisible(!visible);
    setEditable({
      money: false,
      credit_card: false,
      debit_card: false,
      online: false,
      pix: false,
      ticket: false,
    });
  };

  useEscapeKey({ setVisible: () => setVisible(false) });

  const BalanceRecalculate = async () => {
    setLoading(true);
    try {
      const response = await apiSales.get<{
        content: CashHistoriesModel["balance_history"];
      }>(`/sales/balance_history/${cashHistory?.id}`);

      const newBalanceHistory = response?.data?.content;
      if (newBalanceHistory) {
        const money = parseFloat(newBalanceHistory.money);
        const changeAmount = parseFloat(newBalanceHistory.change_amount ?? 0);
        newBalanceHistory.money = (money - changeAmount).toFixed(2);
      }
      setRecalculate(newBalanceHistory);
    } catch {
      notification.error({
        message: "Erro ao buscar valores",
        duration: 5,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleBalanceChange = () => {
    Modal.confirm({
      title: "Atualizar o balanço?",
      content: "Deseja continuar e atualizar o balanço?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      async onOk() {
        if (!recalculate || !cashHistory || !cashHistory?.balance_history)
          return;
        const payload = {
          money: (
            +recalculate?.money + +recalculate?.change_amount || 0
          ).toFixed(2),
          credit_card: (+recalculate?.credit_card || 0).toFixed(2),
          debit_card: (+recalculate?.debit_card || 0).toFixed(2),
          pix: (+recalculate?.pix || 0).toFixed(2),
          ticket: (+recalculate?.ticket || 0).toFixed(2),
          online: (+recalculate?.online || 0).toFixed(2),
        };
        try {
          await api.put(
            `/balance_history/${cashHistory.balance_history.id}`,
            payload
          );

          setShouldSearch(true);
          setRecalculate(undefined);
          notification.success({
            message: "Balanço atualizado com sucesso!",
            description: "Uma nova venda foi gerada no caixa",
            duration: 5,
          });
        } catch {
          notification.error({
            message: "Erro ao fazer a atualização",
            duration: 5,
          });
        }
      },
    });
  };

  const handleChangeEditableValues = (value: any, name: string) => {
    const newValue = { ...recalculate, ...value };

    const currentValue = (cashHistory?.balance_history || {}) as {
      total: number;
      money: number;
      credit_card: number;
      debit_card: number;
      pix: number;
      ticket: number;
      online: number;
      change_amount: number;
    };

    if (newValue[name] < currentValue[name]) {
      notification.warning({
        message: "Ops! O valor inserido deve ser maior que o valor anterior.",
        description: `O valor deve ser superior a R$ ${parseFloat(currentValue[name]).toFixed(2)}.`,
        duration: 2,
      });
      return;
    } else {
      setRecalculate(newValue);
      setEditable((oldValues) => ({ ...oldValues, [name]: false }));
    }
  };

  const calculateBilling = (cashHistory: CashHistoriesModel | null) => {
    const totalSales =
      Number(cashHistory?.balance_history?.money ?? 0) +
      Number(cashHistory?.balance_history?.credit_card ?? 0) +
      Number(cashHistory?.balance_history?.debit_card ?? 0) +
      Number(cashHistory?.balance_history?.online ?? 0) +
      Number(cashHistory?.balance_history?.pix ?? 0) +
      Number(cashHistory?.balance_history?.ticket ?? 0);

    const billing = currencyFormater(+totalSales || 0);

    return billing;
  };

  const billing = calculateBilling(cashHistory);

  const moreInfo = [
    {
      xs: 12,
      sm: 6,
      xl: 3,
      color: "var(--blue-900)",
      title: "Faturamento",
      item: `R$ ${billing} `,
    },
    {
      xs: 12,
      sm: 6,
      xl: 3,
      color: "#43B868",
      title: "Dinheiro",
      name: "money",
      item: (
        <>
          <MonetaryInput2
            defaultValue={+(cashHistory?.balance_history?.money || 0)}
            disabled={recalculate && editable.money ? false : true}
            onEnterPress={(value) =>
              handleChangeEditableValues(
                {
                  money: +(value || "0"),
                },
                "money"
              )
            }
          />
          {recalculate && (
            <PencilIcon
              onClick={() =>
                setEditable((oldValues) => ({ ...oldValues, money: true }))
              }
            />
          )}
        </>
      ),
    },

    {
      xs: 12,
      sm: 6,
      xl: 3,
      color: "#5BC0DE",
      title: "Crédito",
      name: "credit_card",
      item: (
        <>
          <MonetaryInput2
            defaultValue={+(cashHistory?.balance_history?.credit_card || 0)}
            disabled={recalculate && editable.credit_card ? false : true}
            onEnterPress={(value) =>
              handleChangeEditableValues(
                {
                  credit_card: +(value || "0"),
                },
                "credit_card"
              )
            }
          />{" "}
          {recalculate && (
            <PencilIcon
              onClick={() =>
                setEditable((oldValues) => ({
                  ...oldValues,
                  credit_card: true,
                }))
              }
            />
          )}
        </>
      ),
    },
    {
      xs: 12,
      sm: 6,
      xl: 3,
      color: "#1963A2",
      title: "Débito",
      name: "debit_card",
      item: (
        <>
          <MonetaryInput2
            defaultValue={+(cashHistory?.balance_history?.debit_card || 0)}
            disabled={recalculate && editable.debit_card ? false : true}
            onEnterPress={(value) =>
              handleChangeEditableValues(
                {
                  debit_card: +(value || "0"),
                },
                "debit_card"
              )
            }
          />
          {recalculate && (
            <PencilIcon
              onClick={() =>
                setEditable((oldValues) => ({
                  ...oldValues,
                  debit_card: true,
                }))
              }
            />
          )}
        </>
      ),
    },
    {
      xs: 12,
      sm: 4,
      xl: 2,
      color: "#00BDAE",
      title: "PIX",
      name: "pix",
      item: (
        <>
          <MonetaryInput2
            defaultValue={+(cashHistory?.balance_history?.pix || 0)}
            disabled={recalculate && editable.pix ? false : true}
            onEnterPress={(value) =>
              handleChangeEditableValues(
                {
                  pix: +(value || "0"),
                },
                "pix"
              )
            }
          />
          {recalculate && (
            <PencilIcon
              onClick={() =>
                setEditable((oldValues) => ({ ...oldValues, pix: true }))
              }
            />
          )}
        </>
      ),
    },
    {
      xs: 12,
      sm: 4,
      xl: 2,
      color: "#7E3AFC",
      title: "Ticket",
      name: "ticket",
      item: (
        <>
          <MonetaryInput2
            defaultValue={+(cashHistory?.balance_history?.ticket || 0)}
            disabled={recalculate && editable.ticket ? false : true}
            onEnterPress={(value) =>
              handleChangeEditableValues(
                {
                  ticket: +(value || "0"),
                },
                "ticket"
              )
            }
          />
          {recalculate && (
            <PencilIcon
              onClick={() =>
                setEditable((oldValues) => ({ ...oldValues, ticket: true }))
              }
            />
          )}
        </>
      ),
    },

    {
      xs: 12,
      sm: 6,
      xl: 3,
      color: "#4BB8A9",
      title: "Online",
      name: "online",
      item: (
        <>
          <MonetaryInput2
            defaultValue={+(cashHistory?.balance_history?.online || 0)}
            disabled={recalculate && editable.online ? false : true}
            onEnterPress={(value) =>
              handleChangeEditableValues(
                {
                  online: +(value || "0"),
                },
                "online"
              )
            }
          />
          {recalculate && (
            <PencilIcon
              onClick={() =>
                setEditable((oldValues) => ({ ...oldValues, online: true }))
              }
            />
          )}
        </>
      ),
    },
    {
      xs: 0,
      sm: 10,
      xl: 4,
      color: "var(--blue-900)",
      title: "Usuário",
      item: `${
        cashHistory?.opened_user
          ? cashHistory?.opened_user?.name
          : "Usuário deletado"
      }`,
    },
  ];

  return (
    <Modal
      title={
        <>
          <ContentRow>
            <p>Faturamento</p>
            {verifyPermission("cash_management.recalculateButton") && (
              <Tooltip
                title="Para recalcular o balanço, feche o caixa"
                visible={!cashClosed}
              >
                <ButtonReload
                  onClick={BalanceRecalculate}
                  disabled={!cashClosed || loading || isStoreActive}
                >
                  {loading ? <Spin size="small" /> : <ReloadIcon />}
                  <span>Recalcular balanço</span>
                </ButtonReload>
              </Tooltip>
            )}
          </ContentRow>
        </>
      }
      centered
      visible={visible}
      onOk={
        !recalculate
          ? () => setVisible(false)
          : () => {
              setVisible(false);
              setEditable({
                money: false,
                credit_card: false,
                debit_card: false,
                online: false,
                pix: false,
                ticket: false,
              });
              handleBalanceChange();
            }
      }
      okText={recalculate ? "Concluir" : "Ok"}
      onCancel={handleModalToggle}
      cancelButtonProps={{ hidden: true }}
      closable={false}
      width={"80%"}
      destroyOnClose
    >
      <>
        <Container>
          <Row gutter={2}>
            {moreInfo.map((entity, index) => (
              <React.Fragment key={index}>
                <Col xs={entity?.xs} sm={entity?.sm} xl={entity?.xl}>
                  <Title style={{ color: `${entity.color}` }}>
                    {entity?.title}
                  </Title>
                  <Item
                    active={
                      recalculate && editable[`${entity.name}`] ? true : false
                    }
                  >
                    {entity?.item}
                  </Item>
                </Col>
              </React.Fragment>
            ))}
          </Row>
        </Container>
        <ContainerDescription>
          {cashHistory && cashHistory.observation ? (
            <>
              <span className="title">Observações: </span>
              <span className="obs">{cashHistory.observation}</span>
            </>
          ) : null}
        </ContainerDescription>
      </>
    </Modal>
  );
};

export default ModalItemsCashHistoryMoreInfo;
