import React, { useEffect, useState } from "react";
import { Empty, message, Modal, notification, Tabs } from "antd";
import apiAuth from "../../../services/apiAuth";
import PageContainer from "../../../containers/PageContainer";

import { Page } from "../../../models/Page";
import { Order as OrderModel } from "../../../models/Order/Order";
import OrdersList from "../OrdersList";
import {
  Button,
  Container,
  Header,
  CheckboxContainer,
  Checkbox,
  Content,
  ContentConsolidate,
  ContainerButton,
  ContainerConsolidate,
  ContentCheckBox,
} from "./styles";
import Pagination from "../../../components/Pagination";
import { getTokenInfo } from "../../../services/auth";
import api from "../../../services/api";
import Centralizer from "../../../containers/Centralizer";
import Spinner from "../../../components/Spinner";
import { CategoryModel } from "../../../models/CategoryModel";
import { OrderItem } from "../../../models/Order/OrderItem";

const OrdersSendBlingPY: React.FC = () => {
  const [loadingStore, setLoadingStore] = useState<boolean>(false);
  const [loadingBling, setLoadingBling] = useState<boolean>(false);
  const [paginate, setPaginate] = useState<Page>({
    page: 1,
    size: 60,
    totalElements: 0,
  });

  const [consolidatedPaginate, setConsolidatedPaginate] = useState<Page>({
    page: 1,
    size: 2,
    totalElements: 0,
  });
  const [geladoChecked, setGeladoChecked] = useState(false);
  const [secoChecked, setSecoChecked] = useState(false);
  const [todosChecked, setTodosChecked] = useState(false);
  const [categories, setCategories] = useState<CategoryModel[]>([]);

  const [selectedOrderIds, setSelectedOrderIds] = useState<number[]>([]);
  const [paraguayCompanyIds, setParaguayCompanyIds] = useState<number[]>([]);
  const [companyIdsReady, setCompanyIdsReady] = useState<boolean>(false);
  const [ordersToConsolidate, setOrdersToConsolidate] = useState<OrderModel[]>(
    []
  );
  const [exportedOrderGroups, setExportedOrderGroups] = useState<{
    [key: string]: OrderModel[];
  }>({});
  const [selectedGroups, setSelectedGroups] = useState<string[]>([]);
  const [activeTabKey, setActiveTabKey] = useState<string>("1");

  const handleSelectedOrders = (newSelectedOrderIds: number[]) => {
    setSelectedOrderIds(newSelectedOrderIds);
  };

  const handleGroupSelect = (groupId: string) => {
    setSelectedGroups((prevSelectedGroups) => {
      if (prevSelectedGroups.includes(groupId)) {
        return prevSelectedGroups.filter((id) => id !== groupId);
      } else {
        return [groupId];
      }
    });
  };

  useEffect(() => {
    const fetchCompanyIds = async () => {
      setLoadingStore(true);
      try {
        const user = await getTokenInfo();
        const { data } = await apiAuth.get(`/companyUser/${user?.id}/user`);
        const companies = data.content;

        const paraguayCompanies = companies.filter(
          (company) => company.company.country?.toLowerCase() === "paraguai"
        );

        const paraguayCompanyIds = paraguayCompanies.map(
          (company) => company.company.id
        );

        setParaguayCompanyIds(paraguayCompanyIds);
        setCompanyIdsReady(true);
      } catch (error) {
        const _description =
          //@ts-ignore
          error.response?.data || "Erro ao buscar empresas do Paraguai";
        notification.error({
          message: "Erro ao buscar empresas",
          description: _description,
          duration: 5,
        });
      } finally {
        setLoadingStore(false);
      }
    };

    fetchCompanyIds();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const {
          data: { content },
        } = await api.get(`/product_categories/products/orders`);

        setCategories(content);
      } catch (error) {
        //@ts-ignore
        const _description = error.data;

        notification.error({
          message: "Erro ao buscar categorias",
          description: _description,
          duration: 5,
        });
      }
    };
    fetchCategories();
  }, []);

  const fetchAllStoresOrders = async () => {
    if (!companyIdsReady || paraguayCompanyIds.length === 0) return;

    setLoadingStore(true);
    try {
      const storeIds = paraguayCompanyIds.join(",");
      const { data: pagination } = await api.get(
        `/orders?page=${paginate.page}&size=${paginate.size}&stores_ids=${storeIds}`
      );
      const { totalElements, content } = pagination;

      setPaginate((oldValues) => ({ ...oldValues, totalElements }));

      const toConsolidate: OrderModel[] = [];
      const exportedGroups: { [key: string]: OrderModel[] } = {};

      const processedOrderIds = new Set<number>();

      content.forEach((order: OrderModel) => {
        if (processedOrderIds.has(order.id)) return;

        processedOrderIds.add(order.id);

        const hasBlingId = order.orderItems.some(
          (item: OrderItem) => item.sale_order_bling_id
        );

        if (!hasBlingId) {
          toConsolidate.push(order);
        } else {
          const groupId = order.orderItems[0].sale_order_bling_id || "null";
          if (!exportedGroups[groupId]) {
            exportedGroups[groupId] = [];
          }
          exportedGroups[groupId].push(order);
        }
      });

      console.log("Pedidos agrupados:", exportedGroups);

      setOrdersToConsolidate(toConsolidate);
      setExportedOrderGroups(exportedGroups);

      const totalConsolidatedOrders = Object.keys(exportedGroups).length;
      setConsolidatedPaginate((oldValues) => ({
        ...oldValues,
        page: oldValues.page || 1,
        size: oldValues.size || 60,
        totalElements: totalConsolidatedOrders,
      }));
    } catch (error) {
      const _description =
        //@ts-ignore
        error.response?.data || "Erro ao buscar pedidos do Paraguai";
      notification.error({
        message: "Erro ao buscar pedidos",
        description: _description,
        duration: 5,
      });
    } finally {
      setLoadingStore(false);
    }
  };

  useEffect(() => {
    fetchAllStoresOrders();
  }, [
    paginate.page,
    paginate.size,
    consolidatedPaginate.page,
    paraguayCompanyIds,
    companyIdsReady,
  ]);

  useEffect(() => {
    if (activeTabKey === "1") {
      fetchAllStoresOrders();
    }
  }, [
    paginate.page,
    paginate.size,
    activeTabKey,
    paraguayCompanyIds,
    companyIdsReady,
  ]);

  useEffect(() => {
    if (activeTabKey === "2") {
      fetchAllStoresOrders();
    }
  }, [
    consolidatedPaginate.page,
    consolidatedPaginate.size,
    activeTabKey,
    paraguayCompanyIds,
    companyIdsReady,
  ]);

  const handleCheckboxChange = (type: number) => {
    let newSelectedOrderIds: number[] = [];

    if (type === 0) {
      setGeladoChecked(!geladoChecked);
      setSecoChecked(false);
      setTodosChecked(false);
      newSelectedOrderIds = !geladoChecked
        ? ordersToConsolidate
            .filter((order) => order.type_of_load === 0)
            .map((order) => order.id)
        : [];
    } else if (type === 1) {
      setGeladoChecked(false);
      setSecoChecked(!secoChecked);
      setTodosChecked(false);
      newSelectedOrderIds = !secoChecked
        ? ordersToConsolidate
            .filter((order) => order.type_of_load === 1)
            .map((order) => order.id)
        : [];
    } else if (type === 3) {
      setGeladoChecked(false);
      setSecoChecked(false);
      setTodosChecked(!todosChecked);
      newSelectedOrderIds = !todosChecked
        ? ordersToConsolidate.map((order) => order.id)
        : [];
    }

    handleSelectedOrders(newSelectedOrderIds);
  };

  const handleConsolidate = async () => {
    const hideMessage = message.loading({
      content: "Aguarde, estamos enviando os pedidos para o Bling...",
      duration: 0,
    });
    setLoadingBling(true);
    try {
      const orderIdsToSend = selectedOrderIds;

      await api.patch(`/orders/paraguay/send-to-bling`, {
        ordersIds: orderIdsToSend,
      });

      notification.success({
        message: "Sucesso",
        description: "Pedidos enviados para o Bling com sucesso.",
        duration: 5,
      });
      fetchAllStoresOrders();
      setSelectedOrderIds([]);
    } catch (error) {
      let _description = "Erro ao enviar pedidos";
      //@ts-ignore
      if (error.response && error.response.data) {
        //@ts-ignore
        const errorData = error.response.data;

        if (typeof errorData === "string") {
          _description = errorData;
        }

        if (typeof errorData.error === "string") {
          _description = errorData.error;
        }

        if (errorData.error && typeof errorData.error.message === "string") {
          _description = errorData.error.message;
        }

        if (typeof errorData.message === "string") {
          _description = errorData.message;
        }

        if (Array.isArray(errorData.error)) {
          const formattedErrors = errorData.error.join("\n");
          notification.error({
            message: "Erro ao enviar pedidos",
            description: (
              <strong>
                <pre>{formattedErrors}</pre>
              </strong>
            ),
            duration: 5,
            style: { width: "700px" },
          });
          return;
        }

        notification.error({
          message: "Erro ao enviar pedidos",
          description: <strong>{_description}</strong>,
          duration: 5,
        });
      }
    } finally {
      hideMessage();
      setLoadingBling(false);
    }
  };

  const handleRefundSelectedGroups = async () => {
    const ordersToRefund = selectedGroups.reduce((acc, groupId) => {
      const groupOrders = exportedOrderGroups[groupId].map((order) => order.id);
      return [...acc, ...groupOrders];
    }, [] as number[]);

    Modal.confirm({
      title: "Confirmar Estorno",
      content: `Você está prestes a estornar todos os pedidos dos grupos selecionados. Tem certeza que deseja continuar?`,
      okText: "Sim",
      cancelText: "Cancelar",
      onOk: async () => {
        try {
          await api.delete(`/orders/paraguay/remove-to-bling`, {
            data: { ordersIds: ordersToRefund },
          });
          notification.success({
            message: "Sucesso",
            description:
              "Todos os pedidos dos grupos selecionados foram estornados com sucesso.",
            duration: 5,
          });

          fetchAllStoresOrders();
          setSelectedGroups([]);
        } catch (error) {
          notification.error({
            message: "Erro ao estornar pedidos",
            description:
              "Ocorreu um erro ao tentar estornar os pedidos. Tente novamente mais tarde.",
            duration: 5,
          });
        }
      },
    });
  };

  const currentPage = consolidatedPaginate.page ?? 1;
  const pageSize = consolidatedPaginate.size ?? 2;

  return (
    <PageContainer route="Consolidar Pedidos do Paraguay Para envio ao Bling">
      <Container>
        {loadingBling ? (
          <Centralizer>
            <Spinner />
          </Centralizer>
        ) : (
          <>
            <Tabs type="card" onChange={(key) => setActiveTabKey(key)}>
              <Tabs.TabPane tab="Consolidar e Enviar" key="1">
                <Header>
                  <CheckboxContainer>
                    <h2>Lista de Pedidos</h2>
                    <Checkbox
                      type="checkbox"
                      checked={geladoChecked}
                      onChange={() => handleCheckboxChange(0)}
                      disabled={
                        todosChecked ||
                        secoChecked ||
                        loadingBling ||
                        loadingStore
                      }
                    >
                      Gelado
                    </Checkbox>
                    <Checkbox
                      checked={secoChecked}
                      onChange={() => handleCheckboxChange(1)}
                      disabled={
                        todosChecked ||
                        geladoChecked ||
                        loadingBling ||
                        loadingStore
                      }
                    >
                      Seco
                    </Checkbox>
                    <Checkbox
                      checked={todosChecked}
                      onChange={() => handleCheckboxChange(3)}
                      disabled={
                        secoChecked ||
                        geladoChecked ||
                        loadingBling ||
                        loadingStore
                      }
                    >
                      Todos
                    </Checkbox>
                  </CheckboxContainer>
                  <Button
                    disabled={
                      selectedOrderIds.length === 0 ||
                      loadingBling ||
                      loadingStore
                    }
                    onClick={handleConsolidate}
                    loading={loadingBling}
                  >
                    Consolidar e Enviar ao Bling
                  </Button>
                </Header>
                <Content>
                  {loadingStore ? (
                    <Centralizer>
                      <Spinner />
                    </Centralizer>
                  ) : (
                    <>
                      <OrdersList
                        loadingBlingPY={loadingBling}
                        orders={ordersToConsolidate}
                        setShouldSearch={() => {}}
                        categories={categories}
                        setVisibleEdit={() => {}}
                        setOrderToEdit={() => {}}
                        isStoreActive={true}
                        showCheckboxes={true}
                        selectedOrderIds={selectedOrderIds}
                        onSelectedChange={handleSelectedOrders}
                        countryPY={true}
                      />
                      <Pagination
                        disabled={loadingBling}
                        setStateSearch={() => {}}
                        setPaginate={setPaginate}
                        defaultPageSize={60}
                        showSizeChanger={false}
                        current={paginate.page}
                        totalElements={paginate.totalElements}
                      />
                    </>
                  )}
                </Content>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Pedidos Consolidados no Bling" key="2">
                <ContentConsolidate>
                  {loadingStore ? (
                    <Centralizer>
                      <Spinner />
                    </Centralizer>
                  ) : (
                    <>
                      <ContainerButton>
                        <Button
                          onClick={handleRefundSelectedGroups}
                          disabled={selectedGroups.length === 0}
                        >
                          Estornar Selecionados
                        </Button>
                      </ContainerButton>
                      {Object.keys(exportedOrderGroups).length === 0 ? (
                        <Centralizer>
                          <Empty description="Nenhum pedido exportado disponível." />
                        </Centralizer>
                      ) : (
                        Object.keys(exportedOrderGroups)
                          .slice(
                            (currentPage - 1) * pageSize,
                            currentPage * pageSize
                          )
                          .map((groupId) => (
                            <ContainerConsolidate>
                              <ContentCheckBox>
                                <Checkbox
                                  checked={selectedGroups.includes(groupId)}
                                  onChange={() => handleGroupSelect(groupId)}
                                  disabled={
                                    selectedGroups.length > 0 &&
                                    !selectedGroups.includes(groupId)
                                  }
                                >
                                  ID grupo exportado para o Bling: {groupId}
                                </Checkbox>
                              </ContentCheckBox>
                              <OrdersList
                                loadingBlingPY={loadingBling}
                                orders={exportedOrderGroups[groupId]}
                                setShouldSearch={() => {}}
                                categories={categories}
                                setVisibleEdit={() => {}}
                                setOrderToEdit={() => {}}
                                isStoreActive={true}
                                showCheckboxes={false}
                                selectedOrderIds={[]}
                                onSelectedChange={() => {}}
                                countryPY={true}
                              />
                            </ContainerConsolidate>
                          ))
                      )}
                      <Pagination
                        disabled={loadingStore}
                        setStateSearch={() => {}}
                        setPaginate={setConsolidatedPaginate}
                        defaultPageSize={consolidatedPaginate.size}
                        showSizeChanger={false}
                        current={consolidatedPaginate.page}
                        totalElements={consolidatedPaginate.totalElements}
                      />
                    </>
                  )}
                </ContentConsolidate>
              </Tabs.TabPane>
            </Tabs>
          </>
        )}
      </Container>
    </PageContainer>
  );
};

export default OrdersSendBlingPY;
