import React, { useState, useEffect, SetStateAction, Dispatch } from "react";

import apiMercury from "../../services/apiMercury";

import { ProductCategory as ProductCategoryResponse } from "../../models/ProductCategory";

import OrderPaymentType from "../../models/enums/OrderPaymentType.json";

import FormCard from "../../components/FormCard";
import MonetaryInput2 from "../../components/MonetaryInput2";
import SearchProvider from "../../components/SearchProvider";
import ModalProvider from "../../components/ModalProvider";

import { Modal, Spin } from "antd";

import moment from "moment";
import swal from "sweetalert";

import {
  Form,
  Input,
  Container,
  Content,
  Row,
  Col,
  AddItem,
  FormItem,
  ContainerItens,
  Item,
  IconShoppingCart,
  Footer,
  InfoLabel,
  DatePicker,
  CancelButton,
  SaveButton,
  RowTable,
  ContainerFooter,
  ValorTotal,
  SpinContainer,
  ButtonAction,
  ContainerModal,
  Select,
} from "./styles";

import { validarBoleto } from "@mrmgomes/boleto-utils";

const { Panel } = Item;
const { TextArea } = Input;
const { Option } = Select;

type PurchasesItems = {
  id?: number;
  key: number;
  product_id?: number | null;
  category_id?: number | null;
  category_name?: string;
  observation?: string | null;
  product_name?: string;
  quantity?: number | null;
  unitary_value?: number | null;
  new: boolean;
};

type PurchasesOrder = {
  store_id?: number;
  provider_id?: number | null;
  provider_name?: string;
  due_date?: string;
  pay_date?: string;
  observation?: string;
  name?: string;
  payment_method?: number;
  total?: number;
  additional_value?: number;
  discount_value?: number;
  created_at?: string;
};

interface IProps {
  storeId: number | undefined | null;
  purchaseId?: number | undefined;
  setPurchaseId: Dispatch<SetStateAction<number | undefined>>;
  visibleOrderBuy: boolean;
  setVisibleOrderBuy: Dispatch<SetStateAction<boolean>>;
  setshouldSearch: Dispatch<SetStateAction<boolean>>;
  productsCategory: ProductCategoryResponse[];
}

const OrderBuyModal: React.FC<IProps> = ({
  visibleOrderBuy,
  setVisibleOrderBuy,
  storeId,
  purchaseId,
  setPurchaseId,
  setshouldSearch,
  productsCategory,
}) => {
  const [loading, setLoading] = useState(true);

  const [purchasesOrder, setPurchasesOrder] = useState<PurchasesOrder | null>(
    null
  );
  const [purchasesItems, setPurchasesItems] = useState<PurchasesItems[]>([]);
  const [newPurchasesCategory, setNewPurchasesCategory] = useState<
    number | undefined
  >();
  const [unitaryValue, setUnitaryValue] = useState<number | undefined>();
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [formItens] = Form.useForm();

  useEffect(() => {
    const fetchPurchase = async () => {
      setLoading(true);
      const {
        data: { content },
      } = await apiMercury.get(`/purchases/${purchaseId}`);

      setPurchasesOrder({
        store_id: content.store_id,
        provider_id: content?.provider?.id,
        provider_name: content?.provider?.fantasy_name,
        due_date: content.due_date,
        pay_date: content.pay_date,
        observation: content.observation,
        payment_method: content.payment_method,
        total: content.total,
        additional_value: content.additional_value,
        discount_value: content.discount_value,
        created_at: content.created_at,
      });

      setPurchasesItems(
        content.purchasesItems?.map((item) => ({
          id: +item.id,
          key: +item.id,
          product_id: +item.product_id,
          category_id: +item.category_id,
          observation: item.observation,
          product_name: item.product?.name,
          quantity: +item.quantity,
          unitary_value: +item.unitary_value,
          new: false,
        }))
      );

      form.setFieldsValue({
        observation: content.observation,
        name: content.name,
        date_order: moment(content.due_date),
        pay_date: content.pay_date ? moment(content.pay_date) : null,
        payment_method: +content.payment_method,
      });
      setLoading(false);
    };
    if (purchaseId) {
      fetchPurchase();
    } else {
      setPurchasesItems([]);
      setLoading(false);
    }
  }, [form, purchaseId]);

  const createId = (value) => {
    if (purchasesItems?.some((item) => item.id === value)) {
      const newId = createId(value + 1);
      return newId;
    } else {
      return value;
    }
  };

  const addMorePurchasesItems = (values) => {
    const newProduct = values;
    if (!values.id) {
      setPurchasesItems((oldValues) => [
        ...oldValues,
        {
          id:
            purchasesItems.length === 0
              ? purchasesItems.length + 1
              : createId(purchasesItems.length + 1),
          key:
            purchasesItems.length === 0
              ? purchasesItems.length + 1
              : createId(purchasesItems.length + 1),
          category_id: newProduct.category_id,
          product_id: newProduct.product_id,
          quantity: newProduct.quantity,
          unitary_value: unitaryValue,
          observation: newProduct.observation,
          new: true,
        },
      ]);
    } else {
      const indexToUpdate = purchasesItems.findIndex(
        (item) => item.id === values.id
      );
      const newProductsList = purchasesItems;
      newProductsList[indexToUpdate] = values;
      newProductsList[indexToUpdate].unitary_value = unitaryValue;
      setPurchasesItems(newProductsList);
    }

    setPurchasesOrder((oldValues) => ({
      ...oldValues,
      total: getTotalPurchase(),
    }));

    setNewPurchasesCategory(undefined);
    setUnitaryValue(undefined);
    formItens.resetFields();
  };

  const removeItem = (id: number | undefined) => {
    setPurchasesItems(purchasesItems.filter((item) => item.id !== id));
    const newTotal = purchasesItems.reduce((total, item) => {
      if (item.id !== id) {
        return total + (item.unitary_value || 0) * (item.quantity || 0);
      }
      return total;
    }, 0);
    handlePurchasesOrder("total", newTotal);
  };

  const editItem = (item) => {
    setNewPurchasesCategory(item.category_id);
    setUnitaryValue(item.unitary_value);
    formItens.setFieldsValue({
      id: item.id,
      category_id: item.category_id,
      product_id: item.product_id,
      quantity: item.quantity,
      observation: item.observation,
    });
  };

  const handlePurchasesOrder = (name, value) => {
    setPurchasesOrder((oldValues) => ({ ...oldValues, [name]: value }));
  };

  const getTotalOnItems = (): number => {
    return purchasesItems.reduce(
      (total, item) => total + (item.unitary_value || 0) * (item.quantity || 0),
      0
    );
  };

  const getTotalPurchase = (): number => {
    return (
      getTotalOnItems() +
      +(purchasesOrder?.additional_value || 0) -
      +(purchasesOrder?.discount_value || 0)
    );
  };

  const validatedFields = (): boolean => {
    let isValid = true;
    purchasesItems.forEach((item) => {
      if (!item.quantity || !item.unitary_value || !item.product_id) {
        isValid = false;
      }
    });

    if (!purchasesOrder?.due_date) {
      isValid = false;
    }

    return isValid;
  };

  const clearFields = () => {
    setPurchaseId(undefined);
    form.resetFields();
    form.setFieldsValue({
      payment_method: 0,
    });
    setUnitaryValue(0);
    formItens.resetFields();
    setPurchasesOrder(null);
    setPurchasesItems([]);
  };

  const handleSubmit = () => {
    if (!validatedFields()) {
      swal("Oops!", "Preencha os campos corretamente!", "warning");
      return;
    }

    const method = purchaseId ? "put" : "post";

    const payload = {
      store_id: storeId,
      due_date:
        method === "put"
          ? moment(purchasesOrder?.due_date).toISOString()
          : moment(
              purchasesOrder?.due_date,
              "DD/MM/YYYYTHH:mm:ss.sssZ"
            ).toISOString(),

      pay_date: purchasesOrder?.pay_date
        ? moment(purchasesOrder?.pay_date).toISOString()
        : null,
      payment_method: purchasesOrder?.payment_method || 0,
      total: +getTotalPurchase().toFixed(2),
      additional_value: purchasesOrder?.additional_value,
      discount_value: purchasesOrder?.discount_value,
      observation: purchasesOrder?.observation,
      name: productsCategory.find(
        (category) => category.id === purchasesItems[0].category_id
      )?.name,
      provider_id: purchasesOrder?.provider_id,
      purchasesItems: purchasesItems?.map((item) =>
        item.new ? { ...item, id: null } : item
      ),
      created_at: moment(purchasesOrder?.created_at).toISOString(),
    };

    Modal.confirm({
      title: "Cadastrar Compra",
      content: "Deseja continuar e cadastrar essa compra?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      async onOk() {
        setLoading(true);
        try {
          const url = purchaseId ? `/purchases/${purchaseId}` : "/purchases";
          await apiMercury[method](url, payload);
          swal("Ordem de compra salva com sucesso", "", "success");
          setshouldSearch(true);
          clearFields();
        } catch {
          swal("Oops!", "Falha ao salvar ordem de compra!", "error");
        } finally {
          setLoading(false);
        }
        setLoading(false);
        setVisibleOrderBuy(false);
      },
    });
  };

  const closeModal = () => {
    setVisibleOrderBuy(false);
    clearFields();
  };
  return (
    <Container
      closable={false}
      destroyOnClose={true}
      visible={visibleOrderBuy}
      title="Nova Compra/Editar Compra"
      style={{ top: "1rem" }}
      footer={
        <>
          {loading ? (
            <SpinContainer />
          ) : (
            <>
              <Footer>
                <ContainerFooter>
                  <CancelButton onClick={() => closeModal()}>
                    Cancelar
                  </CancelButton>
                  <SaveButton
                    style={{ marginLeft: "10px" }}
                    onClick={() => handleSubmit()}
                    disabled={purchasesItems.length > 0 ? false : true}
                  >
                    Salvar
                  </SaveButton>
                </ContainerFooter>
                {(getTotalPurchase() || purchasesOrder?.total) &&
                  purchasesItems.length > 0 && (
                    <ValorTotal>
                      <span>Valor total </span>
                      <h1>
                        R$:
                        {getTotalPurchase().toFixed(2) ||
                          purchasesOrder?.total?.toFixed(2)}
                      </h1>
                    </ValorTotal>
                  )}
              </Footer>
            </>
          )}
        </>
      }
      onCancel={() => setVisibleOrderBuy(false)}
    >
      {loading ? (
        <SpinContainer>
          <Spin />
        </SpinContainer>
      ) : (
        <>
          <>
            <Content>
              <Form
                layout="vertical"
                form={formItens}
                onFinish={addMorePurchasesItems}
              >
                <FormCard title="Adicionar compra">
                  <ContainerModal>
                    <Row>
                      <Col sm={0} xs={0}>
                        <Form.Item name="id">
                          <input />
                        </Form.Item>
                      </Col>
                      <Col sm={8} xs={24}>
                        <Form.Item
                          label="Categoria"
                          name="category_id"
                          rules={[
                            { required: true, message: "Campo obrigatório" },
                          ]}
                        >
                          <Select
                            showSearch
                            value={newPurchasesCategory || undefined}
                            placeholder="Selecione uma categoria"
                            onChange={(value) => {
                              setNewPurchasesCategory(+value);
                              formItens.setFieldsValue({
                                product_id: undefined,
                              });
                            }}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toString()
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {productsCategory?.map((productCategory) => (
                              <Option
                                value={productCategory.id}
                                key={productCategory.id}
                                label={productCategory.name}
                              >
                                {productCategory.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col sm={8} xs={24}>
                        <Form.Item
                          label="Produto"
                          name="product_id"
                          key="key"
                          rules={[
                            { required: true, message: "Campo obrigatório" },
                          ]}
                        >
                          <Select
                            showSearch
                            placeholder="Selecione algum produto"
                            disabled={!newPurchasesCategory}
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              (option?.label ?? "")
                                .toString()
                                .toLowerCase()
                                .includes(input.toLowerCase())
                            }
                          >
                            {productsCategory?.map(
                              (productCategory) =>
                                productCategory.id === newPurchasesCategory &&
                                productCategory.products?.map((product) => (
                                  <Option
                                    value={product?.id || ""}
                                    key={product?.id}
                                    label={product.name}
                                  >
                                    {product.name}
                                  </Option>
                                ))
                            )}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        sm={6}
                        xs={24}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Col sm={11} xs={11}>
                          <FormItem label="Valor Unitário">
                            <MonetaryInput2
                              defaultValue={unitaryValue}
                              getValue={(value) => setUnitaryValue(+value)}
                            />
                          </FormItem>
                        </Col>
                        <Col sm={11} xs={11}>
                          <FormItem
                            label="Quantidade"
                            name="quantity"
                            rules={[
                              {
                                required: true,
                                message: "Campo obrigatório",
                              },
                            ]}
                          >
                            <Input
                              placeholder="Informe a Quantidade"
                              type="number"
                            />
                          </FormItem>
                        </Col>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={17} xs={24}>
                        <FormItem name="observation">
                          <Input
                            placeholder="Observações do item"
                            name="observation"
                          />
                        </FormItem>
                      </Col>
                      <Col sm={6} xs={24}>
                        <AddItem type="submit">
                          <IconShoppingCart />
                          Novo Produto
                        </AddItem>
                      </Col>
                    </Row>
                  </ContainerModal>
                </FormCard>
              </Form>{" "}
              <Form layout="vertical" form={form}>
                <h3>Lista dos Pedidos</h3>
                <ContainerItens>
                  {purchasesItems.length > 0 && (
                    <>
                      {purchasesItems?.map((item, index) => (
                        <Item key={index} expandIconPosition={"right"}>
                          <Panel
                            header={
                              <RowTable>
                                <Col sm={12} xs={14}>
                                  {productsCategory?.map((productCategory) =>
                                    item.category_id === productCategory.id
                                      ? productCategory.name
                                      : null
                                  )}
                                </Col>
                                <Col sm={4}>{item.quantity}</Col>
                              </RowTable>
                            }
                            key={index}
                          >
                            <RowTable style={{ padding: "0.8rem 1rem 0" }}>
                              <Col
                                sm={8}
                                xs={11}
                                style={{ textTransform: "capitalize" }}
                              >
                                <span>{"Item:"}</span>
                                <p>
                                  {productsCategory
                                    ?.map((productCategory) =>
                                      productCategory.products.find(
                                        (product) =>
                                          product?.id === item?.product_id
                                      )
                                    )
                                    .filter((product) => product !== undefined)
                                    ?.map((product) => product?.name)
                                    .join(", ") || "Produto não encontrado"}
                                </p>
                              </Col>
                              <Col sm={8} xs={11}>
                                <span> {"Valor Unitário:"}</span>
                                <p>{" R$ " + item.unitary_value?.toFixed(2)}</p>
                              </Col>
                            </RowTable>
                            <RowTable style={{ padding: "0 1rem" }}>
                              <Col sm={24} xs={24}>
                                <span> {"Observações:"} </span>
                                <p>{item.observation}</p>
                              </Col>
                            </RowTable>
                            <RowTable
                              style={{
                                justifyContent: "flex-end",
                                background: "white",
                                height: "2rem",
                                padding: "0.2rem",
                              }}
                            >
                              <Col sm={12} xs={12}>
                                <ButtonAction
                                  style={{
                                    color: "var(--gray-55)",
                                  }}
                                  onClick={() => editItem(item)}
                                >
                                  Editar Produto
                                </ButtonAction>
                              </Col>
                              <Col
                                sm={12}
                                xs={12}
                                style={{
                                  justifyContent: "end",
                                }}
                              >
                                <ButtonAction
                                  style={{
                                    justifyContent: "end",
                                    color: "var(--orange-600)",
                                  }}
                                  onClick={() => removeItem(item.id)}
                                >
                                  Excluir Produto
                                </ButtonAction>
                              </Col>
                            </RowTable>
                          </Panel>
                        </Item>
                      ))}
                    </>
                  )}
                </ContainerItens>
                <ContainerModal>
                  <Row>
                    <Col sm={7} xs={24}>
                      <FormItem
                        label="Tipo de Pagamento"
                        name="payment_method"
                        initialValue={purchasesOrder?.payment_method || 0}
                      >
                        <Select
                          onChange={(payment) =>
                            handlePurchasesOrder("payment_method", +payment)
                          }
                        >
                          {OrderPaymentType?.map((type) => (
                            <Option value={type.value} key={type.value}>
                              {type.label}
                            </Option>
                          ))}
                        </Select>
                      </FormItem>
                    </Col>
                    <Col sm={7} xs={24}>
                      <FormItem label="Fornecedor">
                        <SearchProvider
                          defaultValue={purchasesOrder?.provider_name}
                          handleChange={(value) =>
                            handlePurchasesOrder("provider_id", value)
                          }
                        />
                        <InfoLabel onClick={() => setVisible(true)}>
                          Cadastrar fornecedor
                        </InfoLabel>
                      </FormItem>
                    </Col>
                    <Col sm={7} xs={24}>
                      <Form.Item
                        label="Data de Vencimento"
                        name="date_order"
                        rules={[
                          { required: true, message: "Campo obrigatório" },
                        ]}
                      >
                        <DatePicker
                          placeholder="DD/MM/AAAA"
                          format="DD/MM/YYYY HH:mm:ss"
                          onChange={(date) =>
                            handlePurchasesOrder("due_date", date)
                          }
                        />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Row>
                    <Col sm={7} xs={24}>
                      <FormItem label="Valor Adicional" name="additional_value">
                        <MonetaryInput2
                          getValue={(value) =>
                            handlePurchasesOrder("additional_value", value)
                          }
                          defaultValue={purchasesOrder?.additional_value}
                        />
                      </FormItem>
                    </Col>

                    <Col sm={7} xs={24}>
                      <FormItem label="Desconto">
                        <MonetaryInput2
                          getValue={(value) =>
                            handlePurchasesOrder("discount_value", value)
                          }
                          defaultValue={purchasesOrder?.discount_value}
                        />
                      </FormItem>
                    </Col>

                    <Col sm={7} xs={24}>
                      {purchasesOrder?.payment_method === 5 ? (
                        <>
                          <Col sm={24} xs={24}>
                            <Form.Item
                              label="Código do Boleto"
                              name="observation"
                              rules={[
                                {
                                  validator: (_, value) => {
                                    const cleanedValue = validarBoleto(
                                      value.replace(/[^0-9]+/g, "")
                                    );
                                    return cleanedValue.sucesso === true
                                      ? Promise.resolve()
                                      : Promise.reject(cleanedValue.mensagem);
                                  },
                                },
                              ]}
                            >
                              <Input
                                type="text"
                                placeholder="Código do Boleto"
                                name="observation"
                                value={purchasesOrder?.observation}
                                onChange={({ target: { value } }) =>
                                  handlePurchasesOrder(
                                    "observation",
                                    value.replace(/[^0-9]/g, "")
                                  )
                                }
                              />
                            </Form.Item>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col sm={24} xs={24}>
                            <Form.Item
                              label="Observações da ordem"
                              name="observation"
                            >
                              <TextArea
                                rows={2}
                                onChange={({ target: { value } }) =>
                                  handlePurchasesOrder("observation", value)
                                }
                              />
                            </Form.Item>
                          </Col>
                        </>
                      )}
                    </Col>
                  </Row>

                  {purchasesOrder?.created_at && (
                    <Row>
                      <Col
                        sm={24}
                        xs={24}
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          Criada em:{" "}
                          {moment(purchasesOrder?.created_at).format(
                            "DD/MM/YYYY HH:mm:ss"
                          )}
                        </span>
                      </Col>
                    </Row>
                  )}
                </ContainerModal>
              </Form>
            </Content>

            <ModalProvider visible={visible} setVisible={setVisible} />
          </>
        </>
      )}
    </Container>
  );
};

export default OrderBuyModal;
