import React, { Dispatch, SetStateAction, useState, useEffect } from "react";
import apiMercury from "../../services/apiMercury";

import InputMask from "../../components/InputMask";

import { Form, Input, message } from "antd";

import { Modal, Col } from "./styles";
interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  providerId?: number;
}

const ModalProvider: React.FC<IProps> = ({
  visible,
  setVisible,
  providerId,
}) => {
  const [loading, setLoading] = useState(false);

  const [providerForm] = Form.useForm();

  useEffect(() => {
    async function fetchProvider() {
      setLoading(true);
      const { data } = await apiMercury.get(`/providers/${providerId}`);
      providerForm.setFieldsValue({
        cnpj: data?.content?.cnpj,
        company_name: data?.content?.company_name,
        fantasy_name: data?.content?.fantasy_name,
      });
      setLoading(false);
    }
    if (providerId) {
      fetchProvider();
    }
  }, [providerId, providerForm]);

  const handleState = (event) => {
    if (event.target.name === "cnpj") {
      event.target.value = event.target.value.replace(/[^0-9]+/g, "");
    }
  };

  const validateField = async (): Promise<boolean> => {
    try {
      await providerForm.validateFields();
      return true;
    } catch {
      message.warning("Preencha todos os campos corretamente.");
      return false;
    }
  };

  const handleFinish = async () => {
    const validated = await validateField();
    if (!validated) {
      return;
    }
    try {
      const method = providerId ? "put" : "post";
      const url = providerId ? `/providers/${providerId}` : "/providers";
      setLoading(true);

      const { cnpj, company_name, fantasy_name } =
        providerForm.getFieldsValue();
      await apiMercury[method](url, {
        cnpj: cnpj,
        company_name: company_name.toLowerCase(),
        fantasy_name: fantasy_name.toLowerCase(),
      });
      message.success("Fornecedor salvo com sucesso.");
      setLoading(false);
      setVisible(false);
    } catch {
      setLoading(false);
      message.error("Fornecedor já cadastrado.");
    }
  };

  return (
    <Modal
      confirmLoading={loading}
      title="Cadastro de fornecedor"
      centered
      visible={visible}
      onOk={() => handleFinish()}
      onCancel={() => setVisible(false)}
      destroyOnClose={true}
    >
      <Col sm={24} xs={24}>
        <Form layout="vertical" form={providerForm}>
          <Form.Item
            label="CNPJ"
            name="cnpj"
            rules={[
              {
                validator: (_, value) => {
                  const cleanedValue = value.replace(/[^0-9]+/g, "");
                  return cleanedValue.length === 14
                    ? Promise.resolve()
                    : Promise.reject("CNPJ inválido");
                },
              },
            ]}
          >
            <InputMask
              disabled={loading}
              placeholder="CNPJ"
              mask="99.999.999/9999-99"
              name="cnpj"
              value={"99.999.999/9999-99"}
              onChange={handleState}
            />
          </Form.Item>

          <Col sm={24} xs={24}>
            <Form.Item
              label="Razão social"
              name="company_name"
              rules={[{ required: true, message: "Campo obrigatório" }]}
            >
              <Input
                disabled={loading}
                placeholder="Razão social"
                name="company_name"
              />
            </Form.Item>
          </Col>

          <Col sm={24} xs={24}>
            <Form.Item label="Apelido do fornecedor" name="fantasy_name">
              <Input
                disabled={loading}
                placeholder="Apelido do fornecedor"
                name="fantasy_name"
              />
            </Form.Item>
          </Col>
        </Form>
      </Col>
    </Modal>
  );
};

export default ModalProvider;
