import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import locale from "antd/es/date-picker/locale/pt_BR";

import moment from "moment";

import api from "../../../services/api";
import { currencyFormater } from "../../../services/currencyFormater";

import { useWindowSize } from "../../../hooks/useWindowSize";

import { OrderStatus } from "../../../models/enums/OrderStatus";
import { CategoryModel } from "../../../models/CategoryModel";
import { ProductStore } from "../../../models/ProductStore";

import GetStore from "../../../components/GetStore";
import MonetaryInput from "../../../components/MonetaryInput2";
import Spinner from "../../../components/Spinner";

import Centralizer from "../../../containers/Centralizer";

import { Order as OrderModel } from "../../../models/Order/Order";

import notFound from "../../../assets/svg/data-not-found.svg";

import { DatePicker, Modal, Tooltip, notification } from "antd";

import {
  Container,
  Center,
  ContentCategories,
  Select,
  TextSelect,
  Form,
  StepIcon,
  StepperContainer,
  Steps,
  ContainerItens,
  Table,
  ListItens,
  ItemCard,
  ContainerCards,
  ContainerItensInfo,
  QuantityInput,
  ItemText,
  ItemImage,
  ListText,
  DeleteItem,
  Footer,
  TotalFooter,
  Total,
  ButtonsContainer,
  Button,
  ButtonSave,
  Content,
  Row,
  Col,
  TextAreaComment,
  Divider,
  LineDivider,
  Status,
  IconInfo,
  StatusContainer,
} from "./styles";
import { verifyPermission } from "../../../services/auth";
import { Product } from "../../../models/Product";

const { Option } = Select;

interface IItem {
  category_id: number;
  category_name: string;
  product_id: number;
  price_sell: number;
  price_sell_py?: number;
  name: string;
  upload_url: string;
  quantity: number;
  weight: number;
  id?: number;
}
interface ITotalPriceQuantity {
  totalPrice: number;
  totalNF?: number;
  totalProducts: number;
  totalService: number;
  totalQuantity: number;
  totalBox?: number;
}
interface NewEditOrder {
  to_delivery: string;
  time_to_get?: string;
  discount: number;
  value_freight: number;
  value_ipi: number;
  value_icms_st: number;
  value_additional_taxes: number;
  value_interest: number;
  additional_value: number;
  comment: string;
}
interface IProps {
  visible: boolean;
  setVisible: Dispatch<SetStateAction<boolean>>;
  setShouldSearch: Dispatch<SetStateAction<boolean>>;
  store?: number | null;
  categories: CategoryModel[];
  order?: OrderModel;
  user: any;
  minOrder: number | undefined;
  setOrderToEdit: Dispatch<SetStateAction<OrderModel | undefined>>;
  typeOfLoad: number | undefined;
  setTypeOfLoad: Dispatch<SetStateAction<number | undefined>>;
  countryPY?: boolean;
}

const ModalCreateEditOrder: React.FC<IProps> = ({
  setVisible,
  visible,
  setShouldSearch,
  store,
  categories,
  order,
  user,
  minOrder,
  setOrderToEdit,
  typeOfLoad,
  setTypeOfLoad,
  countryPY,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [orderFreightEdit, setOrderFreightEdit] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [categorySelect, setCategorySelect] = useState<number>(0);
  const [storeFreight, setStoreFreight] = useState<number>(0);

  const [productsCategorySelect, setProductsCategorySelect] =
    useState<CategoryModel>();
  const [categoriesFilter, setCategoriesFilter] = useState<CategoryModel[]>([]);
  const [productsStock, setProductsStock] = useState<ProductStore[]>([]);
  const [handleProducts, setHandleProducts] = useState<IItem[]>([]);

  const [categoryPriceQuantity, setCategoryPriceQuantity] =
    useState<ITotalPriceQuantity>({
      totalPrice: 0,
      totalProducts: 0,
      totalService: 0,
      totalQuantity: 0,
    });

  const [totalPriceQuantity, setTotalPriceQuantity] =
    useState<ITotalPriceQuantity>({
      totalPrice: 0,
      totalNF: 0,
      totalProducts: 0,
      totalService: 0,
      totalQuantity: 0,
      totalBox: 0,
    });

  const [newEditOrder, setNewEditOrder] = useState<NewEditOrder>({
    to_delivery: "false",
    discount: 0,
    value_freight: 0,
    value_ipi: 0,
    value_icms_st: 0,
    value_additional_taxes: 0,
    value_interest: 0,
    additional_value: 0,
    comment: "",
  });

  const windowSize = useWindowSize();
  const [form] = Form.useForm();
  const [formQuantity] = Form.useForm();

  useEffect(() => {
    if (order) {
      if (order?.type_of_load) {
        setTypeOfLoad(order?.type_of_load);
      } else {
        setTypeOfLoad(undefined);
      }
    }
  }, [order]);

  // Chamadas de apis.

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);

      try {
        const {
          data: {
            result: { freight },
          },
        } = await api.get(`/value_freight/${store}`);

        setStoreFreight(freight);
      } catch (error) {
        //@ts-ignore
        const _description = error.message;
        notification.error({
          message: "Oops! Erro ao listar o frete.",
          description: error
            ? _description
            : "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde!",
          duration: 5,
        });
      }

      try {
        const urlFactory = countryPY
          ? `/products_store/store/paraguay/${store}?stockProducts=false&load_factory_info=true`
          : `/products_store/store/${store}?stockProducts=false&load_factory_info=true`;
        const {
          data: { content: stockContent },
        } = await api.get(urlFactory);

        setProductsStock(stockContent);

        const _categories = order
          ? handleFilterCategoryByLoad(order.type_of_load, categories)
          : handleFilterCategoryByLoad(typeOfLoad, categories);

        setCategoriesFilter(_categories);

        if (order) {
          setProductsCategorySelect(
            categories.find(
              (_category) =>
                _category.id === order.orderItems[0].products.category_id
            )
          );
        } else if (typeOfLoad === 1) {
          const _categorySelect = categories.find(
            (_category) => _category.name === "acompanhamentos"
          );
          _categorySelect
            ? setProductsCategorySelect(_categorySelect)
            : setProductsCategorySelect(_categories[0]);
        } else {
          const _categorySelect = categories.find(
            (_category) => _category.name === "sorvetes"
          );

          _categorySelect
            ? setProductsCategorySelect(_categorySelect)
            : setProductsCategorySelect(_categories[0]);
        }

        if (order) {
          order.orderItems.forEach(
            (_orderItem, index) =>
              (handleProducts[index] = {
                category_id: _orderItem?.products?.category_id || 0,
                category_name: _orderItem?.products?.category?.name || "",
                product_id: _orderItem.product_id,
                price_sell: +_orderItem.price_unit,
                name: _orderItem.products.name || "",
                upload_url: _orderItem.products.upload_url || "",
                quantity: +_orderItem.quantity,
                weight: +(_orderItem?.products?.weight || 0),
                id: _orderItem.id,
              })
          );
          setNewEditOrder({
            to_delivery: order.to_delivery.toString(),
            time_to_get: order.time_to_get || "",
            discount: order.discount,
            value_freight: +(order.value_freight || 0),
            value_ipi: order.value_ipi || 0,
            value_icms_st: order.value_icms_st || 0,
            value_additional_taxes: order.value_additional_taxes || 0,
            value_interest: order.value_interest || 0,
            additional_value: order.additional_value,
            comment: order.comment,
          });
          console.log(order);
          form.setFieldsValue({ comment: order.comment });
        }
      } catch (error) {
        //@ts-ignore
        const _description = error.message;

        notification.error({
          message: "Oops! Erro ao listar produtos do estoque.",
          description: error
            ? _description
            : "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde!",
          duration: 5,
        });
      } finally {
        setLoading(false);
      }
    };

    if (store && visible) {
      fetchData();
    }
  }, [store, visible]);

  useEffect(() => {
    if (order) {
      setCategorySelect(
        categories?.find(
          (_category) =>
            _category.id === order.orderItems[0].products.category_id
        )?.id || 0
      );
    } else if (typeOfLoad === 1) {
      setCategorySelect(
        categories.find((category) => category.name === "acompanhamentos")
          ?.id || 0
      );
    } else {
      setCategorySelect(
        categories.find((category) => category.name === "sorvetes")?.id || 0
      );
    }
  }, [categories, typeOfLoad, order]);

  //Cálculo de totais e alterações do productlist.

  useEffect(() => {
    const calcPriceQuantity = () => {
      setProductsCategorySelect(
        categories.find((_category) => _category.id === categorySelect)
      );

      currentStep !== 3 &&
        (handleProducts.length ? setCurrentStep(2) : setCurrentStep(1));

      const _totalPrice = handleProducts
        .filter((item) => item.category_id === categorySelect)
        .reduce(
          (totalPrice, reducePrice) =>
            totalPrice + +(reducePrice.price_sell * reducePrice.quantity),
          0
        );
      setCategoryPriceQuantity({
        totalPrice: _totalPrice,
        totalProducts: _totalPrice - _totalPrice * 0.25,
        totalService: _totalPrice * 0.25,

        totalQuantity: handleProducts
          .filter((item) => item.category_id === categorySelect)
          .reduce(
            (totalQuantity, reduceQuantity) =>
              totalQuantity + reduceQuantity.quantity,
            0
          ),
      });

      let _totalOrderValue = handleProducts.reduce(
        (totalPrice, reducePrice) =>
          totalPrice + +(reducePrice.price_sell * reducePrice.quantity),
        0
      );

      setTotalPriceQuantity({
        totalPrice:
          _totalOrderValue +
          (newEditOrder.to_delivery === "true"
            ? 0
            : +newEditOrder.value_freight) +
          (+newEditOrder.value_icms_st || 0) +
          (+newEditOrder.value_ipi || 0) +
          (+newEditOrder.value_additional_taxes || 0) +
          (+newEditOrder.value_interest || 0) +
          (+newEditOrder.additional_value || 0) -
          +(+newEditOrder.discount || 0),

        totalNF:
          _totalOrderValue * 0.75 +
          (newEditOrder.to_delivery === "true"
            ? 0
            : +newEditOrder.value_freight) +
          (+newEditOrder.value_icms_st || 0) +
          (+newEditOrder.value_ipi || 0) +
          (+newEditOrder.value_additional_taxes || 0) +
          (+newEditOrder.value_interest || 0) +
          (+newEditOrder.additional_value || 0) -
          +(+newEditOrder.discount || 0),

        totalProducts: _totalOrderValue * 0.75,

        totalService: _totalOrderValue * 0.25,

        totalQuantity: handleProducts.reduce(
          (totalQuantity, reduceQuantity) =>
            totalQuantity + reduceQuantity.quantity,
          0
        ),
        totalBox: handleProducts
          .filter(
            (_product) =>
              _product.category_name === "sorvetes" ||
              _product.category_name === "acai"
          )
          .reduce((totalBox, reduceBox) => totalBox + reduceBox.quantity, 0),
      });
    };
    if (categorySelect && visible && store) {
      calcPriceQuantity();
    }
  }, [categorySelect, handleProducts, visible, store, loading, newEditOrder]);

  useEffect(() => {
    const calcFreight = () => {
      let _totalOrderValue = handleProducts.reduce(
        (totalPrice, reducePrice) =>
          totalPrice + reducePrice.price_sell * reducePrice.quantity,
        0
      );
      let freightOrderValue = countryPY
        ? _totalOrderValue * (+storeFreight / 100)
        : _totalOrderValue * 0.75 * (+storeFreight / 100);
      setNewEditOrder((oldValues) => ({
        ...oldValues,
        value_freight: freightOrderValue,
      }));
    };

    if (orderFreightEdit && order) {
      setOrderFreightEdit(false);
    } else {
      calcFreight();
    }
  }, [handleProducts]);

  const onChangeQuantity = ({ target: { value } }, record) => {
    if (value < 0) {
      return notification.warning({
        message: `A quantidade de itens não deve ser negativa`,
        duration: 5,
      });
    }
    setHasError(false);
    let _newListProduct = handleProducts.filter(
      (_item) => _item.product_id !== record.id
    );

    setHandleProducts(_newListProduct);

    if (!value || +value === 0) {
      return;
    }
    setHandleProducts((oldArray) => [
      ...oldArray,
      {
        category_id: +record.category_id,
        category_name:
          categories.find((category) => category.id === record.category_id)
            ?.name || "Categoria não encontrada",
        product_id: +record.id,
        price_sell: order
          ? +record.price_unit
          : countryPY
          ? +record.price_sell_py
          : +record.price_sell,
        name: record.name,
        upload_url: record.upload_url,
        quantity: +value,
        weight: +record.weight,
        id: order?.orderItems.find(
          (orderItem) => orderItem.product_id === record.id
        )?.id,
      },
    ]);
  };

  const deleteItem = (ItemId: number) => {
    let _newListProduct = handleProducts.filter(
      (_item) => _item.product_id !== ItemId
    );

    formQuantity.resetFields();
    setHandleProducts(_newListProduct);
  };

  //Finalização de novo pedido e edição de pedidos.

  const changeForm = (value: any, formValue: any) => {
    setNewEditOrder((oldValues) => ({
      ...oldValues,
      [formValue]: value,
    }));
  };

  const payloadContruct = async () => {
    await form.validateFields();

    let orderItems: {
      product_id: number;
      quantity: number;
      products?: {
        name?: string;
      };
      price_unit?: string;
      id?: number;
    }[] = [];

    order
      ? handleProducts.forEach(
          (product, index) =>
            (orderItems[index] = {
              product_id: product.product_id,
              products: {
                name: product.name,
              },
              quantity: product.quantity,
              price_unit: product.price_sell.toString(),
              id: product.id,
            })
        )
      : handleProducts.forEach(
          (product, index) =>
            (orderItems[index] = {
              product_id: product.product_id,
              quantity: product.quantity,
              price_unit: product.price_sell.toString(),
            })
        );

    return {
      store_id: store,
      user_id: user?.id,
      comment: newEditOrder.comment,
      to_delivery: newEditOrder.to_delivery === "true" ? true : false,
      time_to_get: newEditOrder.to_delivery ? newEditOrder.time_to_get : "",
      total: +totalPriceQuantity.totalPrice.toFixed(2),
      total_nf: +(totalPriceQuantity.totalNF || 0).toFixed(2),
      additional_value: newEditOrder.additional_value,
      value_icms_st: newEditOrder.value_icms_st,
      value_ipi: newEditOrder.value_ipi,
      value_additional_taxes: newEditOrder.value_additional_taxes,
      value_interest: newEditOrder.value_interest,
      discount: newEditOrder.discount,
      franchise_tax: +totalPriceQuantity.totalService.toFixed(2),
      type_of_load: typeOfLoad,
      value_freight:
        newEditOrder.to_delivery === "true" ? 0 : newEditOrder.value_freight,
      orderItems,
    };
  };

  const validateQuantity = async () => {
    await formQuantity.validateFields();
    setCurrentStep(3);
  };

  const onFinish = async () => {
    let payload;
    payload = await payloadContruct();

    if (payload.discount && payload.discount > 0 && !payload.comment) {
      return notification.warning({
        message:
          "Por favor insira um comentário sobre o valor do desconto aplicado",
        duration: 5,
      });
    }

    if (order?.status === OrderStatus.CONFIRMADO) {
      payload = {
        ...payload,
        delivery_time: moment(form.getFieldValue("delivery_time")).format(
          "YYYY-MM-DD HH:mm:ss"
        ),
      };
    }

    if (!payload.time_to_get) {
      delete payload.time_to_get;
    }
    const totalBoxOfAcaiAndSorvete = handleProducts
      .filter(
        (item) =>
          item.category_name === "acai" || item.category_name === "sorvetes"
      )
      .reduce(
        (totalQuantity, reduceQuantity) =>
          totalQuantity + reduceQuantity.quantity,
        0
      );

    if (
      typeOfLoad === 0 &&
      minOrder &&
      totalBoxOfAcaiAndSorvete < minOrder &&
      !payload.to_delivery &&
      ((order && order?.status < OrderStatus.CONFIRMADO) || !order)
    ) {
      return notification.warning({
        message: `Necessário pedido de no mínimo ${minOrder} caixas de açaís ou sorvetes!`,
        description: `Restam ${
          minOrder - totalBoxOfAcaiAndSorvete
        } caixas para concluir o pedido.`,
        duration: 5,
      });
    }
    //Validação para que não seja aplicado um desconto maior que o valor total do pedido levando em consideração o desconto.
    if (+payload.total_nf + +payload.discount < +payload.discount) {
      return notification.warning({
        message: `Ops! Não é possível adicionar o desconto neste pedido.`,
        description: `O valor do desconto não deve ser maior que o valor total de NF`,
        duration: 5,
      });
    }

    Modal.confirm({
      title: "Enviar Pedido",
      content: "Gostaria de continuar o envio do pedido?",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      className: "modalConfirm",
      width: 500,
      async onOk() {
        try {
          const method = order ? "put" : "post";
          const url = countryPY
            ? order
              ? `/paraguay/orders/${order.id}`
              : "/paraguay/orders"
            : order
            ? `/orders/${order.id}`
            : "/orders";

          await api[method](url, {
            ...payload,
            status: order
              ? order.to_delivery
                ? 3
                : +order.status === OrderStatus.FINALIZADO
                ? 3
                : +order.status === OrderStatus.EM_ESTOQUE
                ? 5
                : +order.status === OrderStatus.ESTORNADO
                ? 6
                : +order.status + 1
              : 0,
          });

          notification.success({
            message: `Pedido enviado com sucesso`,
            duration: 5,
          });

          clearOrder();
          setOrderToEdit(undefined);
          setVisible(false);
          setShouldSearch(true);
        } catch (error) {
          //@ts-ignore
          const _description = error.message;

          notification.error({
            message: `Erro ao registrar pedido`,
            description: _description,
            duration: 5,
          });
        }
      },
    });
  };

  const cancelOrder = () => {
    Modal.confirm({
      title: "Gostaria de continuar e cancelar as alterações do pedido?",
      content: "Todas as modificações realizadas serão descartadas.",
      okText: "Sim",
      okType: "primary",
      cancelText: "Não",
      className: "modalConfirm",
      width: 500,
      async onOk() {
        clearOrder();
        setOrderToEdit(undefined);
        setVisible(false);
      },
    });
  };

  const clearOrder = async () => {
    if (order) {
      setCategorySelect(
        categories?.find(
          (_category) =>
            _category.id === order.orderItems[0].products.category_id
        )?.id || 0
      );
    } else if (typeOfLoad === 1) {
      setCategorySelect(
        categories.find((category) => category.name === "acompanhamentos")
          ?.id || 0
      );
    } else {
      setCategorySelect(
        categories.find((category) => category.name === "sorvetes")?.id || 0
      );
    }

    setCurrentStep(1);

    setHandleProducts([]);
    setCategoryPriceQuantity({
      totalPrice: 0,
      totalProducts: 0,
      totalService: 0,
      totalQuantity: 0,
    });

    setTotalPriceQuantity({
      totalPrice: 0,
      totalNF: 0,
      totalProducts: 0,
      totalService: 0,
      totalQuantity: 0,
      totalBox: 0,
    });

    setNewEditOrder({
      to_delivery: "false",
      discount: 0,
      value_freight: 0,
      value_ipi: 0,
      value_icms_st: 0,
      value_additional_taxes: 0,
      value_interest: 0,
      additional_value: 0,
      comment: "",
    });
    form.resetFields();
    formQuantity.resetFields();
  };

  //Cabeçalhos das tabelas

  const columnsItemsProducts = [
    {
      title: "Item",
      dataIndex: "name",
      key: "name",
      width: 30,
      defaultSortOrder: "ascend" as any,
      sorter: (productName1, productName2) =>
        productName1?.name?.localeCompare(productName2?.name),
      render: (text, record) => (
        <Tooltip
          title={
            countryPY
              ? record.name_py || "Produto sem nome."
              : record.name || "Produto sem nome."
          }
        >
          <span>
            {countryPY
              ? record.name_py || "Produto sem nome."
              : record.name || "Produto sem nome."}
          </span>
        </Tooltip>
      ),
    },
    {
      title: <Center>Situação</Center>,
      dataIndex: "situacao",
      key: "situacao",
      width: 13,
      render: (text) => (
        <Center>
          <StatusContainer>
            <Tooltip
              title={
                text === "A"
                  ? "Ativo"
                  : text === "I"
                  ? "Inativo"
                  : "Sem informações da fábrica"
              }
            >
              <Status active={text}>
                {text === "A" ? "Ativo" : text === "I" ? "Inativo" : "-"}
              </Status>
            </Tooltip>
            <Tooltip title={"O produto foi desativado pela fábrica!"}>
              {text === "I" ? <IconInfo /> : <></>}
            </Tooltip>
          </StatusContainer>
        </Center>
      ),
    },
    {
      title: <Center>Sugestão</Center>,
      dataIndex: "recommended_product",
      key: "recommended_product",
      responsive: ["md"] as any,
      width: 13,
      render: (text) => (
        <Tooltip title={text}>
          <Center>
            <span>{text}</span>
          </Center>
        </Tooltip>
      ),
    },
    {
      title: <Center>Peso</Center>,
      dataIndex: "weight",
      key: "weight",
      responsive: ["md"] as any,
      width: 13,
      render: (text) => (
        <Tooltip title={Number(text).toFixed(2)}>
          <Center>
            <span>{Number(text).toFixed(2)} KG</span>
          </Center>
        </Tooltip>
      ),
    },
    {
      title: <Center>Valor Total</Center>,
      dataIndex: order
        ? "price_unit"
        : countryPY
        ? "price_sell_py"
        : "price_sell",
      key: order ? "price_unit" : countryPY ? "price_sell_py" : "price_sell",
      width: 15,
      render: (text) => (
        <Tooltip
          title={
            text
              ? `Valor total: ${countryPY ? `US$` : `R$`}${text}`
              : "Valores não informados"
          }
        >
          <Center>
            <span>
              {countryPY ? `US$` : `R$`} {text}
            </span>
          </Center>
        </Tooltip>
      ),
    },
    ...(countryPY
      ? []
      : [
          {
            title: <Center>Valor Produto</Center>,
            dataIndex: order ? "price_unit" : "price_sell",
            key: order ? "price_unit" : "price_sell",
            width: 15,
            responsive: ["md"] as any,
            render: (text) => (
              <Tooltip title={`Valor produto: R$ ${(text * 0.75).toFixed(2)}`}>
                <Center>
                  <span>R$ {(text * 0.75).toFixed(2)}</span>
                </Center>
              </Tooltip>
            ),
          },
          {
            title: <Center>Valor Serviço</Center>,
            dataIndex: order ? "price_unit" : "price_sell",
            key: order ? "price_unit" : "price_sell",
            width: 15,
            responsive: ["md"] as any,
            render: (text) => (
              <Tooltip title={`Valor Serviço: R$ ${(text * 0.25).toFixed(2)}`}>
                <Center>
                  <span>R$ {(text * 0.25).toFixed(2)}</span>
                </Center>
              </Tooltip>
            ),
          },
        ]),

    {
      title: <Center>Estoque</Center>,
      dataIndex: "quantity",
      key: "quantity",
      width: 12,
      render: (text) => (
        <Tooltip title={text}>
          <Center>
            <span>{text}</span>
          </Center>
        </Tooltip>
      ),
    },
    {
      title: <Center>Disponível para pedido</Center>,
      dataIndex: "saldoFisicoTotal",
      key: "saldoFisicoTotal",
      width: 16,
      render: (text, record) => (
        <Tooltip
          title={
            record.situacao === "A"
              ? text
              : record.situacao === "I"
              ? "Inativo"
              : "Sem informações da fábrica"
          }
        >
          <Center>
            <span>
              {record.situacao === "A"
                ? text
                : record.situacao === "I"
                ? "Inativo"
                : "-"}
            </span>
          </Center>
        </Tooltip>
      ),
    },
    {
      title: <Center>Qtd</Center>,
      dataIndex: "Ação",
      key: "Ação",
      width: 15,
      render: (text, record) => (
        <Center>
          <Form form={formQuantity}>
            <Form.Item
              name={`${record.id}Quantity`}
              rules={[
                {
                  pattern: /^\d+$/,
                  message: "Qtd Inválida",
                },
                {
                  validator: (_, value) => {
                    return !value || value < 1001
                      ? Promise.resolve()
                      : Promise.reject("Qtd Inválida");
                  },
                },
              ]}
            >
              <QuantityInput
                type="number"
                defaultValue={
                  handleProducts?.find(
                    (product) =>
                      product.product_id === record.id &&
                      product.category_id === record.category_id
                  )?.quantity
                }
                onChange={(value) => onChangeQuantity(value, record)}
                disabled={
                  order?.status === OrderStatus.EM_ESTOQUE ||
                  record.situacao === "I" ||
                  (record?.saldoFisicoTotal && record?.saldoFisicoTotal === 0)
                }
              ></QuantityInput>
            </Form.Item>
          </Form>
        </Center>
      ),
    },
  ];

  const selectedProducts = [
    {
      title: "Quantidade",
      dataIndex: "quantity",
      key: "quantity",
      render: (text) => <span>{text} Caixas</span>,
    },
    {
      title: "Nome",
      dataIndex: "name",
      key: "name",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Peso",
      dataIndex: "weight",
      key: "weight",
      render: (text) => <span>{Number(text).toFixed(2)} KG</span>,
    },
    {
      title: "Valor Total",
      dataIndex: "price_sell",
      key: "price_sell",
      render: (text) => (
        <span>
          {countryPY ? `US$` : `R$`} {currencyFormater(text)}
        </span>
      ),
    },
    ...(countryPY
      ? []
      : [
          {
            title: "Valor Produtos",
            render: (_, record) => (
              <span>R$ {currencyFormater(record.price_sell * 0.75)}</span>
            ),
          },
          {
            title: "Valor Serviço",
            render: (_, record) => (
              <span>R$ {currencyFormater(record.price_sell * 0.25)}</span>
            ),
          },
        ]),
  ];

  const timesToGetOrder = [
    "8:30",
    "9:30",
    "10:30",
    "11:30",
    "13:30",
    "14:30",
    "15:30",
    "16:30",
  ];

  const responsiveTable = (height) => {
    if (height <= 620) {
      return 270;
    }
    if (height <= 768) {
      return 325;
    }
    if (height <= 850) {
      return 400;
    }
    return 500;
  };

  const handlerFilterTypeOfLoad = (
    _type: number | undefined,
    _products?: Product[]
  ): Product[] | undefined => {
    if (typeof _type !== "number") return _products;
    if (!_products) return undefined;
    return _products.filter((item) =>
      typeof item.type_cargo === "number"
        ? item.type_cargo === +_type
        : _type === 1
        ? true
        : false
    );
  };

  const handleFilterCategoryByLoad = (
    _typeLoad: number | undefined,
    _categories: CategoryModel[]
  ) => {
    let categoriesIds;

    if (order) {
      categoriesIds = order?.orderItems.map(
        (item) => item?.products?.category?.id
      );
    } else {
      categoriesIds = _categories
        .filter((item) =>
          _typeLoad === 0
            ? item?.products?.some((_product) => _product.type_cargo === 0)
            : item?.products?.some((_product) => _product.type_cargo !== 0)
        )
        .map((item) => item.id);
    }

    categoriesIds = [...new Set(categoriesIds)];

    const response = _categories.filter((_cat) =>
      categoriesIds.some((categoriesId) => categoriesId === _cat.id)
    );

    if (order) {
      return response;
    } else {
      return response
        .filter((category) => {
          let hasProduct = category.products?.filter((product) =>
            _typeLoad === 1
              ? product?.type_cargo === 1
              : product?.type_cargo !== 1
          ).length;

          return !!hasProduct;
        })
        .filter((_category) => _category.products?.length);
    }
  };

  return (
    <Container
      title="Novo Pedido"
      visible={visible}
      centered
      destroyOnClose={true}
      width={"90%"}
      closable={false}
      onCancel={() => setVisible(false)}
      maskClosable={false}
      footer={
        <Footer>
          <TotalFooter>
            <Total>
              <span>
                Total{" "}
                <Tooltip
                  title={
                    "Valor Total que será pago pelo franqueado considerando produtos, serviços, impostos e frete"
                  }
                >
                  <IconInfo />
                </Tooltip>
              </span>
              <span className="totalCalc">
                {countryPY ? `US$` : `R$`}{" "}
                {currencyFormater(totalPriceQuantity.totalPrice)}
              </span>
            </Total>
            {!countryPY && (
              <>
                <Total>
                  <span>
                    Total Nota{" "}
                    <Tooltip
                      title={
                        "Valor da Nota Fiscal de produtos considerando impostos, frete e descontos."
                      }
                    >
                      <IconInfo />
                    </Tooltip>
                  </span>
                  <span className="totalCalc">
                    R$ {currencyFormater(totalPriceQuantity.totalNF)}
                  </span>
                </Total>
                <Total>
                  <span>Total Produtos</span>
                  <span className="totalCalc">
                    R$ {currencyFormater(totalPriceQuantity.totalProducts)}
                  </span>
                </Total>
                <Total>
                  <span>Total Serviços</span>
                  <span className="totalCalc">
                    R$ {currencyFormater(totalPriceQuantity.totalService)}
                  </span>
                </Total>
              </>
            )}

            {currentStep === 3 && (
              <>
                <Total>
                  <span>Total de </span>
                  <span className="totalCalc">
                    {totalPriceQuantity.totalQuantity} Itens
                  </span>
                </Total>
                <Total>
                  <span>Total de </span>
                  <span className="totalCalc">
                    {totalPriceQuantity.totalBox} Caixas
                  </span>
                </Total>
              </>
            )}
          </TotalFooter>
          <ButtonsContainer>
            <Button
              onClick={() => {
                currentStep !== 3
                  ? cancelOrder()
                  : setCurrentStep(currentStep - 1);
              }}
            >
              {currentStep !== 3 ? "Cancelar" : "Revisar Pedido"}
            </Button>
            <ButtonSave
              disabled={!!!handleProducts.length || hasError}
              onClick={() => {
                currentStep === 3 ? onFinish() : validateQuantity();
              }}
            >
              {currentStep === 1 || currentStep === 2
                ? "Continuar Pedido"
                : "Finalizar Pedido"}
            </ButtonSave>
          </ButtonsContainer>
        </Footer>
      }
    >
      <ContentCategories>
        <span className="spanText">Progresso do Pedido</span>
        {currentStep !== 3 && (
          <div>
            {order?.type_of_load === 0 || typeOfLoad === 0 ? (
              <span className="loadType">Carga Gelada </span>
            ) : order?.type_of_load === 1 || typeOfLoad === 1 ? (
              <span className="loadType">Carga Seca </span>
            ) : (
              <span className="loadType">Carga Mista </span>
            )}
            {loading ? (
              <></>
            ) : (
              <Select
                placeholder="Categorias"
                defaultValue={categorySelect}
                value={categorySelect}
                onChange={(id) => {
                  setCategorySelect(+id);
                  setCategoryPriceQuantity({
                    totalPrice: 0,
                    totalProducts: 0,
                    totalService: 0,
                    totalQuantity: 0,
                  });
                }}
              >
                {categoriesFilter?.map((categorie) => (
                  <Option
                    key={categorie.id}
                    value={categorie.id}
                    style={{ textTransform: "uppercase" }}
                  >
                    <TextSelect>
                      <span>{categorie.name}</span>
                      <span className="itemCount">
                        {
                          handleProducts.filter(
                            (_item) => _item.category_id === categorie.id
                          ).length
                        }
                      </span>
                    </TextSelect>
                  </Option>
                ))}
              </Select>
            )}
          </div>
        )}
      </ContentCategories>

      <Form layout="vertical" initialValues={{ remember: false }}>
        <StepperContainer>
          <Steps currentPage={currentStep}>
            <StepIcon className="step-icon1" />
            <div className="step-line1" />
            <StepIcon className="step-icon2" />
            <div className="step-line2" />
            <StepIcon className="step-icon3" />
          </Steps>
        </StepperContainer>
      </Form>
      {currentStep !== 3 ? (
        <>
          {loading ? (
            <Centralizer>
              <Spinner />
            </Centralizer>
          ) : (
            <ContainerItens>
              <Table
                columns={columnsItemsProducts}
                dataSource={
                  order
                    ? handlerFilterTypeOfLoad(
                        order.type_of_load,
                        productsCategorySelect?.products
                      )
                        ?.map((entity) => ({
                          ...entity,
                          key: entity.id,
                          quantity:
                            productsStock?.find(
                              (_productStockID) =>
                                _productStockID?.product_id === entity.id
                            )?.quantity || 0,
                          price_unit: order.orderItems.find(
                            (_productStockID) =>
                              _productStockID?.product_id === entity.id
                          )?.price_unit,
                          situacao:
                            productsStock.find(
                              (_productStockID) =>
                                _productStockID?.product_id === entity.id
                            )?.product?.factory_info?.situacao || "-",
                          saldoFisicoTotal: productsStock.find(
                            (_productStockID) =>
                              _productStockID?.product_id === entity.id
                          )?.product?.factory_info?.estoque?.saldoFisicoTotal,
                        }))
                        .filter((_productID) =>
                          order?.orderItems.some(
                            (productItemEdit) =>
                              productItemEdit.product_id === _productID.id
                          )
                        )
                    : handlerFilterTypeOfLoad(
                        typeOfLoad,
                        productsCategorySelect?.products
                      )?.map((entity) => ({
                        ...entity,
                        key: entity.id,
                        quantity:
                          productsStock?.find(
                            (_productStockID) =>
                              _productStockID?.product_id === entity.id
                          )?.quantity || 0,
                        situacao:
                          productsStock.find(
                            (_productStockID) =>
                              _productStockID?.product_id === entity.id
                          )?.product?.factory_info?.situacao || "-",
                        saldoFisicoTotal: productsStock.find(
                          (_productStockID) =>
                            _productStockID?.product_id === entity.id
                        )?.product?.factory_info?.estoque?.saldoFisicoTotal,
                      }))
                }
                scroll={{
                  y: responsiveTable(windowSize.height),
                }}
                pagination={false}
              />
              <ListItens>
                <ListText>
                  <span className="textItens">Lista de Pedidos</span>
                </ListText>
                <ContainerCards>
                  {handleProducts.map(
                    (itens) =>
                      itens.category_id === categorySelect && (
                        <React.Fragment key={itens.product_id}>
                          <ItemCard>
                            <ItemText>
                              <span className={"itemName"}>{itens.name}</span>
                              <span className={"itemQuantity"}>
                                Quantidade: {itens.quantity}
                              </span>
                            </ItemText>

                            <ItemImage
                              src={itens.upload_url || notFound}
                            ></ItemImage>
                          </ItemCard>
                          {!(order?.status === OrderStatus.EM_ESTOQUE) && (
                            <DeleteItem>
                              <span
                                onClick={() => deleteItem(itens.product_id)}
                              >
                                Remover
                              </span>
                            </DeleteItem>
                          )}
                        </React.Fragment>
                      )
                  )}
                </ContainerCards>
                <ContainerItensInfo>
                  <div className="totalInfo">
                    <span>Total em {productsCategorySelect?.name}</span>
                    <span className="totalCalc">
                      {currencyFormater(categoryPriceQuantity?.totalPrice)}
                    </span>
                  </div>

                  <div className="totalInfo">
                    <span>Total de {productsCategorySelect?.name}</span>
                    <span className="totalCalc">
                      {categoryPriceQuantity?.totalQuantity}
                    </span>
                  </div>
                </ContainerItensInfo>
              </ListItens>
            </ContainerItens>
          )}
        </>
      ) : (
        <Content>
          <Form
            layout="vertical"
            initialValues={{ remember: false }}
            form={form}
          >
            <Row gutter={8}>
              <Col sm={6} xs={24}>
                <Form.Item label="Loja Selecionada:">
                  <GetStore
                    disabled={true}
                    defaultValue={store}
                    handleChange={() => {}}
                  />
                </Form.Item>
              </Col>
              <Col sm={6} xs={24}>
                <Form.Item label="Pedido para retirada?" name="to_delivery">
                  <Select
                    placeholder="Delivery"
                    defaultValue={newEditOrder.to_delivery}
                    options={[
                      { value: "true", label: "Sim" },
                      { value: "false", label: "Não" },
                    ]}
                    onChange={(value) =>
                      changeForm(value.toString(), "to_delivery")
                    }
                    disabled={order?.status === OrderStatus.EM_ESTOQUE}
                  ></Select>
                </Form.Item>
              </Col>
              {newEditOrder.to_delivery === "true" && (
                <Col sm={6} xs={24}>
                  <Form.Item
                    label="Horário:"
                    name="time_to_get"
                    rules={[
                      {
                        validator: () => {
                          if (
                            newEditOrder.to_delivery &&
                            newEditOrder.time_to_get
                          ) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject("Campo Obrigatório");
                          }
                        },
                      },
                    ]}
                  >
                    <Select
                      placeholder="Horário"
                      defaultValue={newEditOrder.time_to_get}
                      onChange={(value) => changeForm(value, "time_to_get")}
                      disabled={order?.status === OrderStatus.EM_ESTOQUE}
                    >
                      {timesToGetOrder.map((time) => (
                        <Option key={time} value={time}>
                          {time}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              )}

              {verifyPermission("orders.edit") && (
                <Col sm={3} xs={24}>
                  <Form.Item label="Desconto:" name="discount">
                    <MonetaryInput
                      defaultValue={+newEditOrder.discount}
                      getValue={(value) => changeForm(value, "discount")}
                      currency={countryPY ? "USD" : "BRL"}
                    />
                  </Form.Item>
                </Col>
              )}

              {!countryPY && order && verifyPermission("orders.edit") && (
                <Col sm={3} xs={24}>
                  <Form.Item label="Valor ICMS ST:" name="value_icms_st">
                    <MonetaryInput
                      defaultValue={+newEditOrder.value_icms_st}
                      getValue={(value) => changeForm(value, "value_icms_st")}
                    />
                  </Form.Item>
                </Col>
              )}

              {!countryPY && order && verifyPermission("orders.edit") && (
                <Col sm={3} xs={24}>
                  <Form.Item label="Valor IPI:" name="value_ipi">
                    <MonetaryInput
                      defaultValue={+newEditOrder.value_ipi}
                      getValue={(value) => changeForm(value, "value_ipi")}
                    />
                  </Form.Item>
                </Col>
              )}

              {!countryPY && order && verifyPermission("orders.edit") && (
                <Col sm={3} xs={24}>
                  <Form.Item label="Valor Juros:" name="value_interest">
                    <MonetaryInput
                      defaultValue={+newEditOrder.value_interest}
                      getValue={(value) => changeForm(value, "value_interest")}
                    />
                  </Form.Item>
                </Col>
              )}

              {!countryPY && order && verifyPermission("orders.edit") && (
                <Col sm={3} xs={24}>
                  <Form.Item
                    label="Valor Demais Impostos:"
                    name="value_additional_taxes"
                  >
                    <MonetaryInput
                      defaultValue={+newEditOrder.value_additional_taxes}
                      getValue={(value) =>
                        changeForm(value, "value_additional_taxes")
                      }
                    />
                  </Form.Item>
                </Col>
              )}

              {newEditOrder.to_delivery === "false" && (
                <Col sm={3} xs={24}>
                  <Form.Item
                    label={`Valor de Frete: ${storeFreight}%`}
                    name="value_freight"
                  >
                    <MonetaryInput
                      disabled={
                        order && verifyPermission("orders.edit") ? false : true
                      }
                      readOnly={
                        order && verifyPermission("orders.edit") ? false : true
                      }
                      defaultValue={newEditOrder.value_freight}
                      getValue={(value) => changeForm(value, "value_freight")}
                      currency={countryPY ? "USD" : "BRL"}
                    />
                  </Form.Item>
                </Col>
              )}

              {verifyPermission("orders.edit") && (
                <Col sm={3} xs={24}>
                  <Form.Item label="Valor adicional:" name="additional_value">
                    <MonetaryInput
                      defaultValue={+newEditOrder.additional_value}
                      getValue={(value) =>
                        changeForm(value, "additional_value")
                      }
                      currency={countryPY ? "USD" : "BRL"}
                    />
                  </Form.Item>
                </Col>
              )}
              {!countryPY && (
                <Col sm={3} xs={24}>
                  <Form.Item label="Total de serviços:" name="franchise_tax">
                    <MonetaryInput
                      defaultValue={+(totalPriceQuantity.totalService || 0)}
                      disabled
                    />
                  </Form.Item>
                </Col>
              )}
              <Col
                sm={order?.status === OrderStatus.CONFIRMADO ? 6 : 12}
                xs={24}
              >
                <Form.Item label="Comentário:" name="comment">
                  <TextAreaComment
                    style={{ height: 100 }}
                    placeholder="Deixe algum comentário relacionado ao seu pedido"
                    defaultValue={newEditOrder.comment}
                    rows={5}
                    onChange={({ target: { value } }) =>
                      setNewEditOrder((oldValues) => ({
                        ...oldValues,
                        comment: value,
                      }))
                    }
                    disabled={order?.status === OrderStatus.EM_ESTOQUE}
                  />
                </Form.Item>
              </Col>
              {order?.status === OrderStatus.CONFIRMADO && (
                <Col sm={6} xs={24}>
                  <Form.Item
                    label="Previsão de entrega"
                    name="delivery_time"
                    rules={[{ required: true, message: "Campo obrigatório" }]}
                  >
                    <DatePicker
                      showTime
                      size="small"
                      locale={locale}
                      placeholder="Selecione a data"
                      disabledDate={(current) =>
                        current && current < moment().startOf("day")
                      }
                    />
                  </Form.Item>
                </Col>
              )}
            </Row>
          </Form>
          <Divider>
            <span>Lista de pedidos</span>
            <LineDivider />
          </Divider>

          <Table
            columns={selectedProducts}
            dataSource={handleProducts}
            rowKey={(entity: any) => entity.id}
            scroll={{ y: 200 }}
            pagination={false}
            className="orderList"
          />
        </Content>
      )}
    </Container>
  );
};

export default ModalCreateEditOrder;
