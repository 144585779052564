import styled from "styled-components";

import { Modal as ModalAnt, Col as ColAnt } from "antd";

export const Modal = styled(ModalAnt)`
  .ant-modal-body {
    max-height: 350px;
    overflow-y: scroll;
    padding: 0 10px 10px 10px;
  }
`;

export const Col = styled(ColAnt)``;
