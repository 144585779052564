export const getIpi = (value) => {
  switch (value) {
    case "00":
      return "50";
    case "01":
      return "51";
    case "02":
      return "52";
    case "03":
      return "53";
    case "04":
      return "54";
    case "05":
      return "55";
    case "49":
      return "99";
    case "50":
      return "00";
    case "51":
      return "01";
    case "52":
      return "02";
    case "53":
      return "03";
    case "54":
      return "04";
    case "55":
      return "05";
    case "99":
      return "49";
    default:
      return undefined;
  }
};
