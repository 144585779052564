import styled from "styled-components";

import { EditAlt } from "../../styles/Icons";

import { Select as SelectAnt } from "antd";

const { Option: OptionAnt } = SelectAnt;

export const Select = styled(SelectAnt)`
  text-transform: capitalize;
  .pencilIcon {
    display: none;
  }
`;

export const Option = styled(OptionAnt)``;

export const OptionInfoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-transform: capitalize;
  width: 100%;
`;

export const OptionLabel = styled.label`
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 90%;
`;

export const PencilIcon = styled(EditAlt)`
  width: 23px;
  height: 23px;
  fill: black;
  z-index: 10;
  :hover {
    fill: orange;
  }
  @media only screen and (max-width: 578px) {
    width: 18px;
    height: 18px;
  }
`;
