import styled, { css } from "styled-components";

import { DeliveryRouteStatus } from "../../../models/enums/DeliveryRoutes";

import { ReactComponent as PrimeiroPedidoIcon } from "../../../assets/svg/primeiro_pedido.svg";

import {
  MoreHoriz,
  InfoCircle,
  Star,
  StarEdit,
  StarOff,
  Image,
  TruckOrder,
  Package,
} from "../../../styles/Icons";

import {
  Table as TableAnt,
  Menu as MenuAnt,
  Dropdown as DropdownAnt,
} from "antd";

export const Center = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Table = styled(TableAnt)`
  height: 100%;

  .ant-table-container {
    border-radius: 5px;
    border: 1px solid var(--gray-75);
    overflow-y: scroll;

    ::-webkit-scrollbar {
      width: 0.3rem;
    }

    ::-webkit-scrollbar-track {
      background: var(--white-50);
      border-radius: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: var(--gray-300);
      border-radius: 5px;
    }
  }

  .ant-table-tbody > tr.ant-table-row:hover > td {
    background: none;
  }

  .ant-table-tbody > tr > td {
    padding: 1rem 0.4rem;
    color: var(--blue-999);
    font-weight: 400;
    font-size: 1rem;
    overflow: hidden;
    overflow-wrap: unset;
    white-space: nowrap;
    text-overflow: ellipsis;
    @media only screen and (max-width: 1400px) {
      font-size: 0.8rem;
    }
    @media only screen and (max-width: 575px) {
      padding: 0.15rem;
      font-size: 0.7rem;
    }
  }

  .ant-table-thead > tr > th {
    padding: 1rem 0.4rem;
    background: #fdfdfd;
    font-size: 1rem;
    font-weight: 500;
    color: var(--blue-900);
    text-overflow: ellipsis;
    @media only screen and (max-width: 1400px) {
      font-size: 0.8rem;
    }
    @media only screen and (max-width: 575px) {
      padding: 0.15rem;
      font-size: 0.7rem;
    }
  }
  .totalPay {
    & > td:first-child {
      border-left: 5px solid #71ea3f;
    }
    &:hover {
      background: #bbe88ed9;
    }
  }
  .partialPay {
    & > td:first-child {
      border-left: 5px solid orange;
    }
    &:hover {
      background: #de751c78;
    }
  }
  .emptyPay {
    & > td:first-child {
      border-left: 5px solid red;
    }
    &:hover,
    :active {
      background: #e4737952 !important;
    }
  }
  .default {
    &:hover,
    :active {
      background: #f6f6f6 !important;
    }
  }
`;

export const Menu = styled(MenuAnt)`
  li {
    border-bottom: 1px solid var(--gray-25);
  }
`;

export const Dropdown = styled(DropdownAnt)`
  li {
    padding: 8px;
  }
`;

interface IOrderStatus {
  order_status: number;
  to_delivery: boolean;
}

export const StatusInfo = styled.b<IOrderStatus>`
  font-size: 1rem;
  font-weight: 400;
  overflow: hidden;
  hyphens: auto;
  word-break: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  ${({ order_status, to_delivery }) => {
    if (to_delivery && order_status < 4) {
      return css`
        border: 1px solid var(--chain-gang-grey);
        border-radius: 0.4rem;

        color: var(--chain-gang-grey);
        padding: 0.1rem;
      `;
    } else {
      if (order_status === 0) {
        return css`
          color: var(--night-shift);
        `;
      } else if (order_status === 1) {
        return css`
          color: var(--clear-chill);
        `;
      } else if (order_status === 2) {
        return css`
          color: var(--goldenrod-tea);
        `;
      } else if (order_status === 3) {
        return css`
          color: var(--midori-green);
        `;
      } else if (order_status === 4) {
        return css`
          color: var(--maroon);
        `;
      } else if (order_status === 5) {
        return css`
          color: var(--chain-gang-grey);
        `;
      } else if (order_status === 6) {
        return css`
          color: var(--chanterelle);
        `;
      }
    }
  }};
  @media only screen and (max-width: 1400px) {
    font-size: 0.75rem;
  }
`;

const IconCSS = css`
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
  @media only screen and (max-width: 575px) {
    width: 0.8rem;
    height: 0.8rem;
  }
`;

export const MoreIcon = styled(MoreHoriz)`
  ${IconCSS}
  color: var(--orange-350);
`;

export const InfoIcon = styled(InfoCircle)`
  ${IconCSS}
  color: var(--green-400);
`;

export const ImageIcon = styled(Image)`
  ${IconCSS}
  color: var(--gray-200);
  margin-left: 5%;
`;

export const StarIcon = styled(Star)`
  ${IconCSS}
  color: var(--golden-beryl-yellow);
`;

export const BlingIcon = styled.img`
  width: 1rem;
`;

export const StarIconComment = styled(StarEdit)`
  ${IconCSS}
  color: var(--golden-beryl-yellow);
`;

export const StarEmpty = styled(StarOff)`
  ${IconCSS}
  color: var(--fading-sunset);
`;

interface IDelivery {
  status: number;
}

export const DeliveryIcon = styled(TruckOrder)<IDelivery>`
  ${IconCSS}
  ${({ status }) => {
    if (
      status === DeliveryRouteStatus.ATRIBUIDA ||
      status === DeliveryRouteStatus.ROTEIRIZADA ||
      status === DeliveryRouteStatus.CRIADA
    ) {
      return css`
        color: var(--golden-beryl-yellow);
      `;
    }

    if (status === DeliveryRouteStatus.EM_ROTA) {
      return css`
        color: var(--c64-blue);
      `;
    }
    if (status === DeliveryRouteStatus.EM_ROTA_ATE_VOCE) {
      return css`
        color: var(--green-400);
      `;
    }

    if (status === DeliveryRouteStatus.CANCELADA) {
      return css`
        color: var(--orange-600);
      `;
    }

    return css`
      color: var(--fading-sunset);
    `;
  }}
`;

export const PackageIcon = styled(Package)`
  ${IconCSS}
  color: var(--green-400);
`;

export const StatusDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

export const FirstOrderIcon = styled(PrimeiroPedidoIcon)`
  ${IconCSS}
  margin-left: 0.4rem;
  fill: var(--golden-beryl-yellow);
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
