import styled, { css } from "styled-components";
import Button from "../../components/Button";

import {
  Collapse as CollapseAnt,
  Col as ColAnt,
  Row as RowAnt,
  Input as InputAnt,
  Form as FormAnt,
  DatePicker as DatePickerAnt,
  Modal as ModalAnt,
  Button as ButtonAnt,
  Select as SelectAnt,
} from "antd";

import { ShoppingCartOutline } from "../../styles/Icons";

export const Container = styled(ModalAnt)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex-grow: 1;

  h3 {
    margin-top: 0.2rem;
    color: var(--gray-45);
  }

  .ant-modal-content {
    width: 70rem;
  }
  .ant-modal-header {
    padding-bottom: 0;
  }
  .ant-modal-body {
    padding-top: 0;
  }
  .ant-input {
    height: 3.2rem;
  }
  .ant-form-vertical .ant-form-item-label > label {
    margin-top: 0.2rem;
  }
  @media only screen and (max-width: 1200px) {
    .ant-modal-content {
      width: 50rem;
      .ant-form-vertical .ant-form-item-label > label {
        font-size: 0.7rem;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .ant-modal-content {
      width: 40rem;
      .ant-form-vertical .ant-form-item-label > label {
        font-size: 0.6rem;
      }
    }
  }
  @media only screen and (max-width: 650px) {
    .ant-modal-content {
      width: 36rem;
    }
    .ant-form-vertical .ant-form-item-label > label {
      font-size: 0.8rem;
    }
  }
  @media only screen and (max-width: 575px) {
    .ant-modal-content {
      width: 30rem;
    }
    .ant-form-vertical .ant-form-item-label > label {
      font-size: 1rem !important;
    }
  }
`;

export const SpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 5rem;
`;

export const Content = styled.div`
  width: 100%;
  max-height: 70vh;
  overflow: scroll;
`;

export const Form = styled(FormAnt)``;

export const Row = styled(RowAnt)`
  justify-content: space-between;
  width: 100%;

  margin-top: 0.4rem;

  input {
    height: 3.2rem;
  }
`;
export const RowTable = styled(RowAnt)`
  width: 100%;
`;

export const ButtonAction = styled(ButtonAnt)`
  border: none;
`;

export const Col = styled(ColAnt)``;

export const ContainerForm = styled.div`
  width: 100%;
`;
export const ContainerItens = styled.div`
  width: 100%;

  @media only screen and (max-width: 575px) {
    padding: 0 0 1rem;
  }
`;
export const ContainerModal = styled.div`
  width: 100%;
  h3 {
    color: var(--gray-45);
  }
  @media only screen and (max-width: 575px) {
    padding: 0 0 1rem;
  }
`;
export const Item = styled(CollapseAnt)`
  border: none;
  .ant-col {
    display: flex;
  }

  .ant-collapse-content-box {
    background: #ff856120;
    padding: 0;
  }

  p {
    margin-left: 0.3rem;
    color: var(--orange-350);
  }
`;

export const Input = styled(InputAnt)``;

export const AddItem = styled.button`
  width: 100%;
  height: 100%;
  min-height: 3rem;
  align-items: center;
  font-size: 1rem;
  background: white;
  border: 1px solid var(--orange-350);
  color: var(--orange-350);

  cursor: pointer;

  padding: 3px;
  border-radius: 4px;
  @media only screen and (max-width: 650px) {
    font-size: 0.8rem;
  }
  @media only screen and (max-width: 578px) {
    margin-top: 0.6rem;
    font-size: 1rem;
  }
`;

export const IconShoppingCart = styled(ShoppingCartOutline)`
  width: 25px;
  height: 25px;
  margin-right: 0.2rem;

  @media only screen and (max-width: 578px) {
    justify-content: space-around;
    height: 10%;
    width: 18px;
    height: 18px;
  }
`;

export const DatePicker = styled(DatePickerAnt)`
  width: 100%;
`;

export const ListProductsForm = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const FormItem = styled(FormAnt.Item)`
  margin-bottom: 0px;
  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder,
  .ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector
    input {
    height: 100%;
  }
`;

export const AddItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const InfoLabel = styled.label`
  font-size: 0.8rem;
  color: #494944;
  cursor: pointer;
  font-weight: 700;

  :hover {
    text-decoration: underline;
  }
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;
  height: 10%;
  width: 100%;
  background: var(--white-25);

  h1 {
    color: var(--gray-45);
    font-size: 1.5rem;
    font-weight: 500;
    margin: 0;
  }
  @media only screen and (max-width: 800px) {
    h1 {
      font-size: 1rem !important;
    }
  }
`;

export const ValorTotal = styled.div`
  @media only screen and (max-width: 800px) {
    span {
      font-size: 1rem;
    }
  }
`;
export const ContainerFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 20%;
  min-width: 10rem;
`;

const buttonCss = css`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.25rem;
  font-size: 17px;
  text-overflow: ellipsis;
`;

export const SaveButton = styled(Button)`
  ${buttonCss}
`;

export const CancelButton = styled.a`
  display: block;
  border-radius: 100px;
  transition: 0.5s;
  color: var(--gray-550);
  transition-property: opacity;
  &:hover,
  &:focus {
    color: var(--gray-550);
    opacity: 60%;
  }
  margin-right: 10px;
  ${buttonCss}
`;

export const Select = styled(SelectAnt)`
  text-transform: capitalize;
  
`;
